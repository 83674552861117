@<template>
    <div class="order-history__container ">
        <div class="file__header f jcb aic mb15">
            <div class="p2 brown">Lịch sử đơn hàng</div>
        </div>
        <!-- <div class="file__search relative mb15">
            <div class="absolute" style="top: 35%; left: 2%">
                <svg width="15" height="15" viewBox="0 0 15 15" class="mr05">
                    <use xlink="http://www.w3.org/2000/svg" href="#search" x="0" y="0"></use>
                </svg>
            </div>
            <input 
                id="myInput"
                type="text" 
                placeholder="Nhập mã hồ sơ bệnh lý" 
                v-model="textSearch"
                class="w80 pt1 pb1 pr1 pl2">
        </div> -->
        <div 
            v-if="dataOrder.length !== 0"
            class="file__list">
            <router-link 
                :to="{ name: 'body-detail', params: { id: item.id} }" 
                v-for="(item, index) in dataOrder" 
                :key="index">
                <div 
                    class="card w100 mt1 border-radius bg-white-1">
                    <div class="f jcb aic border-bottom pt1 pb1 pr1 pl1">
                        <div
                            class="p5 red">
                            {{ item.order_key }}
                        </div>
                        <div
                            class="status_order p5 red">
                            <div
                            v-if="item.status_order.title_status_order === 'Chờ xác nhận'"
                            class="pt025 pb025 pl05 pr05 border-radius orange bg-orange-1">
                            {{ item.status_order.title_status_order }}
                            </div>
                            <div
                            v-if="item.status_order.title_status_order === 'Đang xử lý'"
                            class="pt025 pb025 pl05 pr05 border-radius blue bg-blue-1">
                            {{ item.status_order.title_status_order }}
                            </div>
                            <div
                            v-if="item.status_order.title_status_order === 'Thành công'"
                            class="pt025 pb025 pl05 pr05 border-radius green bg-green-1">
                            {{ item.status_order.title_status_order }}
                            </div>
                            <div
                            v-if="item.status_order.title_status_order === 'Đã hủy'"
                            class="pt025 pb025 pl05 pr05 border-radius red bg-red-1">
                            {{ item.status_order.title_status_order }}
                            </div>
                        </div>
                    </div>

                    <!-- order -->
                    <div 
                        v-if="item.customer"
                        class="f fdc mb1 pt1 pr1 pl1">
                        <div class="f jcb aic mb05">
                            <h6 class="gray">Tên người mua</h6>
                            <div class="p4 black">{{item.customer['full_name']}}</div>
                        </div>
                        <div class="f jcb aic mb05">
                            <h6 class="gray">Số điện thoại</h6>
                            <div class="p4 black">{{item.customer['phone']}}</div>
                        </div>
                        <div class="f jcb aic mb05">
                            <h6 class="gray">Tổng sản phẩm</h6>
                            <div class="p4 black">{{item.total_quantity}}</div>
                        </div>
                        <div class="f jcb aic">
                            <h6 class="gray">Tổng đơn hàng</h6>
                            <div class="p4 brown">{{formatMoney(item.total_price)}}</div>
                        </div>
                    </div>
                    <div
                        class="card-item__total f jcb aic pt1 pb1 pr1 pl1 bg-brown-1">
                        <div class="f fdc">
                            <div class="h7 mb05">Tổng giá trị</div>
                            <div class="p3 brown">{{formatMoney(item.total_price)}}</div>
                        </div>
                        <div class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
                            Xem chi tiết
                        </div>
                    </div>
                </div>
            </router-link>
        </div>

        <div
            v-else
            class="f fdc aic jcc px1 border-radius w100 shadow bg-white-1">
            <div class="icon--face-smile"></div>
            <div class="p5 mt1">Khách hàng chưa có đơn hàng nào</div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        data : {
            type : Object,
            default() {
                return {}
            }
        }
    },

    data () {
        return {
            dataOrder : []
        }
    },

    methods : {
        // formatMoney
        formatMoney(value) {
            var x = value;
            x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return x
        },

        convertTime(value) {
            var time = value.split('T')
            return time[0] + '-' + time[1]
        },

        getData () {
            HTTP.get('v1/api/order/?depth=1')
            .then((res) => {
                var data = res.data.results
                this.dataOrder = data.filter(item => (item.customer.id || item.customer.phone) === this.data.id)
            })
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'OrderHistory.scss';
</style>