<template>
  <div 
    v-if="$route.params.path !== 'forums' && $route.params.path !== 'product' && roleUser === 'admin'"
    class="Cus-container bg-gray-2 content-view__container">
    <HeaderView :isMobile="isMobile" @click-nav="isShowMenuMobile = true"></HeaderView>
    <div class="f">
      <SidebarView :isMobile="isMobile" :isShowMenuMobile="isShowMenuMobile" :sidebar="sidebar" @close-nav="isShowMenuMobile = false"></SidebarView>
      <router-view 
        :data="data"
        :isMobile="isMobile"
        :dataField="dataField"/>
    </div>
    <Login></Login>

    <div class="hide">
      
    </div>
  </div>
</template>

<script>
import HeaderView from '@/components/HeaderView/HeaderView.vue'
import SidebarView from '@/components/SideBarView/SidebarView.vue'
import Login from '@/components/LoginView/LoginView.vue'

import bcrypt from 'bcryptjs';

export default {
  components: {

    HeaderView,
    SidebarView,
    Login,
  },
  data() {
    return {
      data : [],
      sidebar : [],
      dataField: {},
      roleUser : '',
      isShowMenuMobile: false,
    }
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch : {
    '$route' : function () {
      var a = []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      a = this.data.filter(item => 
        item.path === '/' + this.$route.params.path &&
        item.levelmenu === 1
      )
      this.dataField = a[0]
    }
  }, 

  methods: {
    async checkRoleUser () {
        var role = localStorage.getItem('role')
        const admin = await bcrypt.compare('admin', role)
        const customer = await bcrypt.compare('customer', role)
        if (admin) {
          this.roleUser = 'admin'
        } else if (customer) {
          this.roleUser = 'customer'
        } else {
          this.roleUser = 'another'
        }
    },

    getData() {
      const setting = localStorage.getItem("systemsetting").toLowerCase()
      const results = setting.replaceAll("'", '"')
      this.data = JSON.parse(results)
      for (let i = 0; i < this.data.length; i++) {
        if(this.data[i]['levelmenu'] == 1) {
          this.sidebar.push(this.data[i])
        }
      }

      var a = []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      a = this.data.filter(item => 
        item.path === '/' + this.$route.params.path &&
        item.levelmenu === 1
      )
      this.dataField = a[0]
    }
  },
  created() {
    this.getData()
    this.checkRoleUser()
  }
}
</script>

<style>
.card {
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
}

.active {
  display: flex;
}

.popup-flow {
  overflow-y: hidden !important;

}
</style>

<style scoped>
.content-view__container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>