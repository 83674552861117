<template>
  <div v-if="!isMobile" class="header f aic jcb w100 bg-white-1 border-bottom">
    <div class="header-left f aic">
      <div class="header-left__img pt1 pb1 pr15 pl15">
        <img src="../../assets/Header/LogoDrMom.png" alt="" />
      </div>
      <!-- <div class="header-left__text f aic pt1 pb1 pr15 pl15">
            <img src="../../assets/Header/language.png" alt="">
            <p class="p-small blue ml1">Xem gian hàng</p>
          </div> -->
    </div>
    <div
      @click="handleLogin"
      class="action__container relative f aic border-radius pr2"
    >
      <div v-if="myAccount.full_name !== ''" class="p4 brown">
        {{ myAccount.full_name }}
      </div>
      <div v-if="myAccount.full_name === ''" class="p4 brown">
        {{ myAccount.user__username }}
      </div>
      <div class="ml1 mr1">
        <img class="image_user" :src="myAccount.avatar" alt="" />
      </div>
      <img src="../../assets/Header/Down.png" alt="" />

      <PopupLogout></PopupLogout>
    </div>
  </div>
  <div v-else class="header-mobile f aic jcb w100 bg-white-1 border-bottom">
    <div class="header-left f aic">
      <div class="header-left__img pt1 pb1 pr15 pl15">
        <img src="../../assets/Header/LogoDrMom.png" alt="" />
      </div>
    </div>
    <div @click="$emit('click-nav')" class="mh1">
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.75C0 0.34375 0.3125 0 0.75 0H13.25C13.6562 0 14 0.34375 14 0.75C14 1.1875 13.6562 1.5 13.25 1.5H0.75C0.3125 1.5 0 1.1875 0 0.75ZM0 5.75C0 5.34375 0.3125 5 0.75 5H13.25C13.6562 5 14 5.34375 14 5.75C14 6.1875 13.6562 6.5 13.25 6.5H0.75C0.3125 6.5 0 6.1875 0 5.75ZM13.25 11.5H0.75C0.3125 11.5 0 11.1875 0 10.75C0 10.3438 0.3125 10 0.75 10H13.25C13.6562 10 14 10.3438 14 10.75C14 11.1875 13.6562 11.5 13.25 11.5Z"
          fill="#1A1A1A"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import PopupLogout from "@/components/PopupLogout/LogoutView.vue";

export default {
  components: {
    PopupLogout,
  },

	props: {
		isMobile: {
			type: Boolean,
			default: false,
		}
	},

  data() {
    return {
      myAccount: {},
    };
  },

  methods: {
    handleLogin() {
      var login = document.getElementsByClassName("log-out")[0];
      console.log(login);
      login.classList.toggle("active");
    },

    getDataAccount() {
      var account = localStorage.getItem("user");
      this.account = account;

      var myAccount = localStorage.getItem("myAccount");
      this.myAccount = JSON.parse(myAccount);
    },
  },

  created() {
    this.getDataAccount();
  },
};
</script>

<style lang="scss">
@import "HeaderView.scss";
</style>
