@<template>
  <div
    v-if="!$route.params.id"
    class="forum-website__container relative bg-yellow-1"
  >
  <div class="forum-website__content">
    <div class="forum-website__category">
      <div class="forum-category__header mb15">
        <div class="p2">Danh sách chuyên mục bài viết</div>
      </div>
      <div v-if="userId && $route.path.includes('forums')" class="f aic jce">
        <button
          class="h5 pt075 pb075 align-c border-radius bg-brown white ph05"
          @click="isAddNewPost = true"
        >
          Đăng bài viết
        </button>
      </div>
      <div class="forum-category__body">
        <div
          v-for="(item, index) in dataCategory"
          :key="index"
          class="forum-category__item pt1 pb1 border-bottom"
        >
          <router-link
            :to="{ name: 'body-detail', params: { id: 'category-' + item.id } }"
            class="item-category f aic"
          >
            <div>
              <img
                class="border-radius"
                style="width: 48px; height: 48px"
                :src="item.image"
                alt=""
              />
            </div>
            <div class="f fdc jcb ais ml1">
              <div class="p5 blue mb025">{{ item.category_name }}</div>
              <div class="h6 gray-1-text">
                Bài viết: {{ handleCountPost(item.id) }}
              </div>
            </div>
          </router-link>
          <div v-if="!isMobile" class="item-post">
            <router-link
              :to="{
                name: 'body-detail',
                params: { id: 'post-' + handlePostCategory(item.id).id },
              }"
              v-if="handlePostCategory(item.id).length !== 0"
              class="f aic cursor"
            >
              <img
                v-if="handlePostCategory(item.id).media[0]"
                :src="handlePostCategory(item.id).media[0].file_media"
                alt=""
              />
              <div class="ml1">
                <div class="one-line title-post mb025">
                  <div class="p4 blue">
                    {{ handlePostCategory(item.id).title_post }}
                  </div>
                </div>
                <div class="f aic">
                  <div class="h6 gray-1-text mr05">
                    {{
                      convertTime(handlePostCategory(item.id).time_published)
                    }}
                  </div>
                  <svg
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2" cy="2" r="2" fill="#D9DDE8" />
                  </svg>
                  <div class="one-line p4 blue ml05" style="max-width: 70%">
                    {{ handlePostCategory(item.id).short_description }}
                  </div>
                </div>
              </div>
            </router-link>
            <div v-if="handlePostCategory(item.id).length === 0">
              Chưa có bài viết nào
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Bài viết -->
    <div class="forum-website__post">
      <!-- Bài viết nổi bật -->
      <div
        class="forum-website__post-trend border border-radius shadow bg-white-1 mb1"
      >
        <div class="post-trend__header pt05 pb05 pl1 pr1 p2">
          Bài viết nổi bật
        </div>
        <div class="post-trend__body pl1 pr1">
          <router-link
            :to="{ name: 'body-detail', params: { id: 'post-' + item.id } }"
            v-for="(item, index) in postTrend"
            :key="index"
            class="pt1 pb1 border-top f aic"
          >
            <div v-if="item.media[0]">
              <img
                class="border-radius"
                :src="item.media[0].file_media"
                alt=""
              />
            </div>
            <div class="ml1 w100">
              <div class="f jcb aic mb025">
                <div class="p4 brown">
                  {{ item.category[0].category_name }}
                </div>
                <div class="h6 gray-1-text">
                  {{ convertTime(item.time_published) }}
                </div>
              </div>
              <div class="two-line p5">
                {{ item.title_post }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- Bài viết mới -->
      <div
        class="forum-website__post-new border border-radius shadow bg-white-1"
      >
        <div class="post-trend__header pt05 pb05 pl1 pr1 p2">Bài viết mới</div>
        <div class="post-trend__body pl1 pr1">
          <router-link
            v-for="(item, index) in handlePostNew()"
            :key="index"
            :to="{ name: 'body-detail', params: { id: 'post-' + item.id } }"
            class="pt1 pb1 border-top f aic"
          >
            <div v-if="item.media[0]">
              <img
                class="border-radius"
                :src="item.media[0].file_media"
                alt=""
              />
            </div>
            <div class="ml1 w100">
              <div class="f jcb aic mb025">
                <div class="p4 brown">
                  {{ item.category[0].category_name }}
                </div>
                <div class="h6 gray-1-text">
                  {{ convertTime(item.time_published) }}
                </div>
              </div>
              <div class="two-line p5">
                {{ item.title_post }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
    <!-- Danh sách chuyên mục bài viết -->

    <div class="forum-bg-1 absolute">
      <img src="../../assets/forum-bg-1.png" alt="" />
    </div>
    <div class="forum-bg-2 absolute">
      <img src="../../assets/forum-bg-2.png" alt="" />
    </div>


    <Popup :isShow="isAddNewPost" @close="isAddNewPost = fasle">
        <AddNewPost class="mv05" :dataInput="dataAddNewPost" :isUserCreate="true" :isMobile="isMobile" @createSuccess="onCreateSuccess" />
    </Popup>
    <NotiView 
        v-if="notiView"
        @closePopup='notiView = false'
        :title='titleNotiview'
        :logo='logo'/>
  </div>

  <CategoryPostWebsite :isMobile="isMobile" v-else />
</template>

<script>
import CategoryPostWebsite from "@/components/CategoryPostWebsite/CategoryPostWebsite.vue";
import AddNewPost from "@/components/AddNew/AddNewPost/AddNewPost.vue";
import NotiView from '@/components/NotiView/NotiView.vue'
import Popup from "@/components/Popup/index.vue";
import axios from "axios";
export default {
  components: {
    CategoryPostWebsite,
    AddNewPost,
    Popup,
    NotiView,
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      dataCategory: [],
      dataPost: [],
      postTrend: [],
      isAddNewPost: false,
      notiView : false,
      titleNotiview : '',
      logo : '',
      dataAddNewPost: {
        id: "",
        title_post: "",
        slug: "",
        permalink: "",
        time_published: "",
        content: "",
        user_created: "",
        short_description: "",
        thumbnail: "",
        thumbnail_url: "",
        status_show_post: "",
        status_post: "",
        total_click: "",
        total_share: "",
        title_seo: "",
        description_seo: "",
        keyword: "",
        media: "",
        category: "",
        tag: "",
        system_show: "",
        avatar: [],
      },
    };
  },

  computed: {
    userId () {
      return localStorage.getItem('idUser')
    }
  },

  methods: {
    convertTime(value) {
      var time = value.split("T");
      return time[0];
    },

    handlePostNew() {
      var data = [...this.dataPost];
      var dataPostNew = data.splice(0, 5);
      return dataPostNew;
    },

    handlePostTrend() {
      var data = [...this.dataPost];

      var dataPost = [];

      while (dataPost.length < 5 && data.length > 0) {
        const trendPost = data.reduce(function (item1, item2) {
          return item1.total_click > item2.total_click ? item1 : item2;
        });

        dataPost.push(trendPost);

        for (let i = 0; i < data.length; i++) {
          if (data[i].id === trendPost.id) {
            data.splice(i, 1);
          }
        }
      }

      this.postTrend = dataPost;
    },

    handlePostCategory(value) {
      var count = this.dataPost.filter((item) => item.category[0]?.id === value);
      if (count.length !== 0) {
        return count[0];
      } else {
        return [];
      }
    },

    handleCountPost(value) {
      var count = this.dataPost.filter((item) => item.category[0]?.id === value);
      return count.length;
    },

    async getData() {
      var url = "https://api.drmom.store/";
      await axios.get(url + "v1/api/post/"  + '?depth=1').then((res) => {
        var data = res.data.results;
        if (this.$route.params.path === "forums") {
          this.dataPost = data.filter(
            (item) => item.system_show[0]?.id == 1 && item.approved_status === 'approved'
          );
        } else {
          this.dataPost = data.filter(
            (item) => item.system_show[0]?.id == 2 && item.approved_status === 'approved'
          );
          const category = this.$route.query.category
          if (category) {
            this.dataPost = this.dataPost.filter(e => e.category.find(elm => elm.id == category))
          }
        }
      });

      await axios.get(url + "v1/api/categorypost/").then((res) => {
        this.dataCategory = res.data.results;
      });

      this.handlePostTrend();
    },

    onCreateSuccess({ isError }) {
        this.isAddNewPost = false
        this.notiView = true
        this.titleNotiview = isError ? 'Tạo bài viết thất bại' : 'Tạo bài viết thành công. Đang chờ admin duyệt bài'
        this.logo = isError ? 'noti-warning' : 'noti-success'
    }
  },

  created() {
    this.getData();
  },
};
</script>

<style scoped lang='scss'>
@import "PostForum.scss";
</style>