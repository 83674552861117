@<template>
    <div class="popup popup-setting-route f jcc aic">
        <div 
            v-if="typeRoute === 'Chọn trang Landing'"
            class="route-landing__container border-radius bg-white-1">
            <div class="p5 px1">
                Chọn trang Landing
            </div>
            <div class="body pl1 pr1 mb1">
                <div class="grid-item px1">
                    <div></div>
                    <div class="p6 gray-1-text align-c">Tên Form</div>
                    <div class="p6 gray-1-text align-c">Ngày khởi tạo</div>
                    <div class="p6 gray-1-text align-c">Người khởi tạo</div>
                </div>
                <div class="f fdc ais" style="gap: 1em">
                    <div
                        class="grid-item aic w100 bg-gray-2 px1 border-radius"
                        v-for="(item, index) in dataLanding"
                        :key="index">
                        <div>
                            <input 
                                @click="handleSelect(item)"
                                type="radio"
                                name="product">
                        </div>
                        <div class="p6 gray-1-text align-c">{{item.title_landing}}</div>
                        <div class="p6 gray-1-text align-c">{{formatTime(item.created_at)}}</div>
                        <div class="p6 gray-1-text align-c">{{item.user_created.username}}</div>
                    </div>
                </div>
            </div>
            <div class="w100 f jce aic action px1 bg-gray-2">
                <div 
                    @click="$emit('closePopup')"
                    class="p5 pt075 pb075 pl1 pr1 align-c border-radius border cursor">Hủy bỏ</div>
                <div 
                    @click="updateData"
                    class="p5 pt075 pb075 pl1 pr1 ml1 border-radius white bg-brown cursor">Xác nhận</div>
            </div>
        </div>
        <div 
            class="route-link__container border-radius bg-white-1"
            v-else>
            <div class="p5 px1">
                Chọn trang Landing
            </div>
            <div class="f fdc ais pl1 pr1">
                <label for="">Địa chỉ đường dẫn đích đến</label>
                <input 
                    class="w100 mt025 px075"
                    v-model="dataSelect"
                    type="text" name="" id="">
            </div>
            <div class="w100 f jcb aic action px1 ">
                <div 
                    @click="$emit('closePopup')"
                    class="w50 p5 pt075 pb075 pl1 pr1 align-c border-radius border cursor">Hủy bỏ</div>
                <div 
                    @click="updateData"
                    class=" w50 p5 pt075 pb075 pl1 pr1 ml1 border-radius white bg-brown cursor">Xác nhận</div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        typeRoute : {
            type : String,
            default() {
                return ''
            }
        }
    },

    data () {
        return {
            dataLanding : [],
            dataSelect : ''
        }
    },

    methods : {
        formatTime (value) {
            var time = value.split('T')
            return time[0]
        },

        handleSelect (item) {
            this.dataSelect = 'https://test.drmom.xyz/?#/landing/' + item.key_landing + '/'
        },

        updateData () {
            this.$emit('updateRouteLanding', this.dataSelect)
            this.$emit('closePopup')
        },

        getData () {
            HTTP.get('v1/api/landing/?depth=1')
            .then((res) => {
                this.dataLanding = res.data.results
            })
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'AddRouteAction.scss';
</style>