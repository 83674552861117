@<template>
  <div
    v-if="type === 'category'"
    class="category-post__container container-web bg-yellow-1"
  >
    <div class="category-post-container">
      <div class="category-post-active relative">
        <!-- Bài viết, bình luận, bài viết liên quan -->
        <div class="post-detail__left">
          <!-- Bài viết -->
          <div class="post-detail__area">
            <!-- chi tiet bai viet -->
            <div class="post-detail__text px1 border-radius bg-white-1 shadow">
              <div class="f jcb aic mb1">
                <!-- Danh mục bài viết -->
                <div class="f aic">
                  <div class="pr05 border-right">
                    <svg
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.6406 7.16016L13.0156 12.4102C12.8789 12.7109 12.5781 12.875 12.2227 12.875H1.75C0.765625 12.875 0 12.1094 0 11.125V2.375C0 1.41797 0.765625 0.625 1.75 0.625H4.94922C5.41406 0.625 5.85156 0.816406 6.17969 1.14453L7.51953 2.375H11.375C12.332 2.375 13.125 3.16797 13.125 4.125V5H11.8125V4.125C11.8125 3.90625 11.5938 3.6875 11.375 3.6875H7L5.25 2.07422C5.16797 1.99219 5.05859 1.9375 4.94922 1.9375H1.75C1.50391 1.9375 1.3125 2.15625 1.3125 2.375V10.25L3.25391 6.36719C3.39062 6.06641 3.69141 5.875 4.01953 5.875H14.875C15.5039 5.875 15.9414 6.55859 15.6406 7.16016Z"
                        fill="#757575"
                      />
                    </svg>
                  </div>
                  <div class="ml05 mr05">
                    <svg
                      width="6"
                      height="11"
                      viewBox="0 0 6 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.43359 1.15625L5.34375 5.3125C5.45312 5.44922 5.53516 5.61328 5.53516 5.75C5.53516 5.91406 5.45312 6.07812 5.34375 6.21484L1.43359 10.3711C1.1875 10.6445 0.777344 10.6445 0.503906 10.3984C0.230469 10.1523 0.230469 9.74219 0.476562 9.46875L3.97656 5.75L0.476562 2.05859C0.230469 1.78516 0.230469 1.375 0.503906 1.12891C0.777344 0.882812 1.1875 0.882812 1.43359 1.15625Z"
                        fill="#757575"
                      />
                    </svg>
                  </div>
                  <div v-if="PostSelect.category" class="h7 blue">
                    {{ PostSelect.category[0].category_name }}
                  </div>
                </div>

                <!-- Thời gian đăng bài -->
                <div class="h7 gray-1-text">
                  Thời gian đăng bài:
                  <span class="p4">{{
                    convertTime(PostSelect.time_published)
                  }}</span>
                </div>
              </div>

              <!-- post title -->
              <div class="h3 mb1">
                {{ PostSelect.title_post }}
              </div>

              <!-- post short description -->
              <div class="p6 mb1">
                {{ PostSelect.short_description }}
              </div>

              <!-- post img -->
              <div v-if="PostSelect.media" class="mb1 border-radius">
                <img
                  class="border-radius w100"
                  :src="PostSelect.media[0].file_media"
                  alt=""
                />
              </div>

              <!-- post content -->
              <div
                class="content__post p6 w100"
                v-html="PostSelect.content"
              ></div>
            </div>
          </div>
        </div>
        <router-link
          class="bg-blur absolute cursor h3 f aic jcc"
          :to="{ name: 'body-detail', params: { id: 'post-' + PostSelect.id } }"
        >
          Xem thêm...
        </router-link>
      </div>
      <div class="category-post-list">
        <p class="p4">Các bài viết khác:</p>
        <router-link
          v-for="(item, index) in postCategory"
          :key="index"
          :to="{ name: 'body-detail', params: { id: 'post-' + item.id } }"
          class="bg-white-1 f border-radius p1 cursor fdc"
        >
          <div class="f aic">
            <img
              class="border-radius border"
              :src="item.media[0].file_media"
              alt=""
            />
            <div class="ml1">
              <div class="mb025">
                <div class="p2 blue one-line">{{ item.title_post }}</div>
              </div>
              <div class="f aic">
                <div class="p4 blue">{{ item.user_created.username }}</div>
                <div class="h6 ml025 gray-1-text">
                  {{ convertTime(item.time_published) }}
                </div>
              </div>
            </div>
          </div>
          <div class="f aic gap1">
            <div class="p4 align-c">
              <span class="p6 gray-1-text">Truy cập: </span>
              {{ item.total_click }} lượt
            </div>
            <div class="p4 align-c">
              <span class="p6 gray-1-text">Chia sẻ: </span>
              {{ item.total_share }} lượt
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <div v-else-if="type === 'post'">
    <div class="post-detail__container container-web bg-white-1">
      <!-- Bài viết, bình luận, bài viết liên quan -->
      <div class="post-detail__left">
        <!-- Bài viết -->
        <div class="post-detail__area pb2 border-bottom">
          <!-- chi tiet bai viet -->
          <div class="post-detail__text px1 border-radius bg-gray-2 mb1 shadow">
            <div class="f jcb aic mb1">
              <!-- Danh mục bài viết -->
              <div class="f aic">
                <div class="pr05 border-right">
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6406 7.16016L13.0156 12.4102C12.8789 12.7109 12.5781 12.875 12.2227 12.875H1.75C0.765625 12.875 0 12.1094 0 11.125V2.375C0 1.41797 0.765625 0.625 1.75 0.625H4.94922C5.41406 0.625 5.85156 0.816406 6.17969 1.14453L7.51953 2.375H11.375C12.332 2.375 13.125 3.16797 13.125 4.125V5H11.8125V4.125C11.8125 3.90625 11.5938 3.6875 11.375 3.6875H7L5.25 2.07422C5.16797 1.99219 5.05859 1.9375 4.94922 1.9375H1.75C1.50391 1.9375 1.3125 2.15625 1.3125 2.375V10.25L3.25391 6.36719C3.39062 6.06641 3.69141 5.875 4.01953 5.875H14.875C15.5039 5.875 15.9414 6.55859 15.6406 7.16016Z"
                      fill="#757575"
                    />
                  </svg>
                </div>
                <div class="ml05 mr05">
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.43359 1.15625L5.34375 5.3125C5.45312 5.44922 5.53516 5.61328 5.53516 5.75C5.53516 5.91406 5.45312 6.07812 5.34375 6.21484L1.43359 10.3711C1.1875 10.6445 0.777344 10.6445 0.503906 10.3984C0.230469 10.1523 0.230469 9.74219 0.476562 9.46875L3.97656 5.75L0.476562 2.05859C0.230469 1.78516 0.230469 1.375 0.503906 1.12891C0.777344 0.882812 1.1875 0.882812 1.43359 1.15625Z"
                      fill="#757575"
                    />
                  </svg>
                </div>
                <div v-if="PostSelect.category" class="h7 blue">
                  {{ PostSelect.category[0].category_name }}
                </div>
              </div>

              <!-- Thời gian đăng bài -->
              <div class="h7 gray-1-text">
                Thời gian đăng bài:
                <span class="p4">{{
                  convertTime(PostSelect.time_published)
                }}</span>
              </div>
            </div>

            <!-- post title -->
            <div class="h3 mb1">
              {{ PostSelect.title_post }}
            </div>

            <!-- post short description -->
            <div class="p6 mb1">
              {{ PostSelect.short_description }}
            </div>

            <!-- post img -->
            <div v-if="PostSelect.media" class="mb1 border-radius">
              <img
                class="border-radius w100"
                :src="PostSelect.media[0].file_media"
                alt=""
              />
            </div>

            <!-- post content -->
            <div
              class="content__post p6 w100"
              v-html="PostSelect.content"
            ></div>
          </div>

          <!-- đánh giá và like bài viết -->
          <div class="post-detail__action f aic mb1">
            <!-- Thả tim bài viết -->
            <div
              v-if="!checkReactPost"
              @click="handleLikePost"
              class="post-detail__like px05 f aic border border-radius cursor"
            >
              <div class="icon--love f jcc aic">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1219_41179)">
                    <path
                      d="M7.90234 0C6.03858 0 4.25115 0.740379 2.93326 2.05826C1.61538 3.37615 0.875 5.16358 0.875 7.02734C0.875 8.89108 1.61538 10.6786 2.93326 11.9965C4.25115 13.3143 6.03858 14.0547 7.90234 14.0547C9.76608 14.0547 11.5536 13.3143 12.8715 11.9965C14.1893 10.6786 14.9297 8.89108 14.9297 7.02734C14.9297 5.16358 14.1893 3.37615 12.8715 2.05826C11.5536 0.740379 9.76608 0 7.90234 0Z"
                      fill="url(#paint0_linear_1219_41179)"
                    />
                    <path
                      d="M10.0735 3.51367C8.14277 3.51367 7.9012 5.11591 7.9012 5.11591C7.9012 5.11591 7.66052 3.51367 5.72975 3.51367C3.87278 3.51367 3.33167 5.46552 3.5583 6.50908C4.15563 9.26731 7.9012 11.1998 7.9012 11.1998C7.9012 11.1998 11.6476 9.26731 12.245 6.50908C12.4707 5.46552 11.9287 3.51367 10.0735 3.51367Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1219_41179"
                      x1="7.90234"
                      y1="0"
                      x2="7.90234"
                      y2="14.0547"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#757575" />
                      <stop offset="1" stop-color="#757575" />
                    </linearGradient>
                    <clipPath id="clip0_1219_41179">
                      <rect
                        width="14.0547"
                        height="14.0547"
                        fill="white"
                        transform="translate(0.875)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-if="PostSelect.react" class="p4 ml05 gray-1-text">
                {{ PostSelect.react.length }}
              </div>
              <div v-else class="p4 ml05 gray-1-text">0</div>
            </div>
            <div
              v-if="checkReactPost"
              @click="handleDistLikePost"
              class="post-detail__like px05 f aic border border-radius cursor"
              style="border: 1px solid #ff6680"
            >
              <div class="icon--love f jcc aic">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1219_41179)">
                    <path
                      d="M7.90234 0C6.03858 0 4.25115 0.740379 2.93326 2.05826C1.61538 3.37615 0.875 5.16358 0.875 7.02734C0.875 8.89108 1.61538 10.6786 2.93326 11.9965C4.25115 13.3143 6.03858 14.0547 7.90234 14.0547C9.76608 14.0547 11.5536 13.3143 12.8715 11.9965C14.1893 10.6786 14.9297 8.89108 14.9297 7.02734C14.9297 5.16358 14.1893 3.37615 12.8715 2.05826C11.5536 0.740379 9.76608 0 7.90234 0Z"
                      fill="url(#paint0_linear_1219_41179)"
                    />
                    <path
                      d="M10.0735 3.51367C8.14277 3.51367 7.9012 5.11591 7.9012 5.11591C7.9012 5.11591 7.66052 3.51367 5.72975 3.51367C3.87278 3.51367 3.33167 5.46552 3.5583 6.50908C4.15563 9.26731 7.9012 11.1998 7.9012 11.1998C7.9012 11.1998 11.6476 9.26731 12.245 6.50908C12.4707 5.46552 11.9287 3.51367 10.0735 3.51367Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1219_41179"
                      x1="7.90234"
                      y1="0"
                      x2="7.90234"
                      y2="14.0547"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF6680" />
                      <stop offset="1" stop-color="#E61739" />
                    </linearGradient>
                    <clipPath id="clip0_1219_41179">
                      <rect
                        width="14.0547"
                        height="14.0547"
                        fill="white"
                        transform="translate(0.875)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div v-if="PostSelect.react" class="p4 ml05 gray-1-text">
                {{ PostSelect.react.length }}
              </div>
              <div v-else class="p4 ml05 gray-1-text">0</div>
            </div>

            <!-- đánh gía -->
            <div
              class="post-detail__rating pt05 pb025 pl05 pr05 f aic border border-radius cursor"
            >
              <div class="mb025 h6 gray-1-text">Đánh giá (24)</div>
              <div class="f jcc aic ml05">
                <StarRating
                  :rating="5"
                  :increment="0.5"
                  :star-size="15"
                  :read-only="true"
                  :border-width="2"
                  border-color="#FFCC00"
                  active-color="#FFCC00"
                  :rounded-corners="true"
                  inactive-color="#fff"
                  :star-points="[
                    108, 0.0, 141, 70, 218, 78.3, 162, 131, 175, 205, 108, 170,
                    41.2, 205, 55, 131, 1, 78, 75, 68,
                  ]"
                  :show-rating="false"
                />
              </div>
            </div>

            <!-- chia sẻ -->
            <a
              class="f aic pt05 pb05 pl075 pr075 border border-radius cursor"
              type="button"
              role="button"
              title="Share on facebook"
              :href="shareUrl"
              target="_blank"
              rel="noopener"
            >
              <div class="icon--share"></div>
              <div class="h7 blue ml05">Chia sẻ</div>
            </a>
          </div>

          <!-- tag name post -->
          <div class="post-detail__tag f aic">
            <div>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.1875 3.6875C2.1875 3.22266 2.57031 2.8125 3.0625 2.8125C3.52734 2.8125 3.9375 3.22266 3.9375 3.6875C3.9375 4.17969 3.52734 4.5625 3.0625 4.5625C2.57031 4.5625 2.1875 4.17969 2.1875 3.6875ZM5.38672 0.625C5.85156 0.625 6.28906 0.816406 6.61719 1.14453L11.4297 5.95703C12.1133 6.64062 12.1133 7.76172 11.4297 8.44531L7.79297 12.082C7.10938 12.7656 5.98828 12.7656 5.30469 12.082L0.492188 7.26953C0.164062 6.94141 0 6.50391 0 6.03906V1.9375C0 1.22656 0.574219 0.625 1.3125 0.625H5.38672ZM1.42188 6.33984L6.23438 11.1523C6.39844 11.3438 6.69922 11.3438 6.86328 11.1523L10.5 7.51562C10.6914 7.35156 10.6914 7.05078 10.5 6.88672L5.6875 2.07422C5.60547 1.99219 5.49609 1.9375 5.38672 1.9375H1.3125V6.03906C1.3125 6.14844 1.33984 6.25781 1.42188 6.33984Z"
                  fill="#757575"
                />
              </svg>
            </div>
            <div class="ml05 f aic">
              <div
                v-for="(item, index) in PostSelect.tag"
                :key="index"
                class="h6 blue"
              >
                {{ item.tag_name }}
              </div>
            </div>
          </div>
        </div>

        <!-- Bình luận -->
        <div class="post-detail__comment mt1">
          <div class="comment__head p5 mb1 brown">
            Bình luận ({{ dataComment.length }})
          </div>
          <div v-if="userId" class="comment__input mb1">
            <label class="h7 gray-1-text" for="">Thêm bình luận</label>
            <input
              @keypress="handlePostComment"
              v-model="commentContent"
              placeholder="Vui lòng đăng nhập để đăng bình luận"
              class="w100 mt025 mb05 border border-radius"
              type="text"
            />
            <div class="w100 f jcb aic gray-1-text">
              <div>
                <div class="h6" v-if="commentResponseSelect !== null">
                  Phản hồi comment của
                  <span class="p4 brown"
                    >{{ commentResponseSelect.user_customer.username }}
                  </span>
                  <span
                    @click="commentResponseSelect = null"
                    class="p4 blue cursor"
                    >- Huỷ</span
                  >
                </div>
              </div>
              <div class="p4">
                Nhấn <span class="brown"> Enter </span> để gửi phản hồi
              </div>
            </div>
          </div>
          <div v-else class="mb1">Vui lòng đăng nhập để đăng bình luận</div>
          <div class="comment__list">
            <div v-for="(item, index) in dataComment" :key="index">
              <div class="mb1" v-if="item.comment_parent === null">
                <div class="comment__item-user f aic mb05">
                  <div>
                    <img
                      class="image_user border"
                      :src="item.image_user"
                      alt=""
                    />
                  </div>
                  <div class="ml05 f fdc jcb ais">
                    <div class="p4">{{ item.user_customer.username }}</div>
                    <div class="h6 gray-1-text">
                      {{ convertTime(item.created_at) }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.user_customer.id === userId"
                  class="comment__item-content p6 px05 mb05 border-radius bg-black white"
                >
                  {{ item.content }}
                </div>

                <div
                  v-else
                  class="comment__item-content p6 px05 mb05 border-radius bg-gray-2"
                >
                  {{ item.content }}
                </div>
                <div
                  @click="commentResponseSelect = item"
                  class="comment__item-response f jce aic"
                >
                  <div class="h7 blue cursor">Phản hồi bình luận</div>
                </div>

                <div v-for="(value, key) in dataComment" :key="key">
                  <div
                    class="pl5 mb2"
                    v-if="
                      value.comment_parent !== null &&
                      value.comment_parent.id === item.id
                    "
                  >
                    <div class="comment__item-user f aic mb05">
                      <div>
                        <img
                          class="image_user border"
                          :src="value.image_user"
                          alt=""
                        />
                      </div>
                      <div class="ml05 f fdc jcb ais">
                        <div class="p4">{{ value.user_customer.username }}</div>
                        <div class="h6 gray-1-text">
                          {{ convertTime(value.created_at) }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="value.user_customer.id === userId"
                      class="comment__item-content p6 px05 mb05 border-radius bg-black white"
                    >
                      {{ value.content }}
                    </div>

                    <div
                      v-else
                      class="comment__item-content p6 px05 mb05 border-radius bg-gray-2"
                    >
                      {{ value.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Thông tin chung bài viết -->
      <div
        class="post-detail__right border border-radius px1"
        style="height: fit-content"
      >
        <div class="post-right__header f aic mb1 pb1 border-bottom">
          <div>
            <img
              v-if="PostSelect.image_user !== null"
              class="image_user"
              :src="PostSelect.image_user"
              alt=""
            />
            <img
              v-else
              class="image_user"
              src="../../assets/account.png"
              alt=""
            />
          </div>
          <div class="f fdc jcb ais ml1">
            <div class="p4">
              {{ PostSelect.user_created.email }}
            </div>
            <div class="h6 gray-1-text">
              {{ PostSelect.user_created.username }}
            </div>
          </div>
        </div>
        <div class="f fdc ais" style="gap: 0.5em">
          <div class="h7">Thống kê bài viết</div>
          <div class="f jcb aic w100">
            <div class="h6 gray-1-text">Lượt xem</div>
            <div class="p4 orange">{{ PostSelect.total_click }} Lượt</div>
          </div>
          <div class="f jcb aic w100">
            <div class="h6 gray-1-text">Lượt bình luận</div>
            <div class="p4 orange">{{ dataComment.length }} Lượt</div>
          </div>
          <!-- <div class="f jcb aic w100">
                        <div class="h6 gray-1-text">Lượt xem</div>
                        <div class="p4 orange">{{PostSelect.total_click}} Lượt</div>
                    </div> -->
        </div>
      </div>
    </div>

    <!-- Chủ đề tương tự -->
    <!-- <div class="post-relate__container bg-white-1">
            <div class="post-relate__header mb1">
                <div class="h3 brown">
                    Chủ đề tương tự
                </div>
            </div>
        </div> -->

    <LoginView
      v-if="LoginPopup"
      @closePopup="closePopupLogin"
      :statusFormProp="'register'"
    >
      <template #subfix>
        <div class="border-radius px1 p5" style="background: #fff">
          Vui lòng ăng nhập để tiếp tục
        </div>
      </template>
    </LoginView>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import LoginView from "@/views/LoginView/LoginView.vue";

import VueCookies from "vue-cookies";
import { HTTP } from "@/http-default";
import axios from "axios";
export default {
  components: {
    StarRating,
    LoginView,
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userId: null,
      type: "",
      categorySelect: {},
      postCategory: [],
      PostSelect: {},

      dataCategory: [],
      dataPost: [],
      dataComment: [],

      commentResponseSelect: null,
      commentContent: "",

      LoginPopup: false,
      timeout: null,
    };
  },

  watch: {
    $route: function () {
      this.filterData();
    },
  },

  computed: {
    checkReactPost() {
      var userId = localStorage.getItem("idUser");
      var dataReact = this.PostSelect.react?.length
        ? this.PostSelect.react.filter(
            (item) => parseInt(item.id) === parseInt(userId)
          )
        : [];

      if (dataReact.length !== 0) {
        return true;
      } else {
        return false;
      }
    },

    shareUrl() {
      const production = "https://drmom.com.vn";
      const idSharing = this.PostSelect?.id;
      const dataUser = JSON.parse(localStorage.getItem("myAccount"));
      const key_affiliate = dataUser?.code_affiliate || "";
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        `${production}/#/post-website/post-${idSharing}?key_affiliate=${key_affiliate}`
      )}`;
    },
  },

  methods: {
    convertTime(value) {
      var time = value ? value.split("T") : value;
      return time?.[0] || time;
    },

    convertUrl(value) {
      var url = value.replaceAll("/", "%2F");
      url = url.replaceAll("?", "%3F");
      url = url.replaceAll("=", "%3D");
      return url;
    },

    handleLikePost() {
      var userId = localStorage.getItem("idUser");

      this.PostSelect.react.push({
        id: parseInt(userId),
      });

      this.handlePutData();
    },

    handleDistLikePost() {
      var userId = localStorage.getItem("idUser");
      for (let i = 0; i < this.PostSelect.react.length; i++) {
        if (this.PostSelect.react[i].id === parseInt(userId)) {
          this.PostSelect.react.splice(i, 1);
        }
      }

      this.handlePutData();
    },

    handlePutData() {
      var params = this.$route.params.id.split("-");

      const system_show = [];
      this.PostSelect.system_show.forEach((item) => {
        system_show.push(item.id);
      });

      const media = [];
      this.PostSelect.media.forEach((item) => {
        media.push(item.id);
      });

      const react = [];
      this.PostSelect.react.forEach((item) => {
        react.push(item.id);
      });

      var data = JSON.stringify({
        permalink: this.PostSelect.permalink,
        system_show: system_show,
        user_created: this.PostSelect.user_created.id,
        media: media,
        react: react,
      });

      HTTP.put("v1/api/post/" + params[1] + "/", data);
    },

    handlePostComment(event) {
      var params = this.$route.params.id.split("-");
      var userId = localStorage.getItem("idUser");

      if (event.key === "Enter") {
        event.preventDefault();
        var data = {
          content: this.commentContent,
          status_comment: 5, // fix theo status db
          post: params[1],
          user_customer: parseInt(userId),
          comment_parent:
            this.commentResponseSelect === null
              ? null
              : this.commentResponseSelect.id,
        };

        // this.dataComment.push(data)
        const dataPost = JSON.stringify(data);

        HTTP.post("v1/api/commentpost/", dataPost).then(() => {
          this.commentContent = "";
          HTTP.get(
            "v1/api/commentpost/?depth=1&post=" + parseInt(params[1])
          ).then((res) => {
            this.dataComment = res.data.results.filter((e) => e.user_customer);
          });
        });
      }
    },

    filterData() {
      var params = this.$route.params.id.split("-");
      if (params[0] === "category") {
        this.type = params[0];

        const data = this.dataCategory.filter(
          (item) => item.id == parseInt(params[1])
        );
        this.categorySelect = data[0];

        this.postCategory = this.dataPost.filter(
          (item) => item.category[0]?.id === this.categorySelect.id
        );
        this.PostSelect = this.postCategory?.[0] || null;
      } else if (params[0] === "post") {
        this.type = params[0];

        // const data = this.dataPost.filter(item => item.id == parseInt(params[1]))

        axios
          .get("https://api.drmom.store/v1/api/post/" + params[1] + "/?depth=1")
          .then((res) => {
            this.PostSelect = res.data;
          });
        // this.PostSelect = data[0]

        axios
          .get(
            "https://api.drmom.store/v1/api/commentpost/?depth=1&post=" +
              parseInt(params[1])
          )
          .then((res) => {
            this.dataComment = res.data.results.filter((e) => e.user_customer);
          });
      }
    },

    async getData() {
      await axios
        .get("https://api.drmom.store/v1/api/categorypost/")
        .then((res) => {
          this.dataCategory = res.data.results;
        });

      await axios
        .get("https://api.drmom.store/v1/api/post/" + "?depth=1")
        .then((res) => {
          var data = res.data.results;
          this.dataPost = data.filter(
            (item) => item.approved_status === "approved"
          );
        });

      this.filterData();
    },

    makeUrlAllfiliate() {
      const key_affiliate = this.$route.query.key_affiliate;
      var params = this.$route.params.id.split("-");
      const post_id = params[1]
      if (key_affiliate) {
        const payload = {
          key_affiliate: key_affiliate,
          post_id: post_id,
          affiliate_link: window.location.href,
          option: "c",
        };
        HTTP.post("/affiliate/create/", payload);
      }
    },

    setOpenPopupLogin() {
      this.timeout = setTimeout(() => {
        this.LoginPopup = true;
      }, 2 * 60 * 1000); // 2 minutes
    },

    closePopupLogin() {
      this.LoginPopup = false;
      this.setOpenPopupLogin();
    },
  },

  created() {
    this.userId = parseInt(localStorage.getItem("idUser"));
    if (!this.userId) {
      this.setOpenPopupLogin();
    }
    this.getData();
    this.makeUrlAllfiliate();
  },
};
</script>

<style scoped lang='scss'>
@import "CategoryPostWeb.scss";
</style>