@<template>
  <div class="chat__container">
    <!-- <div class="chat__header mb15 f jce aic">
            <button class="f jcb aic pt075 pb075 pl1 pr1 border-radius bg-brown cursor">
                <div class="icon--setting-white"></div>
                <div class="p6 ml05 white">Cài đặt</div>
            </button>
        </div> -->
    <div class="chat__body f border-radius bg-white-1">
      <div class="chat__body-list f fdc px1 border-right">
        <div class="chat-list__title mb1 p2 brown">
          Nhắn tin
        </div>
        <div class="chat-list__search relative text-input--search mb15 pr1">
          <div
            class="absolute"
            style="top: 33%; left: 1.05em"
          >
            <div class="icon--search-1"></div>
          </div>
          <input
            class="text-input__el border-radius h6 pt075 pb075 pl075 pr075"
            placeholder="Nhập họ tên, số điện thoại khách hàng"
            type="text"
          >
        </div>

        <!-- danh sách người chat -->
        <div class="chat-list__customer h100">
          <div
            @click="getMessage(item)"
            v-for="(item, index) in dataCustomer"
            :key="index"
          >
            <div
              v-if="item.id === customerSelect"
              class="f jcb aic border-radius pt075 pb075 pl1 pr1 bg-gray-2 cursor"
              @click="customerSelect = item.id, psid = item.psid"
            >
              <div class="f aic w100">
                <img
                  v-if="item.avatar_url"
                  :src="item.avatar_url"
                  alt="Hello"
                >
                <img
                  v-else
                  src="./avatar.jpeg"
                  alt=""
                >
                <div class="f fdc ml05">
                  <div class="p4 mb025"> {{item.name}} </div>
                  <div class="h6 gray"> {{item.newChat}} </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="f jcb aic pt075 pb075 pl1 pr1 cursor"
              @click="customerSelect = item.id, psid = item.psid"
            >
              <div class="f aic w100">
                <img
                  v-if="item.avatar_url"
                  :src="item.avatar_url"
                  alt="Hello"
                >
                <img
                  v-else
                  src="./avatar.jpeg"
                  alt=""
                >
                <div class="f fdc ml05">
                  <div class="p4 mb025"> {{item.name}} </div>
                  <div class="h6 gray"> {{item.newChat}} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat__body-areachat relative">
        <div
          v-for="(item, index) in dataCustomer"
          :key="index"
        >
          <div v-if="item.id === customerSelect">
            <div class="w100 f aic pt1 pb1 pl1 pr1 border-bottom">
              <img
                v-if="item.avatar_url"
                :src="item.avatar_url"
                alt="Hello"
              >
              <img
                v-else
                src="./avatar.jpeg"
                alt=""
              >
              <div class="f fdc ml05">
                <div class="p4 mb025"> {{item.name}} </div>
                <div class="h6 gray"> {{item.newChat}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-area__list pl1 pr1 pt1 bg-gray-2">
          <div
            v-for="(item, index) in dataMessageSelect"
            :key="index"
          >
            <div
              class="w100 f jce aic mb1 white h6"
              style="overflow-wrap: anywhere;text-align: left;"
              v-if="item.page_send"
            >
              <div class="message-page">
                {{ item.message }}
              </div>
            </div>
            <div
              class="w100 f jcs aic mb1 h6"
              style="overflow-wrap: anywhere;text-align: left;"
              v-if="!item.page_send"
            >
              <div class="message-member">
                {{ item.message }}
              </div>
            </div>
          </div>
        </div>
        <div class="chat-area__box pt05 pb05 pl1 pr1 bg-gray-2">
          <div class="w100 f jcb aic pt075 pb075 pl075 pr075 border border-radius bg-white-1">
            <input
              @keypress="handleSendMessage"
              v-model="messageText"
              class="w100 h6 bg-white-1"
              placeholder="Trả lời khách hàng"
              type="text"
              name=""
              id=""
            >

            <div class="chat-area__box-action f aic">
              <div class="p4 blue mr05 cursor">Gửi</div>
              <div class="icon--file mr05 p4 blue"></div>
              <div class="icon--smile p4 blue"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        chatStatus : String
    },
    
    data () {
        return {
            customerSelect : 1,
            psid: '',
            dataCustomer : [],
            dataMessage: [],
            dataMessageSelect : [],
            messageText : ''
        }
    },

    watch : {
        'customerSelect' : function () {
            clearInterval(this.getMessage())

            this.dataMessageSelect = []
            this.dataMessage.forEach(item => {
                if (item.user_fb === this.customerSelect) {
                    this.dataMessageSelect.push(item)
                }
            })

            var chatbox = document.getElementsByClassName('chat-area__list')[0]
            setTimeout(() => {
                chatbox.scrollTop = chatbox.scrollHeight
            }, 1)
        },

        'chatStatus' : function () {
            this.dataMessageSelect = [],
            this.dataMessage = []

            if (this.chatStatus) {
                // lấy danh sách khách hàng
                HTTP.get('/v1/api/userfb/')
                .then((res) => {
                    var data = res.data.results
                    this.dataCustomer = data.filter(item => item.page == this.chatStatus)
                    this.dataCustomer.reverse()
                })

                // HTTP.get('/v1/api/messagefb/')
                // .then((res) => {
                //     this.dataMessage = res.data.results.reverse()
                // })
            }
        },
        
        'dataMessage' : function () {
            this.dataMessageSelect = []
            this.dataMessage.forEach(item => {
                if (item.user_fb === this.customerSelect) {
                    this.dataMessageSelect.push(item)
                }
            })
        }
    },

    mounted () {
        this.getData()
    },

    methods : {
        async getData () {
            setInterval(() => {
                if (this.chatStatus) {
                    // lấy danh sách khách hàng
                    HTTP.get('/v1/api/userfb/')
                    .then((res) => {
                        var data = res.data.results
                        this.dataCustomer = data.filter(item => item.page == this.chatStatus)
                        this.dataCustomer.reverse()
                    })

                    HTTP.get('v1/api/messagefb/')
                    .then((res) => {
                        this.dataMessage = res.data.results.reverse()
                        var chatbox = document.getElementsByClassName('chat-area__list')[0]
                        setTimeout(() => {
                            chatbox.scrollTop = chatbox.scrollHeight
                        }, 1)
                    })
                }
            }, 5000)

            // if (this.dataCustomer[0]) {
            //     this.customerSelect = this.dataCustomer[0].id
            // }
        },

        getMessage () {
            HTTP.get('v1/api/messagefb/')
            .then((res) => {
                this.dataMessage = res.data.results.reverse()
                var chatbox = document.getElementsByClassName('chat-area__list')[0]
                setTimeout(() => {
                    chatbox.scrollTop = chatbox.scrollHeight
                }, 1)
            })
        },

        handleSendMessage (event) {
            if (event.key === "Enter") {
                var newMessage = {
                    message: this.messageText,
                    user_fb: this.customerSelect,
                    page_send:true
                }

                this.dataMessageSelect.push(newMessage)
                var chatbox = document.getElementsByClassName('chat-area__list')[0]
                setTimeout(() => {
                    chatbox.scrollTop = chatbox.scrollHeight;
                }, 1)
                
                const data = JSON.stringify({
                    psid : this.psid,
                    message : this.messageText
                })

                if (this.chatStatus === 'Zalo') {
                    HTTP.post('https://api.drmom.store/sendmessage_zl', data)

                } else {
                    HTTP.post('https://api.drmom.store/sendmessage', data)
                }

                this.messageText = ''
            }   
        }
    },

    created () {
        if (this.chatStatus) {
            // lấy danh sách khách hàng
            HTTP.get('/v1/api/userfb/')
            .then((res) => {
                var data = res.data.results
                this.dataCustomer = data.filter(item => item.page == this.chatStatus)
            })

            // HTTP.get('/v1/api/messagefb/')
            // .then((res) => {
            //     this.dataMessage = res.data.results.reverse()
            // })
        }

        HTTP.get('v1/api/messagefb/')
        .then((res) => {
            this.dataMessage = res.data.results.reverse()
            var chatbox = document.getElementsByClassName('chat-area__list')[0]
            setTimeout(() => {
                chatbox.scrollTop = chatbox.scrollHeight
            }, 1)
        })
    }
}
</script>

<style scoped lang="scss">
@import 'CustomerChat.scss';
</style>