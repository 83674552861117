@<template>
    <div class="pop-up-add-folder jcc aic z10">
        <div class="add-folder">
            <form class="bg-white-1 pt1 pb1 pr1 pl1 border-radius" action="">
                <div class="body f">

                  <!-- body-left -->
                    <div class="body-left mr1">
                        <div 
                          v-if="dataField.props"
                          class="left__header h5 brown mb1">
                            Danh mục {{dataField.props.titlecontent}} mới
                        </div>
                        <div class="left__name-folder f fdc mb15">
                            <label class="mb025 h7" for="">Tên danh mục</label>
                            <input 
                              v-model="titleCategory"
                              type="text" 
                              placeholder="Nhập tên danh mục">
                        </div>
                        <div class="left__search f fdc">
                            <label class="mb1 h5 brown" for="">Thêm sản phẩm vào danh mục</label>
                            <input 
                              v-if="$route.params.path === 'quan-ly-post'"
                              type="text" placeholder="Nhập bài viết bạn muốn tìm">
                            <input 
                              v-else
                              type="text" placeholder="Nhập sản phẩm bạn muốn tìm">
                        </div>
                        <div class="left__list">

                          <!-- landing -->
                          <div v-if="$route.params.path === 'quan-ly-funnel'">
                              <div class="left__list-header w100 p5 pt1 pb05 ">
                                  <div class="f aic jcb" style="width: 5%">
                                      <input 
                                        @click="handleSelectAll"
                                        class="form-add-folder__input" 
                                        type="checkbox" 
                                        value="" 
                                        id="flexCheckDefault">
                                  </div>
                                  <div class="align-c p5 gray">
                                      Tên Trang Landing
                                  </div>
                                  <div class="align-c p5 gray">
                                      Ngày khởi tạo
                                  </div>
                                  <div class="align-c p5 gray">
                                      Người khởi tạo
                                  </div>
                              </div>
                              <div class="left__list-body">
                                  <div 
                                    v-for="(item, key) in item" 
                                    :key="key" 
                                    class="body__item-list w100 f aic jcb p5 pt1 pb1 border-bottom bg-white-1">
                                      <div class=" f aic jcb" style="width: 5%">
                                          <input 
                                            @click="handleSelect"
                                            class="form-add-folder__input" 
                                            type="checkbox" 
                                            :value="item.id" 
                                            >
                                      </div>
                                      <div class="align-c p4 blue">
                                          {{item.title_landing}}
                                      </div>
                                      <div class="align-c h6">
                                          {{formatTime(item.created_at)}}
                                      </div>
                                      <div class="align-c p4 gray">
                                          {{item.user_created}}
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- Bài viết -->
                          <div v-else-if="$route.params.path === 'quan-ly-post'">
                              <div class="body__item-post w100 p5 pt1 pb05 ">
                                  <div class="f aic jcb" style="width: 5%">
                                      <input 
                                        @click="handleSelectAll"
                                        class="form-add-folder__input" 
                                        type="checkbox" 
                                        value="" 
                                        id="flexCheckDefault">
                                  </div>
                                  <div class="align-c p5 gray">
                                      Tiêu đề bài viết
                                  </div>
                                  <div class="align-c p5 gray">
                                      Lượt xem
                                  </div>
                                  <div class="align-c p5 gray">
                                      Lượt chia sẻ
                                  </div>
                              </div>
                              <div class="left__list-body">
                                  <div 
                                    v-for="(item, key) in item" 
                                    :key="key" 
                                    class="body__item-post w100 f aic jcb p5 pt1 pb1 border-bottom bg-white-1">
                                      <div class=" f aic jcb" style="width: 5%">
                                          <input 
                                            @click="handleSelect"
                                            class="form-add-folder__input" 
                                            type="checkbox" 
                                            :value="item.id" 
                                            >
                                      </div>
                                      <div class="align-l p4 blue">
                                          {{item.title_post}}
                                      </div>
                                      <div class="align-c h6">
                                          {{item.total_click}} lượt
                                      </div>
                                      <div class="align-c p4 gray">
                                          {{item.total_share}} lượt
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- product -->
                          <div v-else>
                              <div class="left__list-header-product w100 p5 pt1 pb05 ">
                                  <div class="f aic jcb" style="width: 5%">
                                      <input 
                                        @click="handleSelectAll"
                                        class="form-add-folder__input" 
                                        type="checkbox" 
                                        value="" 
                                        id="flexCheckDefault">
                                  </div>
                                  <div class="align-c p5 gray">
                                      Sản phẩm
                                  </div>
                                  <div class="align-c p5 gray">
                                      Giá sản phẩm
                                  </div>
                                  <div class="align-c p5 gray">
                                      Giá khuyến mãi
                                  </div>
                                  <div class="align-c p5 gray">
                                      Giá bán
                                  </div>
                              </div>
                              <div class="left__list-body-product">
                                  <div 
                                    v-for="(item, key) in item" 
                                    :key="key" 
                                    class="body__item-list w100 f aic jcb p5 pt1 pb1 border-bottom bg-white-1">
                                      <div class=" f aic jcb" style="width: 5%">
                                          <input 
                                            @click="handleSelect"
                                            class="form-add-folder__input" 
                                            type="checkbox" 
                                            :value="item.id" 
                                            >
                                      </div>
                                      <div class="align-s f aic p4 blue">
                                          <img :src="item.media[0].file_media" alt="">
                                          <div class="ml1">
                                            <div class="mb025 p4 blue"> {{item.title_product}} </div>
                                            <div class="h6 gray"> Tồn kho: {{item.inventory}} {{item.unit}} </div>
                                          </div>
                                      </div>
                                      <div 
                                        v-if="item.price_product"
                                        class="align-c h6">
                                          {{formatMoney(item.price_product)}}
                                      </div>
                                      <div 
                                        v-if="item.price_sale"
                                        class="align-c h6">
                                          {{formatMoney(item.price_sale)}}
                                      </div>
                                      <div 
                                        v-if="item.price_product"
                                        class="align-c p4 brown">
                                          {{formatMoney(item.price_product)}}
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>

                    <!-- body-right -->
                    <div
                      v-if="$route.params.path === 'quan-ly-funnel'" 
                      class="body-right">
                        <div class="right__header h5 mb1">Sản phẩm đã chọn</div>
                        <div class="item-select__list">
                            <div 
                              class="item-select pt1 pb1 pr1 pl1 mb1 border-radius bg-gray-2 "
                              v-for="(item, key) in itemSelect" 
                              :key="key">

                                <!-- landing đã chọn -->
                                <div v-if="$route.params.path === 'quan-ly-funnel'">
                                  <div class="f jcb aic mb1">
                                    <div class="p4 blue">
                                      {{item.title_landing}}
                                    </div>
                                    <div>
                                      <div style="width: 0px" class="icon--close ml1"></div>
                                    </div>
                                  </div>
                                  <div class="f jcb aic mb05">
                                    <div class="p4 gray">Ngày khởi tạo</div>
                                    <div class="p4"> {{formatTime(item.created_at)}} </div>
                                  </div>
                                  <div class="f jcb aic mb05">
                                    <div class="p4 gray">Người khởi tạo</div>
                                    <div class="p4"> {{item.user_created}} </div>
                                  </div>
                                </div>

                                <!-- sản phẩm đã chọn -->
                                <!-- <div 
                                  v-else
                                  class="item-select__info" >
                                    <div class="f jcb aic mb1">
                                        <div class="f aic">
                                          <img class="mr1" src="../../../assets/imgUser.png" alt="">
                                          <div class="f fdc jcb">
                                              <div class="blue p4">{{item.product}}</div>
                                              <div class="h6">Tồn tại: {{item.inventory}}</div>
                                          </div>
                                        </div>
                                        <div class="f aic">
                                          <div class="change-quantity f jcb aic pt05 pb05 pl1 pr1 border border-radius bg-white-1">
                                            <div 
                                              @click="handleMinus"
                                              class="change-quantity__minus h5 mr1 cursor">-</div>
                                            <div class="change-quantity__number align-c h5 mr1">01</div>
                                            <div 
                                              @click="handlePlus"
                                              class="change-quantity__plus h5 cursor">+</div>
                                          </div>
                                          <div style="width: 0px" class="icon icon--close ml1"></div>
                                        </div>
                                    </div>
                                    <div class="pr2">
                                      <div 
                                        v-for="(value, key) in item"
                                        :key="key"
                                        class="f jcb aic w100">
                                          <div 
                                            class="f jcb aic w100"
                                            v-if="key != 'id' && key != 'inventory'">
                                            <div class="p4 gray">{{key}}</div>
                                            <div class="p4">{{value}}</div>
                                          </div>
                                      </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="f aic jce pt1 pr1 pb1 pl1 bg-blue-2">
                    <div 
                      @click="handleClosePopup"
                      class="add-folder__btn btn-black pt075 pb075 pr1 border-radius pl1 cursor white bg-black">
                        Hủy
                    </div>
                    <div
                      @click="handleCreateCategory" 
                      class="add-folder__btn btn-brown ml1 pt075 pb075 pr1 border-radius pl1 white bg-brown cursor">
                        Xác nhận
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'

export default {
  props : {
    dataField : {
      type : Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      titleCategory : '',
      key: [],
      item: [],
      itemSelect: [],
      wid: ''
    }
  },
  watch: {
    'titleSelect': function() {
      this.itemSelect = []
      this.handleFilter()
      setTimeout(() => {
        this.function()
        this.handleInfo()
      }, 1)
    },
    '$route' : function() {
      this.getData()
    }
  },
  methods : {

    // formatTime
    formatTime(time) {
        var timeFormat = time.split('T')
        timeFormat[1] = timeFormat[1].split('')
        timeFormat[1] = timeFormat[1].splice(0, 8)
        timeFormat[1] = timeFormat[1].join("")

        return timeFormat[1] + ' ' + timeFormat[0]
    },

    // formatMoney
    formatMoney(value) {
      var x = value;
      x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
      return x
    },

    handleStatus() {
       this.wid = (70/this.key.length)
    },
    handleFilter() {
      if(this.titleSelect == 'Tất cả') {
        for(let i = 0; i < this.item.length; i++) {
          this.itemSelect.push(this.item[i])
        }
      } else {
        for(let i = 0; i < this.item.length; i++) {
          if(this.item[i]['Trạng thái'] == this.titleSelect) {
            this.itemSelect.push(this.item[i])
          }
        }
      }
    },
    handleMinus(event) {
      var button = event.target.parentElement;
      var quantity = button.getElementsByClassName('change-quantity__number')[0]
      var number = parseInt(quantity.innerHTML)
      number = number - 1
      if (number < 10 && number > 0) {
        quantity.innerHTML = `0${number}`
      }
      else if (number < 0) {
        quantity.innerHTML = `0`
      }
      else {
        quantity.innerHTML = `${number}`
      }
    },
    handlePlus(event) {
      var button = event.target.parentElement;
      var quantity = button.getElementsByClassName('change-quantity__number')[0]
      var number = parseInt(quantity.innerHTML)
      number = number + 1
      if (number < 10 && number > 0) {
        quantity.innerHTML = `0${number}`
      }
      else {
        quantity.innerHTML = `${number}`
      }
    },

    // xu ly select input checkbox
    handleSelect(event) {
      var checkbox = event.target
      if (checkbox.checked == true) {
        for(let i = 0; i < this.item.length; i++) {
          if(checkbox.value == this.item[i].id) {
            this.itemSelect.push(this.item[i])
          }
        }
      } 
      if (checkbox.checked == false) {
        for(let i = 0; i < this.itemSelect.length; i++) {
          if(checkbox.value == this.itemSelect[i].id) {
            this.itemSelect.splice(i, 1)
          }
        }
      }
    },

    // xu ly select all
    handleSelectAll(event) {
      var checkbox = event.target
      var input = document.getElementsByClassName('form-add-folder__input')
      if (checkbox.checked == true) {
        for (let i = 0; i < input.length; i ++) {
          input[i].checked = true
        }
        for(let i = 0; i < this.item.length; i++) {
          if (!this.itemSelect.includes(this.item[i])) {
            this.itemSelect.push(this.item[i])
          } 
        }
      } 
      if (checkbox.checked == false) {
        for (let i = 0; i < input.length; i ++) {
          input[i].checked = false
          this.itemSelect = []
        }
      }
    },

    handleClosePopup() {
      var popup = document.getElementsByClassName('pop-up-add-folder')[0];
      const CustomerView = document.getElementsByClassName('Cus-container')?.[0]; 
      popup.classList.remove('popup-active')
      CustomerView && CustomerView.classList.remove('popup-flow')
    },

    // tạo danh mục
    handleCreateCategory () {
      var userId = localStorage.getItem('idUser')
      if (this.$route.params.path === 'quan-ly-funnel') {
        var landingId = []

        this.itemSelect.forEach(item => {
          landingId.push(item.id)
        })

        const data = JSON.stringify({
          "title_funnel": this.titleCategory,
          "key_funnel": Math.floor(Math.random() * 1000000000),
          "user_created": userId,
          "landing": landingId
        })

        HTTP.post('v1/api/funnel/', data)
        .then(() => {
          var popup = document.getElementsByClassName('pop-up-add-folder')[0]
          var CustomerView = document.getElementsByClassName('Cus-container')[0];
          popup.classList.remove('popup-active');
          CustomerView.classList.remove('popup-flow');
          this.$emit('updateDataCategory')
        })
      } 
      else if (this.$route.params.path === 'quan-ly-post') {
        const data = JSON.stringify({
          "category_name": this.titleCategory,
          "slug_category": Math.floor(Math.random() * 1000000000),
          "user_created": parseInt(userId),
        })

        HTTP.post('v1/api/categorypost/', data)
        .then((res) => {
          var categoryId = []
          categoryId.push(res.data.id)

          this.itemSelect.forEach(item => {
            const dataPatch = JSON.stringify({
              category : categoryId
            })

            HTTP.patch('v1/api/post/' + item.id + '/', dataPatch)
          })
          
          var popup = document.getElementsByClassName('pop-up-add-folder')[0]
          var CustomerView = document.getElementsByClassName('Cus-container')[0];
          popup.classList.remove('popup-active');
          CustomerView.classList.remove('popup-flow');
          this.$emit('updateDataCategory')
        })
      } 
      else {
        var productSelect = []

        this.itemSelect.forEach(item => {
          productSelect.push(item.id)
        })

        const data = JSON.stringify({
          "category_name": this.titleCategory,
          "slug_category": Math.floor(Math.random() * 1000000000),
          "user_created": userId,
          "product_list": productSelect
        })

        HTTP.post('v1/api/categoryproduct/', data)
        .then(() => {
          var popup = document.getElementsByClassName('pop-up-add-folder')[0]
          var CustomerView = document.getElementsByClassName('Cus-container')[0];
          popup.classList.remove('popup-active');
          CustomerView.classList.remove('popup-flow');
          this.$emit('updateDataCategory')
        })
      } 
    },

    // get data 
    getData () {
      if (this.$route.params.path === 'quan-ly-funnel') {
        HTTP.get('v1/api/landing/')
        .then((res) => {
          this.item = res.data.results
        })
      } 
      else if (this.$route.params.path === 'quan-ly-post') {
        HTTP.get('v1/api/post/')
        .then((res) => {
          this.item = res.data.results
        })
      }
      else {
        HTTP.get('v1/api/product/?depth=1')
        .then((res) => {
          this.item = res.data.results
        })
      }
    }
  },
  created() {
    // this.key = Object.keys(this.item[0])
    this.handleStatus();
    this.getData()
  }
}
</script>

<style scoped lang="scss">
@import 'AddFolder.scss';
</style>