@<template>
    <div class="popup popup-noti-addnew f jcc aic">
        <div class="noti-addnew__container pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="icon-noti f fdc jcc aic mb1">
                <div class="icon--noti-success mb1"></div>
                <div class="h2 gray align-c">Thông báo</div>
            </div>
            <div class="noti__body mb15">
                <div class="p6 gray-1-text align-c mb05">
                    Xác nhận thêm mới khách hàng?
                </div>
            </div>
            <div class="noti-addnew__action w100 f jcc aic">
                <div 
                    @click="handleClosePopup()"
                    class="pt075 pb075 pl1 pr1 h6 brown border-radius border cursor">
                    Huỷ bỏ
                </div>
                <div 
                    @click="handleSubmit"
                    class="pt075 pb075 pl1 pr1 ml1 h6 border-radius bg-brown white cursor">
                    Xác nhận
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        dataInput : {
            type : Object,
            default () {
                return {}
            }
        },
        dataCustomerRelate : {
            type : Array,
            default () {
                return []
            }
        }
    },

    methods : {
        handleClosePopup (created) {
            this.$emit('closeNotiPopup', created)
        },

        async handleSubmit () {
            const idUser = JSON.parse(localStorage.getItem('idUser'))
            var listCustomerrelate = [...this.dataCustomerRelate]
            var dataInput = {...this.dataInput}

            var listCustomerRelateId = []
            
            if (listCustomerrelate.length !== 0) {
                listCustomerrelate.forEach(item => {
                    if (item.user) {
                        const newUser = JSON.stringify({
                            "username": item.email,
                            "password": "1"
                        })

                        HTTP.post('register/', newUser)
                        .then((res) => {
                            let data = new FormData(); 

                            // data.append('avatar', item.avatar[0]);
                            data.append('name', item.full_name);
                            data.append('phone', item.phone);
                            data.append('key_customer', item.key_customer);
                            data.append('address', item.address);
                            data.append('email', item.email);
                            data.append('user_customer_relate', res.data.id);
                            data.append('user_created', idUser);

                            HTTP.post('v1/api/peoplerelate/', data)
                            .then((res) => {
                                var id = res.data.id
                                listCustomerRelateId.push(id)

                                console.log(listCustomerRelateId, typeof listCustomerRelateId);

                                if (listCustomerRelateId.length === this.dataCustomerRelate.length) {
                        
                                // Tạo tài khoản mới
                                let newUser = JSON.stringify({
                                    "username": this.dataInput.email,
                                    "password": "1"
                                })

                                HTTP.post('register/', newUser).
                                then((res) => {
                                    const user = res.data 

                                    dataInput.link_fb_zl = '[{' + 'facebook' + ':' + this.linkFb + '},{' + 'zalo:' + this.linkZalo + '}]'
                                    let data = JSON.stringify({
                                        'full_name' : this.dataInput.full_name,
                                        'phone' : this.dataInput.phone,
                                        'key_customer' : Math.floor(Math.random() * 1000000000),
                                        'address' : this.dataInput.address,
                                        'email' : this.dataInput.email,
                                        'link_fb_zl' : JSON.stringify(this.dataInput.link_fb_zl),
                                        'user' : user.id,
                                        'people_relate' : listCustomerRelateId,
                                    })

                                    HTTP.post('v1/api/customer/', data)
                                    .then((res) => {
                                        this.handleClosePopup()

                                        var dataAvatar = new FormData()

                                        dataAvatar.append('avatar', this.dataInput.avatar[0]);
                                        dataAvatar.append('full_name', this.dataInput.full_name);
                                        dataAvatar.append('user', user.id);
                                        HTTP.put('v1/api/customer/' + res.data.id + '/', dataAvatar)
                                    })
                                })
                            }
                            })
                        })
                    } else {
                        let data = new FormData(); 

                        // data.append('avatar', item.avatar[0]);
                        data.append('name', item.full_name);
                        data.append('phone', item.phone);
                        data.append('key_customer', item.key_customer);
                        data.append('address', item.address);
                        data.append('email', item.email);
                        data.append('myrole', item.myrole);
                        data.append('user_created', idUser);

                        HTTP.post('v1/api/peoplerelate/', data)
                        .then((res) => {
                            var id = res.data.id
                            listCustomerRelateId.push(id)
                            console.log(listCustomerRelateId);

                            if (listCustomerRelateId.length === this.dataCustomerRelate.length) {
                        
                                // Tạo tài khoản mới
                                let newUser = JSON.stringify({
                                    "username": this.dataInput.email,
                                    "password": "1"
                                })

                                HTTP.post('register/', newUser).
                                then((res) => {
                                    const user = res.data 

                                    dataInput.link_fb_zl = '[{' + 'facebook' + ':' + this.linkFb + '},{' + 'zalo:' + this.linkZalo + '}]'
                                    let data = JSON.stringify({
                                        'full_name' : this.dataInput.full_name,
                                        'phone' : this.dataInput.phone,
                                        'key_customer' : Math.floor(Math.random() * 1000000000),
                                        'address' : this.dataInput.address,
                                        'email' : this.dataInput.email,
                                        'link_fb_zl' : JSON.stringify(this.dataInput.link_fb_zl),
                                        'user' : user.id,
                                        'people_relate' : listCustomerRelateId,
                                    })

                                    HTTP.post('v1/api/customer/', data)
                                    .then((res) => {
                                        this.handleClosePopup()

                                        var dataAvatar = new FormData()

                                        dataAvatar.append('avatar', this.dataInput.avatar[0]);
                                        dataAvatar.append('full_name', this.dataInput.full_name);
                                        dataAvatar.append('user', user.id);
                                        HTTP.put('v1/api/customer/' + res.data.id + '/', dataAvatar)
                                    })
                                })
                            }
                        })
                    }
                })
            } else {
                let newUser = JSON.stringify({
                    "username": this.dataInput.email,
                    "password": "1"
                })

                HTTP.post('register/', newUser).
                then((res) => {
                    const user = res.data 

                    dataInput.link_fb_zl = '[{' + 'facebook' + ':' + this.linkFb + '},{' + 'zalo:' + this.linkZalo + '}]'
                    // let data = JSON.stringify({
                    //     'full_name' : this.dataInput.full_name,
                    //     'phone' : this.dataInput.phone,
                    //     'key_customer' : Math.floor(Math.random() * 1000000000),
                    //     'address' : this.dataInput.address,
                    //     'email' : this.dataInput.email,
                    //     'link_fb_zl' : JSON.stringify(this.dataInput.link_fb_zl),
                    //     'user' : user.id,
                    //     'people_relate' : listCustomerRelateId,
                    // })

                    var dataCustomer = new FormData()
                    dataCustomer.append('avatar', this.dataInput.avatar[0])
                    dataCustomer.append('full_name', this.dataInput.full_name)
                    dataCustomer.append('address', this.dataInput.address)
                    dataCustomer.append('link_fb_zl', JSON.stringify(this.dataInput.link_fb_zl))
                    dataCustomer.append('key_customer', Math.floor(Math.random() * 1000000000000))
                    dataCustomer.append('phone', this.dataInput.phone)
                    dataCustomer.append('email', this.dataInput.email)
                    listCustomerRelateId?.length && dataCustomer.append('people_relate', JSON.stringify(listCustomerRelateId))
                    // dataCustomer.append('role', 2)
                    dataCustomer.append('user', user.id)

                    HTTP.post('v1/api/customer/', dataCustomer)
                    .then((res) => {
                        this.handleClosePopup(true)

                        // var dataAvatar = new FormData()

                        // dataAvatar.append('avatar', this.dataInput.avatar[0]);
                        // dataAvatar.append('full_name', this.dataInput.full_name);
                        // dataAvatar.append('user', user.id);
                        // HTTP.put('v1/api/customer/' + res.data.id + '/', dataAvatar)
                    })
                })
            }
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'NotiAddNew.scss';
</style>