<template>
  <div class="header f aic jcc w100 pt1 pb1 bg-white-1 border-bottom shadow">
    <div class="container--forums w100 f aic jcb">
      <div class="header-left f aic">
        <div>
          <router-link to="/website">
            <img src="../Landingpage/imgLanding/logo.png" alt="" />
          </router-link>
        </div>
        <div></div>
        <div class="cursor" v-for="(item, index) in dataNav" :key="index">
          <template v-if="item.listChildren">
            <div class="dropdown" tabindex="0">
              <input type="checkbox" id="dropdown" />
              <label class="dropdown__face" for="dropdown">
                <div class="dropdown__text h4 gray-1-text">
                  {{ item.title }}
                </div>
                <div class="dropdown__arrow"></div>
              </label>

              <ul class="dropdown__items">
                <p
                  v-for="(child, indexChild) in item.listChildren"
                  :key="indexChild"
                  @click="$router.push(`/${item.path}/category-${child.id}`)"
                  class="sub-item-menu"
                >
                  {{ child.category_name }}
                </p>
              </ul>
            </div>
          </template>
          <template v-else>
            <router-link
              :to="{ name: 'body-view', params: { path: item.path } }"
              class="h4 brown"
              v-if="$route.params.path === item.path"
            >
              {{ item.title }}
            </router-link>
            <div v-else class="h4 gray-1-text">{{ item.title }}</div>
          </template>
        </div>
      </div>
      <div class="header-right relative f aic">
        <!-- search product -->
        <!-- <div class="mr1 search">
          <input type="text" class="search__form" placeholder="Tìm kiếm">
        </div> -->

        <!-- shopping cart -->
        <div
          v-if="$route.params.path === 'product'"
          class="shopping-cart__icon relative cursor"
        >
          <div
            v-if="!listCartcontainer"
            @click="listCartcontainer = true"
            class="icon icon--cart"
          ></div>

          <div
            v-if="listCartcontainer"
            @click="listCartcontainer = false"
            class="icon icon--cart"
          ></div>

          <div
            v-if="dataProductCart && dataProductCart.length !== 0"
            class="count-cart_container f jcc aic absolute bg-brown"
          >
            <div class="p7 white">{{ dataProductCart.length }}</div>
          </div>
          <!-- list cart item -->
          <div
            v-if="listCartcontainer"
            class="shopping-cart__list absolute border-radius bg-white-1"
          >
            <div class="cart-list__header f jcb aic pt1 pl1 pr1">
              <div class="h4 brown">Giỏ hàng sản phẩm</div>
            </div>

            <!-- có sản phẩm trong giỏ hàng -->
            <div
              v-if="dataProductCart.length !== 0"
              class="cart-list__body f fdc p1 relative border-radius"
            >
              <div
                v-for="(item, index) in dataProductCart"
                :key="index"
                class="product__cart"
                :style="{ 'animation-deley': index * 0.4 + 's' }"
                :id="'product-' + item.id"
              >
                <div
                  class="f aic pt05 pb05 pl05 pr05 border-radius bg-gray-2"
                  style="gap: 0.5em"
                >
                  <div class="cart-product__img f jcc">
                    <img
                      class="border-radius border"
                      :src="item.media[0].file_media"
                      alt=""
                    />
                  </div>
                  <div class="cart-product__title w100">
                    <div class="mb05 f jcb aic">
                      <div
                        style="max-width: calc(100% - 30px)"
                        class="two-line p5"
                      >
                        {{ item.title_product }}
                      </div>
                      <div
                        style="width: 10px"
                        @click="handleDelete(item)"
                        class="icon--close mr05 cursor"
                      ></div>
                    </div>
                    <div class="f jcb aic w100">
                      <div>
                        <div class="h6 mb025 gray-1-text text-line-through">
                          {{ converMoney(item.price_product) }}
                        </div>
                        <div class="p5 orange">
                          {{ converMoney(item.price_sale) }}
                        </div>
                      </div>
                      <input
                        v-model="item.quantity"
                        class="align-c border border-radius bg-white-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Không có sản phẩm trong kho -->
            <div v-if="dataProductCart.length === 0" class="f fdc jcc aic p1">
              <div class="icon--face-smile mb1"></div>
              <div class="p5">Bạn chưa có sản phẩm trong giỏ hàng</div>
            </div>

            <div
              v-if="dataProductCart.length !== 0"
              class="cart-list__action p1 bg-brown-1"
            >
              <div class="f jcb aic mb1">
                <div class="p4">Tổng tiền</div>
                <div class="h3 brown">{{ converMoney(totalPrice) }}</div>
              </div>
              <router-link
                :to="{ name: 'body-detail', params: { id: 'detail-cart' } }"
                class="f jcc aic pt075 pb075 border-radius bg-brown white"
              >
                <div class="h5">Xem giỏ hàng</div>
              </router-link>
            </div>
          </div>
        </div>

        <!-- Tài khoản của tôi -->
        <div v-if="dataUser.full_name">
          <div
            @click="handleLogin"
            class="action__container relative f aic ml05 border-radius"
          >
            <div class="p4 black mr1">{{ getDataAccount() }}</div>
            <img v-if="dataUser" :src="dataUser.avatar" class="img-user mr1" />
            <img src="../../assets/Header/Down.png" alt="" />
            <LogoutView></LogoutView>
          </div>
        </div>
        <div
          v-else
          class="action__container f aic pt075 pb075 pl1 pr1 border-radius bg-brown"
        >
          <div
            @click="(LoginPopup = true), (statusFormProp = 'register')"
            class="h5 pr05 border-right white cursor"
          >
            Đăng ký
          </div>
          <div
            @click="(LoginPopup = true), (statusFormProp = 'login')"
            class="h5 pl05 white cursor"
          >
            Đăng nhập
          </div>
        </div>
      </div>
    </div>

    <LoginView
      v-if="LoginPopup"
      @closePopup="closePopup"
      :statusFormProp="statusFormProp"
    />
  </div>
</template>

<script>
import LogoutView from "@/components/PopupLogout/LogoutView.vue";
import LoginView from "@/views/LoginView/LoginView.vue";

import bcrypt from "bcryptjs";
import { HTTP } from "@/http-default";
import axios from "axios";

export default {
  components: {
    LogoutView,
    LoginView,
  },

  props: {
    dataProductCart: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      dataUser: {},
      listCartcontainer: false,
      totalPrice: 0,
      dataNav: [
        {
          title: "Trang chủ",
          path: "website",
        },
        {
          title: "Kiến thức cho mẹ",
          path: "post-website",
          listChildren: [],
        },
        {
          title: "Forum",
          path: "forums",
        },
        {
          title: "Sản phẩm",
          path: "product",
        },
      ],
      LoginPopup: false,
      statusFormProp: null,
    };
  },

  watch: {
    handleTotlePrice: {
      deep: true,
      handler: function (newVal) {
        this.totalPrice = newVal;
      },
    },
  },

  computed: {
    isLogin() {
      return window.localStorage.user !== null;
    },

    handleTotlePrice() {
      var total = 0;

      if (this.dataProductCart !== null) {
        this.dataProductCart.forEach((item) => {
          let price = item.price_sale ? item.price_sale : item.price_product;
          total += price * item.quantity;
        });
      }

      return total;
    },
  },

  methods: {
    converMoney(value) {
      var money = value.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      });
      return money;
    },

    handleDelete(item) {
      let data = localStorage.getItem("cartProudct");
      let cart = JSON.parse(data);

      for (let i = 0; i < cart.length; i++) {
        if (cart[i].id === item.id) {
          var itemCart = document.getElementById("product-" + item.id);
          itemCart.classList.toggle("delete-cart");
          cart.splice(i, 1);
        }
      }

      setTimeout(() => {
        localStorage.setItem("cartProudct", JSON.stringify(cart));
        this.$emit("updateProductCart");
        var cartShow = document.getElementsByClassName("delete-cart");
        console.log(cartShow);
        for (let i = 0; i < cartShow.length; i++) {
          cartShow[i].classList.remove("delete-cart");
        }
      }, 400);
    },

    handleLogin() {
      var login = document.getElementsByClassName("log-out")[0];
      login.classList.toggle("active");
    },

    getDataAccount() {
      var myAccount = localStorage.getItem("myAccount");
      myAccount = JSON.parse(myAccount);
      return myAccount?.full_name;
    },

    getData() {
      HTTP.get("v1/api/customer/?depth=1").then((res) => {
        var data = res.data.results;
        var userId = parseInt(localStorage.getItem("idUser"));
        var filter = data.filter((item) => item.user.id === userId);
        this.dataUser = filter[0];
      });
    },

    closePopup() {
      this.LoginPopup = false;
    },

    async getDataCategory() {
      var url = "https://api.drmom.store/";
      await axios.get(url + "v1/api/categorypost/").then((res) => {
        this.dataNav[1].listChildren = res.data.results;
        this.dataNav = [...this.dataNav];
      });
    },
  },

  created() {
    var total = 0;

    if (this.dataProductCart !== null) {
      this.dataProductCart.forEach((item) => {
        let price = item.price_sale ? item.price_sale : item.price_product;
        total += price * item.quantity;
      });
    }

    this.totalPrice = total;

    this.getData();
    this.getDataCategory()

    setTimeout(() => {
      var login = document.getElementsByClassName("log-out")[0];
      login.classList.remove("active");
    }, 1);
  },
};
</script>

<style scoped lang="scss">
@import "HeaderType2.scss";
</style>
