@<template>
  <div class="container">
    <HeaderLayout2 :systemShow="systemShow" :data="dataField"></HeaderLayout2>
    <CategotyLayout2
      :data="dataField"
      :dataCategory="dataCategory"
      :dataProps="dataProps"
      :isMobile="isMobile"
    ></CategotyLayout2>
    <ListLayout2
      :systemShow="systemShow"
      :data="dataField"
      :dataProps="dataProps"
      :isMobile="isMobile"
    ></ListLayout2>
  </div>
</template>

<script>
import HeaderLayout2 from "../Layout2/HeaderLayout2/HeaderLayout2.vue";
import CategotyLayout2 from "../Layout2/CategoryLayout2/CategotyLayout2.vue";
import ListLayout2 from "../Layout2/ListLayout2/ListLayout2.vue";

export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    dataCategory: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    dataProps: {
      type: Array,
      default() {
        return [];
      },
    },
    dataField: {
      type: Object,
      default() {
        return {};
      },
    },

    systemShow: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderLayout2,
    CategotyLayout2,
    ListLayout2,
  },
  // data() {
  //     return {
  //         data : [],
  //         dataField: [],
  //         dataProps : [],
  //         dataCategory: []
  //     }
  // },
  // methods: {

  // },
  // created() {

  // }
};
</script>

<style>
</style>