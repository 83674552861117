@<template>
  <div
    class=""
    :style="{ 'background-color' : dataLanding.color }"
  >

    <!-- form in right -->
    <div
      v-if="dataLanding.style === 2"
      v-bind:style="{ 'background-image': 'url(' + dataLanding.img_landing + ')' }"
      class="landing__container w100 f jcc ais"
    >
      <div class="landing__content-img border-radius relative w100">
        <!-- <div class="img-landing w100">
                    <img 
                        class="w100 border-radius"
                        :src="dataLanding.img_landing" alt="">
                </div> -->
        <div class="title-landing border-radius absolute w100">
          <div class="title-landing__bg f fdc ais px2 border-radius white">
            <div class="h1">
              {{dataLanding.title_landing}}
            </div>

            <div class="p6">
              {{dataLanding.description}}
            </div>

            <div class="w100 h100">
              <iframe
                class="border-radius"
                width="100%"
                height="100%"
                controls
                :src="'https://www.youtube.com/embed/' +  dataLanding.link_img"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="landing__content-form w100 f fdc ais jcc border-radius">
        <div class="form__header w100 h1 px05 mb05 bg-white-1 border-radius">
          Điền thông tin của bạn
        </div>
        <div class="form__body f fdc ais w100 mb1">
          <div
            class="f fdc ais w100 px1 bg-white-1 border-radius shadow"
            v-for="(item, index) in dataLanding.question"
            :key="index"
          >
            <label
              class="p5"
              for=""
            > {{item}} </label>
            <input
              v-if="item == 'Họ và tên'"
              :placeholder="'Nhập họ và tên'"
              v-model="dataInput.name"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else-if="item == 'Số điện thoại'"
              :placeholder="'Nhập số điện thoại'"
              v-model="dataInput.phone"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else-if="item == 'Email'"
              :placeholder="'Nhập email'"
              v-model="dataInput.email"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else
              :placeholder="'Nhập ' + item "
              :id="item"
              class="w100 mt1 border border-radius"
              type="text"
            >
          </div>
        </div>
        <div class="w100 f jcc aic">
          <div
            @click="handleRegisterCustomer"
            class="form__action w100 p3 px075 border-radius align-c white bg-brown cursor shadow"
          >
            Đăng ký ngay
          </div>
        </div>
      </div>
    </div>

    <!-- form in left -->
    <div
      v-if="dataLanding.style === 1"
      v-bind:style="{ 'background-image': 'url(' + dataLanding.img_landing + ')' }"
      class="landing__container-left w100 f jcc ais"
    >
      <div class="landing__content-form w100 f fdc ais jcc border-radius">
        <div class="form__header w100 h1 px05 mb05 bg-white-1 border-radius">
          Điền thông tin của bạn
        </div>
        <div class="form__body f fdc ais w100 mb1">
          <div
            class="f fdc ais w100 px1 bg-white-1 border-radius shadow"
            v-for="(item, index) in dataLanding.question"
            :key="index"
          >
            <label
              class="p5"
              for=""
            > {{item}} </label>
            <input
              v-if="item == 'Họ và tên'"
              :placeholder="'Nhập họ và tên'"
              v-model="dataInput.name"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else-if="item == 'Số điện thoại'"
              :placeholder="'Nhập số điện thoại'"
              v-model="dataInput.phone"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else-if="item == 'Email'"
              :placeholder="'Nhập email'"
              v-model="dataInput.email"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else
              :placeholder="'Nhập ' + item "
              :id="item"
              class="w100 mt1 border border-radius"
              type="text"
            >
          </div>
        </div>
        <div class="w100 f jcc aic">
          <div
            @click="handleRegisterCustomer"
            class="form__action w100 p3 px075 border-radius align-c white bg-brown cursor shadow"
          >
            Đăng ký ngay
          </div>
        </div>
      </div>
      <div class="landing__content-img border-radius relative w100">
        <!-- <div class="img-landing w100">
                    <img 
                        class="w100 border-radius"
                        :src="dataLanding.img_landing" alt="">
                </div> -->
        <div class="title-landing border-radius absolute w100">
          <div class="title-landing__bg f fdc ais px2 border-radius white">
            <div class="h1">
              {{dataLanding.title_landing}}
            </div>

            <div class="p6">
              {{dataLanding.description}}
            </div>

            <div class="w100 h100">
              <iframe
                class="border-radius"
                width="100%"
                height="100%"
                controls
                :src="'https://www.youtube.com/embed/' +  dataLanding.link_img"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- form in center -->
    <div
      v-if="dataLanding.style === 3"
      v-bind:style="{ 'background-image': 'url(' + dataLanding.img_landing + ')' }"
      class="landing__container-center w100 f fdc jcc aic"
    >
      <div class="landing__content-img border-radius relative w100">
        <!-- <div class="img-landing w100">
                    <img 
                        class="w100 border-radius"
                        :src="dataLanding.img_landing" alt="">
                </div> -->
        <div class="title-landing border-radius w100">
          <div class="title-landing__bg f fdc ais px2 border-radius white">
            <div class="h1">
              {{dataLanding.title_landing}}
            </div>

            <div class="p6">
              {{dataLanding.description}}
            </div>

            <div class="w100 h100">
              <iframe
                class="border-radius"
                width="100%"
                height="100%"
                controls
                :src="'https://www.youtube.com/embed/' +  dataLanding.link_img"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="landing__content-form w100 f fdc ais jcb border-radius">
        <div class="form__header w100 h1 px05 mb05 bg-white-1 border-radius">
          Điền thông tin của bạn
        </div>
        <div class="form__body f fdc ais w100 mb1">
          <div
            class="f fdc ais w100 px1 bg-white-1 border-radius shadow"
            v-for="(item, index) in dataLanding.question"
            :key="index"
          >
            <label
              class="p5"
              for=""
            > {{item}} </label>
            <input
              v-if="item == 'Họ và tên'"
              :placeholder="'Nhập họ và tên'"
              v-model="dataInput.name"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else-if="item == 'Số điện thoại'"
              :placeholder="'Nhập số điện thoại'"
              v-model="dataInput.phone"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else-if="item == 'Email'"
              :placeholder="'Nhập email'"
              v-model="dataInput.email"
              class="w100 mt1 border border-radius"
              type="text"
            >
            <input
              v-else
              :placeholder="'Nhập ' + item "
              :id="item"
              class="w100 mt1 border border-radius"
              type="text"
            >
          </div>
        </div>
        <div class="w100 f jcc aic">
          <div
            @click="handleRegisterCustomer"
            class="form__action w100 p3 px075 border-radius align-c white bg-brown cursor shadow"
          >
            Đăng ký ngay
          </div>
        </div>
      </div>

    </div>

    <a
      id="link-route-landing"
      :href="dataLanding.next_route"
    ></a>

    <NotiView
      v-if="notiView"
      @closePopup='closePopup'
      :logo='logo'
      :title='title'
    />
  </div>
</template>

<script>
import NotiView from '@/components/NotiView/NotiView.vue'
import VueCookies from 'vue-cookies'

import axios from 'axios'
import { HTTP } from '@/http-default'
export default {
    components : {
        NotiView
    },

    data () {
        return {
            dataLanding : {},
            notiView : false,
            title : '',
            logo : '',
            dataInput : {
                name : '',
                phone : '',
                email : '',
            }
        }
    },

    methods : {
        closePopup () {
            this.notiView = false
        },

        handleRegisterCustomer () {
            var data = this.dataInput
            var ques = []
            if (this.dataLanding.question) {
                
                this.dataLanding.question.forEach(element => {
                    let d = document.getElementById(element)
                    console.log(d);
                    if (d !== null) {
                        console.log(d.value);
                        let obj = {
                            "key": element,
                            "value": d.value
                        }
                        ques.push(obj)

                    }
                });
                
            }
            data['question'] = JSON.stringify(ques)
            data['user_created'] = 2
            data['landing'] = this.dataLanding.id
            console.log(data);
            const axiosConfig = {
                headers : {'Content-Type': 'application/json'} 
            }
            axios.post('https://api.drmom.store/v1/api/answeruser/', JSON.stringify(data), axiosConfig)
            .then((res) => {
                console.log(res);
                var id = document.getElementById('link-route-landing')
                id.click()
            })
            // var dataUser = JSON.stringify({
            //     "username": this.dataInput.phone,
            //     "password": 'drmom',
            // });

            

            // axios.post('https://api.drmom.store/register/', dataUser, axiosConfig)
            // .then((res) => {
                
            //     this.dataInput['user'] = res.data.id
            //     HTTP.post('v1/api/customer/', this.dataInput)
            //     .then(() => {
            //         var id = document.getElementById('link-route-landing')
            //         id.click()
            //         this.notiView = true
            //         this.title = 'Đăng kí thành công'
            //         this.logo = 'noti-success'
            //     })
            // })
            // .catch(() => {
            //     this.notiView = true
            //     this.title = 'Số điện thoại đã tồn tại'
            //     this.logo = 'noti-warning'
            // })

        },

        makeUrlAllfiliate () {
            var user = localStorage.getItem('myAccount')
            user = JSON.parse(user)
            if (user && user.code_affiliate !== null) {
                this.$router.push({query : {'key' : user.code_affiliate} })
            } else {
                if (this.$route.query.key && !this.$cookies.get('code_affiliate')) {
                    HTTP.get('affiliate/' + this.$route.query.key + '/c/1/')
                }
                console.log(this.$cookies.get('code_affiliate'));

                VueCookies.set('code_affiliate' , this.$route.query.key) 
            }
        },

        getData () {
            var config = {
                method: 'get',
                url: 'https://api.drmom.store/v1/api/landing/?depth=1&&key_landing=' + this.$route.params.id,
                headers: { }
            };
            axios(config)
            .then((res) => {
                // var data = res.data.results
                // var landing = data.filter(item => item.key_landing === this.$route.params.id)
                this.dataLanding = res.data.results[0]
            })
        }
    },

    created () {
        this.getData()
        this.makeUrlAllfiliate()
    }
}
</script>

<style scoped lang='scss'>
@import 'LandingWebsite.scss';
</style>