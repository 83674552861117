<template>
  <div id="app" class="layout-landing h100vh relative">
    <LandingPage v-if="$route.params.path === 'website'"/>
    
    <ForumsView 
      v-else-if="$route.params.path === 'forums'"/>

    <ProductWebSite 
      v-else-if="$route.params.path === 'product'"/>

    <WebPostView 
      v-else-if="$route.params.path === 'post-website'"/>

    <MyAccount 
      v-else-if="$route.params.path === 'myAccount' || $route.params.path === 'myOrder' || $route.params.path === 'myAffiliate'" />

    <LandingWebsite 
        v-else-if="$route.params.path === 'landing'"/>

    <router-view v-else/>

    <div id="fb-root">
      <!-- Your Plugin chat code -->
      <div class="fb-customerchat"
          attribution="install_email"
          attribution_version="biz_inbox"
          page_id="101824012586668">
      </div>
    </div>
  </div>
</template>
 
<script>
import LandingPage from '@/components/Landingpage/LandingPage'
import ForumsView from '@/views/ForumsView/ForumsView.vue'
import ProductWebSite from '@/views/ProductWebSite/ProductWebSite.vue'
import MyAccount from '@/components/MyAccount/MyAccount.vue'
import LandingWebsite from '@/components/LandingWebsite/LandingWebsite.vue'
import WebPostView from '@/components/WebPostview/WebPostView.vue'

import bcrypt from 'bcryptjs';

export default {
  components : {
    LandingPage,
    ForumsView,
    ProductWebSite,
    MyAccount,
    LandingWebsite,
    WebPostView
  },

  watch : {
    checkRoleUser : function () {
      console.log(123);
    }
  },

  computed : {
    checkRoleUser () {
      var role = localStorage.getItem('role')
      if (bcrypt.compare('admin', role)) {
        return 'admin'
      } else if (bcrypt.compare('customer', role)) {
        return 'customer'
      } else {
        return 'another'
      }
    }
  },

  methods : {
    async compareRole () {
      var role = localStorage.getItem('role')
      bcrypt.compare('admin', role, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          console.log(res);
        }
      })
    },


  },

  created () {
    this.compareRole()
  }
}
</script>

<style lang="scss"> 
@import '/global/index';
.image__product {
  width: 48px;
  height: 48px;
}

.popup {
  z-index: 1000000;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: $c-brown;
}

.upcase::first-letter {
  text-transform: capitalize;
}

.img-logo {
  width: 64px;
  height: 64px;
  border: 1px solid $c-border;
  border-radius: 0.5em ;
}

.layout-landing {
  overflow: auto;
}
</style>
