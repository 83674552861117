<template>
  <div :class="[$attrs.isMobile ? 'container-mobile px1' : 'container w85 pt2 mb2']">
    <div v-if="$route.params.path != 'quan-ly-funnel'">
      <div
        v-if="dataAdd['props'] && !$route.params.id"
        class="f aic  mb2"
      >
        <div class="pr1 h2 border-right"> {{ dataAdd['props'].titlecontent }} </div>
        <div class="p5 pr05 pl1 gray"> {{ dataAdd['props'].titlecontent }} </div>
        <div class="f aic">
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            class="mr05"
          >
            <use
              xlink="http://www.w3.org/2000/svg"
              href="#next"
              x="0"
              y="0"
            ></use>
          </svg>
          <div class="h5 brown pl05">Thêm mới {{ dataAdd['props'].titlecontent }}</div>
        </div>
      </div>

      <!-- thêm mới đơn hàng -->
      <div
        class="order-add__container f"
        v-if="$route.params.path === 'quan-ly-order'"
      >

        <!-- left -->
        <div class="order-add__container-left">

          <!-- customer -->
          <div class="order-left__customer w100 p1 mb1 border-radius bg-white-1">
            <div class="left-customer__title mb1">
              <div class="p5 brown">
                Thông tin người mua
              </div>
            </div>

            <!-- form input customer -->
            <div class="left-customer__form">
              <div class="mb05">
                <fieldset
                  id="customer_type"
                  class="mb05 mt05"
                >
                  <div class="f aic mb05 ">
                    <input
                      @click="addCustomerSystem = true"
                      type="radio"
                      name="customer_type"
                      :checked='addCustomerSystem'
                      value="true"
                    >
                    <label class="ml05 h7">Chọn từ danh sách trên hệ thống</label>

                  </div>
                  <div class="f aic">
                    <input
                      :checked='!addCustomerSystem'
                      type="radio"
                      name="customer_type"
                    >
                    <label class="ml05 h7">Khách hàng mới</label>
                  </div>
                </fieldset>
              </div>

              <div v-if="!addCustomerSystem">
                <div class="f fdc mb1">
                  <label class="h7">Tên khách hàng <span class="red">*</span></label>
                  <input
                    class="h6 mt025"
                    placeholder="Nhập họ và tên"
                    type="text"
                    v-model="customerOrder.full_name"
                  >
                </div>
                <div class="f aic mb1">
                  <div class="f fdc w50">
                    <label class="h7">Số điện thoại <span class="red">*</span></label>
                    <input
                      class="h6 mt025"
                      placeholder="Nhập số điện thoại"
                      type="text"
                      v-model="customerOrder.phone"
                    >
                  </div>
                  <div class="f fdc w50 ml05">
                    <label class="h7">Email</label>
                    <input
                      class="h6 mt025"
                      placeholder="Nhập email"
                      type="text"
                      v-model="customerOrder.email"
                    >
                  </div>
                </div>
                <div class="f fdc mb1">
                  <label class="h7">Địa chỉ nhận hàng</label>
                  <input
                    class="h6 mt025"
                    placeholder="Nhập  địa chỉ"
                    type="text"
                    v-model="customerOrder.address"
                  >
                </div>
                <div class="f fdc mb1">
                  <label class="h7">Ghi chú</label>
                  <textarea
                    class="h6 border-radius-s mt025 pt075 pb075 pl075 pr075"
                    placeholder="Thêm nội dung ghi chú về người mua"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    v-model="customerOrder.note_admin"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <!-- payment type -->
          <div class="order-left__payment p1 mb1 border-radius bg-white-1">
            <div class="left-payment__title">
              <div class="p5 brown">
                Phương thức thanh toán
              </div>
              <fieldset
                id="group1"
                class="mb05 mt05"
              >
                <div class="f aic mb05 ">
                  <input
                    type="radio"
                    name="group1"
                    id="infinity"
                    value="true"
                    checked
                  >
                  <label class="ml05 h7">Thanh toán COD</label>

                </div>
                <div class="f aic mb05">
                  <input
                    id="infinity"
                    type="radio"
                    name="group1"
                  >
                  <label class="ml05 h7 gray">Chuyển khoản</label>
                </div>
                <div class="f aic">
                  <input
                    id="infinity"
                    type="radio"
                    name="group1"
                  >
                  <label class="ml05 h7 gray">Qua ngân hàng</label>
                </div>
              </fieldset>
            </div>
          </div>

          <!-- setting cart -->
          <div class="order-left__cart p1 border-radius bg-white-1">
            <div class="mb05">
              <div class="p5 brown">
                Thiết lập giỏ hàng
              </div>
            </div>
            <div class="mb1">
              <div class="h6 gray">
                Lựa chọn sản phẩm cho người mua
              </div>
            </div>
            <div class="left__list-header-product w100 p5 pt1 pb05 ">
              <div
                class="f aic jcb"
                style="width: 5%"
              >
                <input
                  @click="handleSelectAll"
                  class="form-add-folder__input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                >
              </div>
              <div class="align-c p5 gray">
                Sản phẩm
              </div>
              <div class="align-c p5 gray">
                Giá sản phẩm
              </div>
              <div class="align-c p5 gray">
                Giá khuyến mãi
              </div>
              <div class="align-c p5 gray">
                Giá bán
              </div>
            </div>
            <div class="left__list-body-product">
              <div
                v-for="(item, key) in item"
                :key="key"
                class="body__item-list w100 f aic jcb p5 pt1 pb1 border-bottom bg-white-1"
              >
                <div
                  class=" f aic jcb"
                  style="width: 5%"
                >
                  <input
                    @click="handleSelect"
                    class="form-add-folder__input"
                    type="checkbox"
                    :value="item.id"
                  >
                </div>
                <div class="align-s f aic p4 blue">
                  <img
                    class="image__product"
                    :src="item.media[0].file_media"
                    alt=""
                  >
                  <div class="ml1">
                    <div class="mb025 p4 blue"> {{item.title_product}} </div>
                    <div class="h6 gray"> Tồn kho: {{item.inventory}} {{item.unit}} </div>
                  </div>
                </div>
                <div
                  v-if="item.price_product"
                  class="align-c h6"
                >
                  {{formatMoney(item.price_product)}}
                </div>
                <div
                  v-if="item.price_sale"
                  class="align-c h6"
                >
                  {{formatMoney(item.price_sale)}}
                </div>
                <div
                  v-if="item.price_product"
                  class="align-c p4 brown"
                >
                  {{formatMoney(item.price_product)}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- right -->
        <div
          class="order-add__container-right ml2 p1 border-radius bg-white-1"
          style="height: fit-content"
        >
          <div class="order-right__title">
            <div class="p5">
              Sản phẩm đã chọn
            </div>
          </div>
          <div
            v-if="itemSelect.length !== 0"
            class="order-right__product-select pt1 pb1 border-bottom"
          >
            <div
              class="order-right__item mt1 border-radius bg-gray-2"
              v-for="(item, index) in itemSelect"
              :key="index"
            >
              <div class="item-select__info pt1 pb1 pl1 pr1 border-radius mb1 bg-gray-2">
                <div class="f jcb aic mb1">
                  <div class="f aic w80">
                    <img
                      class="image__product mr1"
                      :src="item.media[0].file_media"
                      alt=""
                    >
                    <div class="f fdc jcb">
                      <div class="blue p4 mb025">{{item.title_product}}</div>
                      <div class="h6">Tồn kho: {{item.inventory}} {{item.unit}}</div>
                    </div>
                  </div>
                  <div class="f jce aic">
                    <div
                      style="width : 100px"
                      class="change-quantity f jcc aic pt05 pb05 pl1 pr1 border border-radius bg-white-1"
                    >
                      <!-- <div 
                                                    @click="handleMinus(item)"
                                                    class="change-quantity__minus h5 cursor">-</div> -->
                      <input
                        v-model="item.quantity"
                        type="number"
                        class="w100 input-quantity"
                      >
                      <!-- <div 
                                                    @click="handlePlus(item)"
                                                    class="change-quantity__plus h5 cursor">+</div> -->
                    </div>
                    <!-- <div style="width: 0px" class="icon icon--close ml1"></div> -->
                  </div>
                </div>
                <div>
                  <div
                    v-if="item.price_product"
                    class="f jcb aic"
                  >
                    <div class="h5 gray">Giá sản phẩm</div>
                    <div class="align-c p4">{{formatMoney(item.price_product)}}</div>
                  </div>
                  <div
                    v-if="item.price_product"
                    class="f jcb aic"
                  >
                    <div class="h5 gray">Giá khuyến mãi</div>
                    <div class="align-c p4">{{formatMoney(item.price_sale)}}</div>
                  </div>
                  <div
                    v-if="item.price_product"
                    class="f jcb aic"
                  >
                    <div class="h5 gray">Giá bán</div>
                    <div class="align-c p4 brown">{{formatMoney(item.price_sale)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="order-right__price pt1 pb1 mb1 border-bottom">
            <div class="f jcb aic mb05">
              <div class="h6">Tổng tiền hàng</div>
              <div class="p4">{{totalPriceProduct}}</div>
            </div>
            <div class="f jcb aic mb05">
              <div class="h6">Giảm giá </div>
              <div class="p4">0VND</div>
            </div>
            <div class="f jcb aic mb05">
              <div class="h6">Phí vận chuyển</div>
              <div class="p4">35.000VND</div>
            </div>
          </div>

          <div class="order-right__price f jcb aic mb15">
            <div class="h7">Tổng tiền phải trả</div>
            <div class="h3 brown">{{formatMoney(totalPriceOrder)}}</div>
          </div>

          <div class="order-right__action f aic">
            <div class="w50 pt075 pb075 pl1 pr1 border-radius align-c white bg-black cursor">Hủy bỏ</div>
            <div
              v-if="itemSelect.length == 0"
              class="w50 pt075 pb075 pl1 pr1 ml1 border-radius align-c white bg-brown-1"
            >Tạo đơn</div>
            <div
              v-else
              @click="handleAdd"
              class="w50 pt075 pb075 pl1 pr1 ml1 border-radius align-c white bg-brown cursor"
            >Tạo đơn</div>
          </div>
        </div>
      </div>

      <!-- Thêm mới bài viết -->
      <AddNewPost
        v-else-if="dataAdd['props'] && $route.params.path === 'quan-ly-post'"
        :dataInput='dataInput'
        :isMobile="$attrs.isMobile"
      />

      <!-- thêm mới -->
      <div
        v-else-if="dataAdd['props'] && $route.params.path !== 'quan-ly-order'"
        class="f jcc aic"
      >
        <div class="content jcc pt1 pb1 pr1 pl1 border-radius bg-white-1">
          <div
            v-if="!$route.params.id"
            class="container__header mb1"
          >
            <div class="h4 brown">Thêm mới {{ dataAdd['props'].titlecontent }}</div>
          </div>
          <div
            v-if="$route.params.id"
            class="container__header mb1"
          >
            <div class="h4 brown">Chi tiết {{ dataAdd['props'].titlecontent }}</div>
          </div>
          <form class="form f fdc w100">

            <!-- thêm mới customer -->
            <div v-if="$route.params.path == 'quan-ly-customer'">
              <div class="info-basic w100 mb1">
                <div class="form__label h4 mb1">Thông tin cơ bản</div>
                <div class="f fdc ais mb1">
                  <label
                    class="h7 mb025"
                    for=""
                  >Tên {{dataAdd['props'].titlecontent }}</label>
                  <input
                    v-if="!dataErr.full_name"
                    class="w100 pt075 pb075 pl075 pr075"
                    type="text"
                    placeholder="Nhập vào đây"
                    v-model="dataInput.full_name"
                  >
                  <div
                    class="w100"
                    v-else-if="dataErr.full_name"
                  >
                    <input
                      class="w100 border-error pt075 pb075 pl075 pr075"
                      type="text"
                      placeholder="Nhập vào đây"
                      v-model="dataInput.full_name"
                    >
                    <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                  </div>
                </div>
                <div class="medical__img mb1">
                  <div class="h7">Ảnh {{dataAdd['props'].titlecontent }}</div>
                  <div
                    class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3"
                    style="border: 1px dashed #D9DDE8;"
                  >
                    <div
                      @click="uploadImg"
                      class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="file"
                        @change="uploadImage($event)"
                        id="file-input"
                      >
                      <i>Tải ảnh lên</i>
                    </div>
                    <div>
                      <div class="p4">Thêm ảnh đại diện của bạn</div>
                      <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                    </div>
                  </div>
                  <div
                    id="list_avatar-relate"
                    class="list__avatar f aic mt05"
                  >
                    <div
                      class="avatar-upload__item relative"
                      v-for="(item, index) in avatar"
                      :key="index"
                    >
                      <div
                        @click="handleDeleteImg(item)"
                        class="avatar-upload__delete absolute jcc aic white cursor"
                      >x</div>
                      <img
                        class="avatar-upload__img border-radius mr05"
                        :src="item"
                        alt=""
                      >
                    </div>
                  </div>
                </div>
                <div class="f jcb ais">
                  <div class="f fdc w50">
                    <label
                      class="h7 mb025"
                      for=""
                    >Email khách hàng</label>
                    <input
                      v-if="!dataErr.email"
                      class="pt075 pb075 pr075 pl075"
                      type="text"
                      placeholder="Nhập vào đây"
                      v-model="dataInput.email"
                    >
                    <div v-if="dataErr.email === true">
                      <input
                        class="w100 mt025 border-error pt075 pb075 pl075 pr075"
                        type="email"
                        pattern=".+@gmail\.com"
                        placeholder="Nhập vào đây"
                        v-model="dataInput.email"
                      >
                      <div class="h7 mt025 red">Bạn cần email để tạo mới người dùng!</div>
                    </div>
                  </div>
                  <div class="f fdc w50 ml1">
                    <label
                      class="h7 mb025"
                      for=""
                    >Số điện thoại khách hàng</label>
                    <input
                      v-if="!dataErr.phone"
                      class="pt075 pb075 pr075 pl075"
                      type="text"
                      placeholder="Nhập vào đây"
                      v-model="dataInput.phone"
                    >
                    <div
                      class="w100"
                      v-else-if="dataErr.phone === true"
                    >
                      <input
                        class="w100 mt025 border-error pt075 pb075 pl075 pr075"
                        type="text"
                        placeholder="Nhập vào đây"
                        v-model="dataInput.phone"
                      >
                      <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                    </div>
                    <div
                      class="w100"
                      v-else-if="dataErr.phone === 'error format'"
                    >
                      <input
                        class="w100 mt025 border-error pt075 pb075 pl075 pr075"
                        type="text"
                        placeholder="Nhập vào đây"
                        v-model="dataInput.phone"
                      >
                      <div class="h7 mt025 red">Bạn cần điền đúng định dạng số điện thoại</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="info-address mb1">
                <div class="h5 mb1">Địa chỉ khách hàng</div>
                <div class="f fdc aic mb1">
                  <div class="w100 f jcb aic mb05">
                    <label
                      class="h7"
                      for=""
                    >Địa chỉ </label>
                    <!-- <div class="f aic">
                                            <div class="h7 mr05">Mặc định</div>
                                            <input type="checkbox">
                                        </div> -->
                  </div>
                  <input
                    class="w100 pt075 pb075 pr075 pl075"
                    type="text"
                    placeholder="Nhập địa chỉ"
                    v-model="dataInput.address"
                  >
                </div>
                <!-- <div class="f fdc aic">
                                    <div class="f jcb aic w100 mb05">
                                        <label class="h7" for="">Địa chỉ 2</label>
                                        <div class="h7 blue cursor">Đặt địa chỉ này làm mặc định</div>
                                    </div>
                                    <input 
                                        class="w100 pt075 pb075 pr075 pl075" 
                                        type="text" 
                                        placeholder="Nhập địa chỉ">
                                </div> -->
              </div>
              <div class="info-social mb1">
                <div class="h5 mb1">Mạng xã hội</div>
                <div class="f jcb aic mb1">
                  <img
                    class="mr1"
                    src="../../assets/AddCustomerPNG/facebook.png"
                    alt=""
                  >
                  <input
                    class="w90"
                    type="text"
                    placeholder="Nhập địa chỉ Facebook của khách hàng"
                    v-model="linkFb"
                  >
                </div>
                <div class="f jcb aic">
                  <img
                    class="mr1"
                    src="../../assets/AddCustomerPNG/zalo.png"
                    alt=""
                  >
                  <input
                    class="w90"
                    type="text"
                    placeholder="Nhập địa chỉ Facebook của khách hàng"
                    v-model="linkZalo"
                  >
                </div>
              </div>
              <div class="info-note f fdc mb1">
                <div class="h7 mb05">Ghi chú</div>
                <textarea
                  class="border h6 pt075 pb075 pr075 pl075 border-radius"
                  cols="30"
                  rows="10"
                  placeholder="Nhập ghi chú"
                  v-model="dataInput.note_admin"
                ></textarea>
              </div>
              <div class="info-related mb1">
                <div class="info-related__header w100 f jcb aic mb1">
                  <div class="h5">Thông tin người liên quan</div>
                  <div
                    v-if="dataCustomerRelate.length < 4"
                    class="f aic"
                  >
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      class="mr05 blue"
                    >
                      <use
                        xlink="http://www.w3.org/2000/svg"
                        href="#more"
                        x="0"
                        y="0"
                      ></use>
                    </svg>
                    <div
                      @click="popupCustomerRelate = true"
                      class="h5 blue cursor"
                    >Thêm người liên quan</div>
                  </div>
                  <div
                    v-else
                    class="relative f aic"
                  >
                    <div class="icon--info cursor">
                      <div class="customer-relate__number pt05 pb05 pl075 pr075 border-radius bg-brown">
                        <div class="p7 white">
                          Bạn chỉ có thể nhập tối đa 4 người liên quan
                        </div>
                      </div>
                    </div>
                    <div class="ml05 h5 gray">Thêm người liên quan</div>
                  </div>
                </div>
                <div class="info-related__list mb1">
                  <div
                    v-for="(item, index) in dataCustomerRelate"
                    :key="index"
                    class="info-related__item f jcb aic"
                  >
                    <div v-if="item.user">
                      <div class="mb025">
                        <span
                          v-if="$route.params.menu !== 'editInfo'"
                          class="p4 blue mr05"
                        > {{item.full_name}} </span>
                        <span
                          v-if="$route.params.menu === 'editInfo'"
                          class="p4 blue mr05"
                        > {{item.name}} </span>
                        <span class="p4 gray">(Bố/con)</span>
                      </div>
                      <div class="h6 gray">
                        {{item.phone}}
                      </div>
                    </div>
                    <div v-else>
                      <div class="mb025">
                        <span
                          v-if="$route.params.menu !== 'editInfo'"
                          class="p4 orange mr05"
                        > {{item.full_name}} </span>
                        <span
                          v-if="$route.params.menu === 'editInfo'"
                          class="p4 orange mr05"
                        > {{item.name}} </span>
                        <span class="p4 gray">(Bố/con)</span>
                      </div>
                      <div class="h6 gray">
                        {{item.phone}}
                      </div>
                    </div>
                    <div class="f aic">
                      <div
                        @click="handleEditCustomerRelate(item)"
                        class="icon--edit cursor"
                      ></div>
                      <div
                        @click="handleDeleteCustomerRelate(item)"
                        class="icon--delete-black ml1 cursor"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!dataEdit.id"
                  class="info-medical"
                >
                  <div class="h5 mb1">Hồ sơ bệnh lý</div>
                  <div class="medical__img mb1">
                    <div class="h7">Ảnh bệnh lý</div>
                    <div
                      class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-2"
                      style="border: 1px dashed #D9DDE8;"
                    >
                      <div
                        class="h7 pt05 pb05 pr1 pl1 mr1 border-radius"
                        style="border: 1px solid #D9DDE8;"
                      >
                        Tải ảnh lên
                      </div>
                      <div>
                        <div class="p4">Bạn có thể thêm nhiều ảnh cùng lúc (tối đa: 10 ảnh)</div>
                        <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                      </div>
                    </div>
                  </div>
                  <div class="medical__text w100 f fdc ais">
                    <div class="h7 mb05">Bổ sung hồ sơ</div>
                    <textarea
                      class="border border-radius w100 h7 pt075 pb 075 pr075 pl075"
                      cols="30"
                      rows="10"
                      placeholder="Giúp bạn chăm sóc khách hàng tốt hơn"
                      v-model="dataInput.note_add"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <!-- thêm mới quà tặng -->
            <div v-if="$route.params.path == 'quan-ly-gift'">
              <div class="info-basic w100 mb1">
                <div class="f fdc ais mb1">
                  <label
                    class="h7 mb025"
                    for=""
                  >Tên {{dataAdd['props'].titlecontent }} <span class="red">*</span> </label>
                  <input
                    class="w100 pt075 pb075 pr075 pl075"
                    type="text"
                    placeholder="Nhập vào đây"
                    v-model="dataInput.title_gift"
                  >
                </div>

                <!-- Loại quà tặng -->
                <div class="gift-type mb1">
                  <div
                    v-if="dataAdd['props']"
                    class="gift-type__header h5 mb1 brown"
                  > Loại quà <span class="red">*</span>
                  </div>
                  <div class="gift-type__body w100">
                    <div class="gift-type__body-product mb1">
                      <div class="gift-type__label f jcb aic mb1">
                        <div class="h7">Thêm sản phẩm</div>
                        <div class="f aic cursor">
                          <div>
                            <svg
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              class="mr05 blue"
                            >
                              <use
                                xlink="http://www.w3.org/2000/svg"
                                href="#more"
                                x="0"
                                y="0"
                              ></use>
                            </svg>
                          </div>
                          <div
                            @click="handleAddProduct"
                            class="h7 ml05 blue"
                          >Thêm sản phẩm</div>
                        </div>
                      </div>
                      <div class="gift-type__list-product">
                        <div
                          v-for="(item, index) in productGift"
                          :key="index"
                          class="gift-type__item-product f jcb aic pt05 pb05 pl1 pr1 mb1 border-radius bg-gray-2"
                        >
                          <div class="f aic">
                            <img
								class="product-img"
                              :src="item.media[0].file_media"
                              alt=""
                            >
                            <div class="ml05">
                              <div class="p4 blue mb025"> {{item.title_product}} </div>
                              <!-- <div class="h5 gray"> {{item.type_product.title_type_product}} </div> -->
                            </div>
                          </div>
                          <div class="p4 gray">
                            x{{item.quantity}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="gift-type__body-discount f fdc ais mb1">
                      <label class="h7 mb025"> Giảm giá tổng đơn </label>
                      <input
                        class="w100 pt075 pb075 pr075 pl075"
                        type="number"
                        placeholder="Nhập vào đây"
                        v-model="dataInput.discount_order"
                      >
                    </div>
                  </div>
                </div>

                <!-- cài đặt quà tặng -->
                <div class="gift-setting mb1">
                  <div
                    v-if="dataAdd['props']"
                    class="gift-setting__header h5 mb1 brown"
                  > Cài đặt quà tặng <span class="red">*</span>
                  </div>
                  <div class="gift-setting__body w100">
                    <div class="gift-setting__body-quantity mb1">
                      <label class="h7"> Số lượng quà tặng </label>
                      <fieldset
                        id="group1"
                        class="mb05 mt05 f aic"
                      >
                        <div class="f aic mr1">
                          <input
                            @click="quantityGift = false"
                            type="radio"
                            name="group1"
                            id="infinity"
                            value="true"
                            checked
                          >
                          <label class="ml05 h6">Không giới hạn</label>

                        </div>
                        <div class="f aic">
                          <input
                            @click="quantityGift = true"
                            type="radio"
                            name="group1"
                          >
                          <label class="ml05 h6">Tùy chỉnh hạn sử dụng</label>
                        </div>
                      </fieldset>
                      <input
                        v-if="quantityGift"
                        class="w100 pt075 pb075 pr075 pl075"
                        type="number"
                        placeholder="Nhập vào đây"
                        v-model="dataInput.quantity_gift"
                      >
                    </div>

                    <div class="gift-setting__body-date">
                      <label class="h7"> Hạn sử dụng quà tặng </label>
                      <fieldset
                        id="group2"
                        class="mb05 mt05 f aic"
                      >
                        <div class="f aic mr1">
                          <input
                            @click="expiryDateGift = false"
                            type="radio"
                            name="group2"
                            id="infinity"
                            value="true"
                            checked
                          >
                          <label class="ml05 h6">Không giới hạn</label>

                        </div>
                        <div class="f aic">
                          <input
                            @click="expiryDateGift = true"
                            type="radio"
                            name="group2"
                          >
                          <label class="ml05 h6">Tùy chỉnh hạn sử dụng</label>
                        </div>
                      </fieldset>
                      <div
                        v-if="expiryDateGift"
                        class="f jcb aic"
                      >
                        <div class="w50 mr1">
                          <label class="h7">Thời gian bắt đầu</label>
                          <input
                            class="w100 mt05"
                            type="datetime-local"
                            v-model="dataInput.time_start"
                          >
                        </div>
                        <div class="w50">
                          <label class="h7">Thời gian kết thúc</label>
                          <input
                            class="w100 mt05"
                            type="datetime-local"
                            v-model="dataInput.time_end"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="gift-setting__total f jcb aic pt1 pb1 border-top">
                <div class="h7">Tổng giá trị quà tặng</div>
                <div class="h3 brown">{{ formatMoney(totalPriceGift) }}VNĐ</div>
              </div>

              <div class="gift-require pt1 pb1">
                <div class="p5 brown mb1">Điều kiện nhận quà tặng* </div>
                <div
                  @click="stausRequireGift = true"
                  class="w100 f jcc aic pt05 pb05 border-radius border cursor"
                >
                  <div class="icon--edit"></div>
                  <div class="h7 ml05">Thêm nhóm điều kiện mới</div>
                </div>
                <div class="f fdc mt05">
                  <div
                    v-for="(item, index) in conditionList"
                    :key="index"
                    class="f jcb aic p1 mb025 border-radius bg-brown-1"
                  >
                    <div class="h7"> {{item.title_require}} </div>
                    <div
                      @click="handleDeleteCondition(index)"
                      class="icon--bin cursor"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- thêm mới sản phẩm -->
            <div v-if="$route.params.path == 'quan-ly-product'">
              <div class="info-basic w100 mb1">

                <!-- Thông tin cơ bản -->
                <div class="product__info-basic mb1 pb1 border-bottom">
                  <div class="h5 mb1 brown">
                    Thông tin cơ bản
                  </div>
                  <div
                    v-for="(item, key) in dataListAddField"
                    :key="key"
                  >
                    <div
                      class="mb1"
                      v-if="key === 'title_product'
                                                || key === 'category'
                                                || key === 'short_description'"
                    >
                      <label class="h7"> {{item.text_lable}} </label>
                      <div
                        class="mt025"
                        v-if="item.type_input === 'select'"
                      >
                        <select
                          class="w100 h6"
                          v-model="dataInput[key]"
                          multiple
                        >
                          <option
                            value=""
                            selected
                            disabled
                            hidden
                          >Lựa chọn {{item.text_lable}} </option>
                          <option
                            v-for="(value, index) in dataSelect[key]"
                            :key="index"
                            :value="value.id"
                          >
                            {{value.category_name}}
                          </option>
                        </select>
                      </div>
                      <div
                        class="mt025"
                        v-else
                      >
                        <input
                          class="w100 pt075 pb075 pl075 pr075 h6"
                          v-model="dataInput[key]"
                          type="text"
                          :placeholder="item.text_lable"
                        >
                      </div>
                    </div>
                    <div
                      class="mb1"
                      v-if="key === 'content'"
                    >
                      <label class="h7"> Mô tả chi tiết </label>
                      <editor
                        api-key="4wl3mwjq0h6xnbsttg7khu6kcq0xy9vi5g6xsc52q90hglep"
                        v-model="dataInput[key]"
                        :init="{
                                                    height: 500,
                                                    menubar: false,
                                                    placeholder: 'Nhập mô tả chi tiết sản phẩm',
                                                    plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | help | link image tinydrive',
                                                    powerpaste_allow_local_images: true,
                                                    powerpaste_word_import: 'prompt',
                                                    powerpaste_html_import: 'prompt',
                                                }"
                      />
                      <!-- <textarea 
                                                class="border-radius mt025 w100 pt075 pb075 pl075 pr075 h6"
                                                v-model="dataInput[key]"
                                                type="text"
                                                placeholder="Nhập mô tả chi tiết sản phẩm"
                                                name="" id="" cols="30" rows="10"></textarea> -->
                    </div>
                  </div>
                  <div class="product__img mb1">
                    <div class="h7">Ảnh {{dataAdd['props'].titlecontent }}</div>
                    <div
                      class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3"
                      style="border: 1px dashed #D9DDE8;"
                    >
                      <div
                        @click="uploadImg"
                        class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          ref="file"
                          @change="uploadImage($event)"
                          id="file-input"
                        >
                        <i>Tải ảnh lên</i>
                      </div>
                      <div>
                        <div class="p4">Thêm ảnh đại diện của bạn</div>
                        <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                      </div>
                    </div>
                    <div
                      id="list_avatar-relate"
                      class="list__avatar f aic mt05"
                    >
                      <div
                        class="avatar-upload__item relative"
                        v-for="(item, index) in avatar"
                        :key="index"
                      >
                        <div
                          @click="handleDeleteImg(item)"
                          class="avatar-upload__delete absolute jcc aic white cursor"
                        >x</div>
                        <img
                          class="avatar-upload__img border border-radius mr05"
                          :src="item"
                          alt=""
                        >
                      </div>
                    </div>
                  </div>
                  <!-- <div class="product__img mb1">
                                        <div class="h7">Ảnh sản phẩm</div>
                                        <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3" style="border: 1px dashed #D9DDE8;">
                                            <div
                                                @click="uploadImg" 
                                                class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
                                                <input 
                                                    type="file" 
                                                    accept="image/*" 
                                                    ref="file"
                                                    @change="uploadImage($event)" 
                                                    id="file-input"
                                                    multiple>
                                                <i>Tải ảnh lên</i>
                                            </div>
                                            <div>
                                                <div class="p4">Thêm ảnh đại diện của bạn</div>
                                                <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                                            </div>
                                        </div>
                                    </div> -->
                  <!-- <div class="product__video mb1">
                                        <div class="h7">Video sản phẩm</div>
                                        <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3" style="border: 1px dashed #D9DDE8;">
                                            <div
                                                @click="uploadImg" 
                                                class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
                                                <input 
                                                    type="file" 
                                                    accept="image/*" 
                                                    ref="file"
                                                    @change="uploadImage($event)" 
                                                    id="file-input">
                                                <i>Tải video lên</i>
                                            </div>
                                            <div>
                                                <div class="p4">Bạn có thể thêm nhiều video cùng lúc (tối đa: ?)</div>
                                                <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                                            </div>
                                        </div>
                                    </div> -->
                </div>

                <!-- Thông tin chi tiết -->
                <div class="product__info-detail mb1 pb2 border-bottom">
                  <div class="h5 mb1 brown">
                    Thông tin chi tiết
                  </div>
                  <div class="f fdc">
                    <div class="f fdc mb1">
                      <label class="h7">Mã sản phẩm <span class="red">*</span></label>
                      <input
                        class="mt025 h6"
                        type="text"
                        placeholder="Nhập mã sản phẩm"
                      >
                    </div>
                    <div class="f fdc mb1">
                      <label class="h7">Trạng thái sản phẩm trong kho <span class="red">*</span></label>
                      <input
                        v-model="dataInput.inventory"
                        class="mt025 h6"
                        type="text"
                        placeholder="Nhập số lượng sản phẩm trong kho"
                      >
                    </div>
                    <div class="f jcb aic mb1">
                      <div class="f fdc w50">
                        <label
                          class="h7"
                          for=""
                        >Giá bán <span class="red">*</span></label>
                        <input
                          v-model="dataInput.price_product"
                          placeholder="Nhập giá bán"
                          type="text"
                        >
                      </div>
                      <div class="f fdc w50 ml1">
                        <label
                          class="h7"
                          for=""
                        >Giá khuyến mãi <span class="red">*</span></label>
                        <input
                          v-model="dataInput.price_sale"
                          placeholder="Nhập giá khuyến mãi"
                          type="text"
                        >
                      </div>
                    </div>
                    <div class="f fdc mb1">
                      <label class="h7"> Số tiền chiết khấu affiliate <span class="red">*</span></label>
                      <input
                        v-model="dataInput.discount_price"
                        class="mt025 h6"
                        type="text"
                        placeholder="Nhập số tiền chiết khấu"
                      >
                    </div>
                  </div>
                </div>

                <!-- Cài đặt vận chuyển -->
                <div class="product__setting-delivery mb1">
                  <div class="h5 mb1 brown">
                    Cài đặt vận chuyển
                  </div>
                  <div class="f fdc">
                    <div class="f fdc mb1">
                      <label class="h7">Loại sản phẩm <span class="red">*</span></label>
                      <input
                        class="mt025 h6"
                        type="text"
                        placeholder="Nhập mã sản phẩm"
                      >
                    </div>
                    <div class="f jcb aic mb1">
                      <div class="f fdc w50">
                        <label for="">Đơn vị tính</label>
                        <input
                          v-model="dataInput.unit"
                          placeholder="Nhập vào đây"
                          type="text"
                        >
                      </div>
                      <div class="f fdc w50 ml1">
                        <label for="">Khối lượng (gram) <span class="red">*</span></label>
                        <input
                          v-model="dataInput.weight"
                          placeholder="Nhập đơn vị tính"
                          type="text"
                        >
                      </div>
                    </div>
                    <div class="grid-item">
                      <div class="f fdc jcb">
                        <label for="">Chiều dài (cm) <span class="red">*</span></label>
                        <input
                          v-model="inputDimensions.length"
                          placeholder="Nhập đơn vị tính"
                          type="text"
                        >
                      </div>
                      <div class="f fdc jcb ml1">
                        <label for="">Chiều rộng (cm) <span class="red">*</span></label>
                        <input
                          v-model="inputDimensions.width"
                          placeholder="Nhập đơn vị tính"
                          type="text"
                        >
                      </div>
                      <div class="f fdc jcb ml1">
                        <label for="">Chiều cao (cm) <span class="red">*</span></label>
                        <input
                          v-model='inputDimensions.height'
                          placeholder="Nhập đơn vị tính"
                          type="text"
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <!-- xác nhận -->
                <div class="f jce">
                  <button
                    @click="$router.go(-1)"
                    class="pt075 pb075 pl1 pr1 mr1 border-radius brown"
                    style="border: 1px solid #6F382B"
                  >Hủy bỏ</button>
                  <button
                    class="pt075 pb075 pl1 pr1 border-radius white bg-brown"
                    @click="handleAdd"
                  >Thêm mới</button>
                </div>
              </div>
            </div>

            <div
              v-if="$route.params.path !== 'quan-ly-product'"
              class="f jce"
            >
              <button
                @click="($route.params.path === 'quan-ly-customer') ? $router.push({name : 'body-detail-menu', params : {menu : 'Hồ sơ bệnh lý'} }) : $router.go(-1)"
                class="pt075 pb075 pl1 pr1 mr1 border-radius brown"
                style="border: 1px solid #6F382B"
              >Hủy bỏ</button>
              <button
                v-if="$route.params.id"
                class="pt075 pb075 pl1 pr1 border-radius white bg-brown"
                @click="handleUpdate"
              >Cập nhật</button>

              <button
                v-else
                class="pt075 pb075 pl1 pr1 border-radius white bg-brown"
                @click="handleAdd"
              >Thêm mới</button>
            </div>
          </form>
        </div>
      </div>

      <div class="hide">
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="next"
            d="M6.90625 6.25L2.3125 10.875C2.125 11.0625 1.94792 11.0625 1.78125 10.875L1.15625 10.2812C0.989583 10.0938 0.989583 9.91667 1.15625 9.75L4.875 6L1.15625 2.25C0.989583 2.08333 0.989583 1.90625 1.15625 1.71875L1.78125 1.125C1.94792 0.9375 2.125 0.9375 2.3125 1.125L6.90625 5.75C7.07292 5.91667 7.07292 6.08333 6.90625 6.25Z"
            fill="#BABABA"
          />
        </svg>
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="bin"
            d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.3125 9.09375 0 8.5625 0H5.40625C4.875 0 4.375 0.3125 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.4375 1.5 5.5 1.5C5.5 1.5 5.5 1.5 5.53125 1.5H8.46875C8.53125 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z"
            fill="#1A1A1A"
          />
        </svg>
        <svg
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="note"
            d="M12.5625 11.7812C12.5 11.8438 12.5 11.9062 12.5 11.9688V15.5H1.5V4.5H9.03125C9.09375 4.5 9.15625 4.5 9.21875 4.4375L10.2188 3.4375C10.375 3.28125 10.25 3 10.0312 3H1.5C0.65625 3 0 3.6875 0 4.5V15.5C0 16.3438 0.65625 17 1.5 17H12.5C13.3125 17 14 16.3438 14 15.5V10.9688C14 10.75 13.7188 10.625 13.5625 10.7812L12.5625 11.7812ZM17.4375 5.5C18.1562 4.78125 18.1562 3.625 17.4375 2.90625L16.0938 1.5625C15.375 0.84375 14.2188 0.84375 13.5 1.5625L5.3125 9.75L5 12.5938C4.90625 13.4062 5.59375 14.0938 6.40625 14L9.25 13.6875L17.4375 5.5ZM14.375 6.4375L8.5625 12.25L6.5 12.5L6.75 10.4375L12.5625 4.625L14.375 6.4375ZM16.375 3.96875C16.5312 4.09375 16.5312 4.28125 16.4062 4.4375L15.4375 5.40625L13.625 3.5625L14.5625 2.625C14.6875 2.46875 14.9062 2.46875 15.0312 2.625L16.375 3.96875Z"
            fill="#1A1A1A"
          />
        </svg>
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="more"
            d="M10.5 7.3125C10.5 7.14844 10.3359 6.98438 10.1719 6.98438H7.76562V4.57812C7.76562 4.41406 7.60156 4.25 7.4375 4.25H6.5625C6.37109 4.25 6.23438 4.41406 6.23438 4.57812V6.98438H3.82812C3.63672 6.98438 3.5 7.14844 3.5 7.3125V8.1875C3.5 8.37891 3.63672 8.51562 3.82812 8.51562H6.23438V10.9219C6.23438 11.1133 6.37109 11.25 6.5625 11.25H7.4375C7.60156 11.25 7.76562 11.1133 7.76562 10.9219V8.51562H10.1719C10.3359 8.51562 10.5 8.37891 10.5 8.1875V7.3125ZM13.7812 7.75C13.7812 4.00391 10.7461 0.96875 7 0.96875C3.25391 0.96875 0.21875 4.00391 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7461 14.5312 13.7812 11.4961 13.7812 7.75ZM12.4688 7.75C12.4688 10.7852 10.0078 13.2188 7 13.2188C3.96484 13.2188 1.53125 10.7852 1.53125 7.75C1.53125 4.74219 3.96484 2.28125 7 2.28125C10.0078 2.28125 12.4688 4.74219 12.4688 7.75Z"
            fill="#2577C9"
          />
        </svg>
      </div>

      <AddProductGift @updateProductGift="updateProductGift" :isMobile="$attrs.isMobile">
      </AddProductGift>

      <RequireGift
        @updateCondition='updateCondition'
        @updateStatus="updateStatus"
        :status='stausRequireGift'
      ></RequireGift>

      <CustomerRelate
        v-if="popupCustomerRelate"
        :editCustomerRelate="editCustomerRelate"
        @closePopupRelate='closePopupRelate'
        @updateCustomerRelate='updateCustomerRelate'
      ></CustomerRelate>
    </div>

    <AddUser v-if="$route.params.path == 'quan-ly-user'" :isMobile="$attrs.isMobile" />

    <AddLanding
      v-if="$route.params.path == 'quan-ly-funnel'"
      :dataAdd='dataAdd'
    ></AddLanding>

    <AddCustomerOrder
      @updateCustomerSelect='updateCustomerSelect'
      @updateAddCustomerSystem='updateAddCustomerSystem'
      :addCustomerSystem='addCustomerSystem'
    ></AddCustomerOrder>

    <PopupConfirm
      v-if="statusPopupConfirm"
      :dataCustomerRelate='dataCustomerRelate'
      :customerRelateDelete='customerRelateDelete'
      @closePopupConfirm='closePopupConfirm'
      @updateCustomerRelate='updateCustomerRelate'
    />

    <NotiAddNew
      v-if="notiStatus"
      @closeNotiPopup='closeNotiPopup'
      :dataInput='dataInput'
      :dataCustomerRelate='dataCustomerRelate'
    />

    <NotiView
      v-if="notiView"
      @closePopup='closePopup'
      :title="title"
      :logo="logo"
    />
  </div>
</template>

<script>
import CustomerRelate from '@/components/AddNew/AddCustomerRelate/CustomerRelate.vue';
import AddProductGift from '@/components/AddNew/AddProductGift/AddProductGift.vue';
import RequireGift from '@/components/AddNew/PopupRequireGift/RequireGift.vue';
import AddLanding from '@/components/AddNew/AddLanding/AddLanding.vue';
import AddCustomerOrder from '@/components/AddNew/AddCustomerOrder/AddCustomerOrder.vue'
import PopupConfirm from './ConfirmDeleteCustomerRelate/PopupConfirm.vue'
import NotiAddNew from './NotiAddNew/NotiAddNew.vue'
import AddNewPost from './AddNewPost/AddNewPost.vue'
import AddUser from './AddUser/AddUser.vue'

import NotiView from '@/components/NotiView/NotiView.vue'

import Editor from '@tinymce/tinymce-vue'

import axios from 'axios'
import { HTTP } from '@/http-default'
export default {
    components : {
        CustomerRelate,
        AddProductGift,
        RequireGift,
        AddLanding,
        AddCustomerOrder,
        PopupConfirm,
        NotiAddNew,
        AddNewPost,
        AddUser,
        NotiView,

        'editor': Editor,
    },

    props: {
        data : {
            type : Array,
            default() {
                return []
            } 
        },

        dataField : {
            type : Object,
            default() {
                return {}
            } 
        },

        dataEdit : {
            type : Object,
            default() {
                return {}
            }
        }
    },

    data() {
        return {
            title : '',
            logo : '',
            notiView : false,

            inputDimensions : {
                length : null,
                height : null,
                width : null,
            },

            dataErr : {
                full_name: false,
                email: false,
                phone: false
            },
            dataAdd : [], 
            dataListAddField : [],

            popupCustomerRelate: false,
            dataCustomerRelate : [],
            dataNew: {},
            dataInput: {},

            // thông báo tạo mới khách hàng ,...
            notiStatus : false,

            // điều kiện hiển thị popup confirm
            statusPopupConfirm : false,

            // khách hàng liên quan bị xoá
            customerRelateDelete : {},

            // chỉnh sửa customer relate
            editCustomerRelate : {},

            // trạng thái popup
            stausRequireGift : false,
            
            // sản phẩm quà tặng
            productGift: [],
            // điều kiện quà tặng
            conditionList : [],
            quantityGift: false,
            expiryDateGift: false,

            // product list item
            item: [],
            itemSelect : [],
            customerOrder : {
                full_name: '',
                phone: '',
                email: '',
                address: '',
                note: '',
            },

            // thêm khách hàng từ hệ thống
            addCustomerSystem : false,

            // link fb vs zalo
            linkFb : '',
            linkZalo: '',

            dataSelect : {
                category: [],
                media: [],
                thumbnail: [],
            },
            avatar : [],
        }
    },

    computed : {
        // Tổng tiền đơn hàng
        totalPriceProduct () {
            var total = 0
            // const reducer = (total, item) => total + (parseInt(item.quantity) * item.price_sale)
            // const results = this.itemSelect.reduce(reducer, 0)
            if (this.itemSelect.length !== 0) {
                this.itemSelect.forEach(item => {
                    total += item.price_sale*item.quantity
                })
                return this.formatMoney(total)
            }

            return this.formatMoney(0)
        },

        totalPriceOrder () {
            var reducer = (total, item) => total + item.quantity*item.price_sale
            var results = this.itemSelect.reduce(reducer, 35000)
            return results
        },

		totalPriceGift() {
			return this.productGift.reduce((total, item) => total + (item?.price_product || 0), 0)
		}
    },

    methods: {

        closePopup () {
            this.notiView = false
        },

        updateAddCustomerSystem () {
            this.addCustomerSystem = false
        },

        updateCustomerSelect (value) {
            this.customerOrder = value
        },

        // format Money
        formatMoney(value) {
            var x = value;
            x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return x
        },

        // xu ly select input checkbox
        handleSelect(event) {
        var checkbox = event.target
            if (checkbox.checked == true) {
                for(let i = 0; i < this.item.length; i++) {
                    if(checkbox.value == this.item[i].id) {
                        this.itemSelect.push({...this.item[i]})
                    }
                }
            } 
            if (checkbox.checked == false) {
                for(let i = 0; i < this.itemSelect.length; i++) {
                    if(checkbox.value == this.itemSelect[i].id) {
                        this.itemSelect.splice(i, 1)
                    }
                }
            }
        },

        // xu ly select all
        handleSelectAll(event) {
            var checkbox = event.target
            var input = document.getElementsByClassName('form-add-folder__input')
            if (checkbox.checked == true) {
                for (let i = 0; i < input.length; i ++) {
                    input[i].checked = true
                }
                for(let i = 0; i < this.item.length; i++) {
                    if (!this.itemSelect.includes(this.item[i])) {
                        this.itemSelect.push({...this.item[i]})
                    } 
                }
            } 
            if (checkbox.checked == false) {
                for (let i = 0; i < input.length; i ++) {
                    input[i].checked = false
                    this.itemSelect = []
                }
            }
        },

        // xóa điều kiện nhận quà
        handleDeleteCondition(index) {
            this.conditionList.splice(index, 1)
        },

        // Xoá khách hàng liên quan 
        handleDeleteCustomerRelate (item) {
            this.statusPopupConfirm = true
            this.customerRelateDelete = item
        },
        
        closePopupConfirm () {
            this.statusPopupConfirm = false
        },

        // Chỉnh sửa khách hàng liên quan
        handleEditCustomerRelate (item) {
            this.editCustomerRelate = item
            this.popupCustomerRelate = true
        },

        updateProductGift(value) {
            this.productGift = value
        },

        // cập nhật condition quà tặng
        updateCondition (value) {
            this.conditionList.push(value)
        }, 
 
        // cập nhật trạng thái popup
        updateStatus(value) {
            this.stausRequireGift = value
        },

        // thêm điều kiện nhận quà
        handleGiftRequire () {
            var popup = document.getElementsByClassName('popup-gift-require')[0]
            popup.classList.toggle('f')
        },

        // thêm sản phẩm quà tặng
        handleAddProduct () {
            var popup = document.getElementsByClassName('pop-up-add-folder')[0]
            popup.classList.toggle('f')
        },

        // update  khách hàng liên quan
        updateCustomerRelate (value) { 
            if (value.length >= 0) {
                this.dataCustomerRelate = value
            }
            else {
                if (this.dataCustomerRelate.length === 0) {
                    this.dataCustomerRelate.push(value)
                } else {
                    var dataExist = false
                    for (let i = 0; i < this.dataCustomerRelate.length; i++) {
                        if (this.dataCustomerRelate[i].key_customer === value.key_customer) {
                            this.dataCustomerRelate[i] = value
                            dataExist = true
                        }
                    }

                    if (!dataExist) {
                        this.dataCustomerRelate.push(value)
                    }
                }
            }
        },

        uploadImg () {
            var input = document.getElementById('file-input')
            input.click()
        },

        uploadImage(event) {
            var url = URL.createObjectURL(event.target.files[0]);
            this.avatar.push(url)
            if (this.$route.params.path === 'quan-ly-customer' || this.$route.params.path === 'quan-ly-product') {
                this.dataInput.avatar.push(event.target.files[0])
            }
        },

        // Thông tin người liên quan
        closePopupRelate () {
            this.popupCustomerRelate = false
            this.editCustomerRelate = {}
        },

        // close Noti popup
        closeNotiPopup (isCreated) {
			if (isCreated) {
				this.title = 'Tạo thành công'
                this.logo = 'noti-success'
                this.notiView = true
			}
            this.notiStatus = false
        },

        getDataSelect () {
            for (let item in this.dataListAddField) {
                if (this.dataListAddField[item].type_input === 'select') {
                    HTTP.get(this.dataListAddField[item].api)
                    .then((res) => {
                        this.dataSelect[item] = res.data.results
                    })
                }
            }
        },

        async getData() {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].path == ('/' + this.$route.params.path)) {
                    this.dataAdd = this.data[i]
                    this.dataListAddField = this.dataAdd['props'].listfieldaddnew

                    this.getDataSelect()

                    // Chỉnh sửa thông tin khách hàng
                    if (this.$route.params.menu === 'editInfo' && this.$route.params.path === 'quan-ly-customer') {
                        this.dataInput = {...this.dataEdit}

                        this.linkFb = this.dataEdit.link_fb_zl[0].facebook
                        this.linkZalo = this.dataEdit.link_fb_zl[0].zalo

                        this.avatar.push(this.dataInput.avatar)
                        this.dataCustomerRelate = this.dataInput.people_relate

                        // var link = JSON.parse(this.dataInput.link_fb_zl)
                        // console.log(link);
                    }

                    else {
                        for (let item in this.dataListAddField) {
                            if (item === 'avatar') {
                                this.dataInput[item] = []
                            } else {
                                this.dataInput[item] = ''
                            }
                            // this.dataErr[item] = false
                        }
                    }
                    break
                }
            }

            if (this.$route.params.path === 'quan-ly-order') {
                HTTP.get('v1/api/product/?depth=1')
                .then((res) => {
                    this.item = res.data.results

                    this.item.forEach(item => {
                        item.quantity = 1
                    })
                })
            }
        },

        // lấy data chi tiết (quà tặng)
        getDataDetail() {
            if (this.$route.params.path === 'quan-ly-gift') {
                HTTP.get(this.dataField.props.urlapi + this.$route.params.id + '/?depth=1')
                .then((res) => {
                    this.dataInput = res.data
                    // this.dataInput.time_start = this.dataInput.time_start.splice(1, 8)
                    
                    this.productGift = this.dataInput.product_gift
                    this.dataInput.require_gift = this.dataInput.require_gift.replace(/\//g,'');
                    this.dataInput.require_gift = JSON.parse(this.dataInput.require_gift)
                    this.conditionList = this.dataInput.require_gift
                })
            }
        },

        handleUpdate (event) {
            const idUser = JSON.parse(localStorage.getItem('idUser'))
            event.preventDefault()
            delete this.dataInput['id']
            this.dataInput.product_gift = []

            this.productGift.forEach(item => {
                this.dataInput.product_gift.push(item.id)
            })

            this.dataInput['require_gift'] = JSON.stringify(this.conditionList)
            this.dataInput['user_created'] = idUser
            this.dataInput['type_number_gift'] = 1
            this.dataInput['type_time_gift'] = 1
            
            if (this.dataInput['avatar']) {
                delete this.dataInput['avatar']
            }

            delete this.dataInput['customer_care']

            const data = JSON.stringify(this.dataInput)

            delete data.customer_care

            if (this.$route.params.menu === 'editInfo') {
				console.log('editInfo');
                HTTP.put(this.dataField.props.urlapi + this.$route.params.id + '/?depth=1', data)
                .then(() => {
                    this.$router.push({name : 'body-detail-menu', params : {menu : 'Hồ sơ bệnh lý'}})
                })
            } else {
                HTTP.put(this.dataField.props.urlapi + this.$route.params.id + '/', data)
                .then(() => {
                    this.$router.go(-1)
                })
            }

        },

        validateInput () {
            // full_name
            if (this.dataInput.full_name === '') {
                this.dataErr.full_name = true
            } 
            
            // phone
            if (this.dataInput.phone === '') {
                this.dataErr.phone = true
            } else if (this.dataInput.phone.length !== 10 || this.dataInput.phone[0] !== '0') {
                this.dataErr.phone = "error format"
            }

            // email
            if (this.dataInput.email === '') {
                this.dataErr['email'] = true
            }
        },

        async handleAdd(event) {
            const idUser = JSON.parse(localStorage.getItem('idUser'))
            // var schema = Joi.object().keys(this.dataInput)
            // var { error } = s
            event.preventDefault()
            if (this.$route.params.path === 'quan-ly-customer') {

                for (let item in this.dataErr) {
                    this.dataErr[item] = false
                }

                this.validateInput()

                for (let item in this.dataErr) {
                    if (this.dataErr[item] === true || this.dataErr[item] === 'error format') {
                        return false
                    }
                }
                this.dataInput.link_fb_zl = []
                this.dataInput.link_fb_zl.push({
                    "facebook" : this.linkFb,
                    "zalo" : this.linkZalo,
                })

                this.notiStatus = true
            }

            // thêm mới gift
            else if (this.$route.params.path === 'quan-ly-gift') {
                this.dataInput['require_gift'] = JSON.stringify(this.conditionList)
                
                var idProduct = []
                this.productGift.forEach(item => {
                    idProduct.push(item.id)
                })
                this.dataInput['product_gift'] = idProduct
                this.dataInput['key_gift'] = Math.floor(Math.random() * 1000000000)
                this.dataInput['user_created'] = idUser
                this.dataInput['type_number_gift'] = 1
                this.dataInput['type_time_gift'] = 1
                this.dataInput['total_price_gift'] = 1000000
                this.dataInput['discount_order'] = 100000
                
                for (let i in this.dataInput) {
                    if (this.dataInput[i] === '') {
                        delete this.dataInput[i]
                    }
                }

                var data = JSON.stringify(this.dataInput)

                HTTP.post('v1/api/gift/', data)
                .then(() => {
                    this.$router.go(-1)
                })
            }

            // thêm mới order
            else if (this.$route.params.path === 'quan-ly-order') {

                var total_quantity = 0
                var total_price = 0

                this.itemSelect.forEach(i => {
                    total_quantity = parseInt(total_quantity) + parseInt(i.quantity)
                    total_price = total_price + (i.quantity*i.price_product)
                })

                const dataOrder = JSON.stringify({
                        "order_key": Math.floor(Math.random() * 1000000000),
                        "total_quantity": total_quantity,
                        "total_price": total_price,
                        "customer": this.customerOrder,
                        "status_order": 1,
                        "user_create": idUser
                })

                HTTP.post('v1/api/order/', dataOrder)
                .then((res) => {
                    this.itemSelect.forEach (item => {
                        const dataSub = JSON.stringify({
                            "title_sub_order": Math.floor(Math.random() * 1000000000),
                            "key_sub_order": Math.floor(Math.random() * 1000000000),
                            "quantity_order": item.quantity,
                            "price_product": item.price_product,
                            "total_price": parseInt(item.quantity)*parseInt(item.price_product),
                            "user_create": idUser,
                            "product": item.id,
                            "order": res.data.id
                        })

                        HTTP.post('v1/api/suborder/', dataSub)
                        .then(() => {
                            this.$router.push({ name: 'body-view', params: {path : 'quan-ly-order'} })
                        })
                    })

					const dataUser = JSON.parse(localStorage.getItem("myAccount"))
					const key_affiliate = dataUser?.code_affiliate || ''
					if (key_affiliate) {
						const payload = {
							key_affiliate: key_affiliate,
							order_id: res.data.id,
							affiliate_link: `${window.location.origin}/quan-ly-order/${res.data.id}`,
							option: "o",
						};
						HTTP.post("/affiliate/create/", payload);
					}
                })
            }

            // thêm mới product
            else {

                var idImg = []
                for await (const item of this.dataInput.avatar) {
                    const dataMedia = new FormData();

                    dataMedia.append('file_media', item)
                    dataMedia.append('user_created', idUser)
                    dataMedia.append('title_media', Math.floor(Math.random() * 1000000000))

                    await HTTP.post('v1/api/mediaproduct/', dataMedia)
                    .then(async (res) => {
                        const a = await res.data.id
                        idImg.push(a)
                    })

                    if (idImg.length === this.avatar.length) {
                        this.postDataProduct(idImg)
                    }
                }
            }
        },

        async postDataProduct (idImg) {
            const idUser = JSON.parse(localStorage.getItem('idUser'))
            for (let item in this.dataInput) {
                if (this.dataInput[item] == "") {
                    delete this.dataInput[item]
                }
            }

            this.dataInput.media = idImg
            this.dataInput.slug = Math.floor(Math.random() * 1000000000);
            this.dataInput.sku = Math.floor(Math.random() * 1000000000);
            this.dataInput.dimensions = JSON.stringify(this.inputDimensions)
            this.dataInput.user_create = idUser
            this.dataInput.status = 1

            let data = JSON.stringify(this.dataInput)

            HTTP.post('https://api.drmom.store' + this.dataAdd.props.urlapi, data)
            .then(() => {
                this.title = 'Tạo sản phẩm thành công'
                this.logo = 'noti-success'
                this.notiView = true
            })
        },
    },

    created() {
        this.getData()
        if (this.$route.params.id) {
            this.getDataDetail()
        }

        this.dataInput.avatar = []
    }
}
</script>

<style scoped lang="scss">
@import 'AddNew.scss';
</style>
