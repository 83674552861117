@<template>
    <div class="landing-new__contaienr f fdc aic pt1" v-bind:style="{background: colorBgPick}">
        
        <!-- cài đặt landing -->
        <div 
            class="landing-new__form p1 border-radius bg-white-1 mb1">
            <div class="landing-new__header f jcb aic mb1">
                <div class="p5 brown">Cài đặt Trang Landing</div>
                <div
                    @click="formStatus = false"
                    class="f aic cursor"
                    v-if="formStatus === true">
                    <div class="h7 gray mr05mp">Phóng to</div>
                    <div class="icon--arrown"></div>    
                </div>
                <div 
                    v-if="$route.params.id"
                    @click="historyFeedback = true"
                    class="history__comment h7 blue cursor">
                    Lịch sử phản hồi
                </div>
            </div>

            <!-- body -->
            <div
                v-if="formStatus === false" 
                class="landing-new__body">

                <!-- tiêu đề trang landing  -->
                <div class="f fdc mb1">
                    <label class="h7 mb05"> {{dataAdd.props.listfieldaddnew.title_funnel.text_lable}} </label>
                    <input 
                        class="h6 border-radius"
                        type="text"
                        placeholder="Nhập tên Trang Landing"
                        v-model="formInput.title_landing">
                </div>

                <!-- màu landing -->
                <div class="mb1">
                    <label class="h7"> Màu nền (Đỏ) </label>
                    <div class="color-pick f aic">
                        <div 
                            class="mb025"
                            v-for="(item, index) in colorBg"
                            :key="index">
                            <div 
                                v-if="item.bgColor === colorBgPick"
                                class="color-pick__item"
                                v-bind:style="{background: item.bgColor, borderColor: item.border}">

                            </div>
                            <div    
                                v-else
                                class="color-pick__item"
                                v-bind:style="{background: item.bgColor}"
                                @click="colorBgPick = item.bgColor">
                            
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ảnh nền landing -->
                <div class="f fdc mb1">
                    <label class="h7"> Ảnh nền <span class="icon--info-i"></span></label>
                    <div 
                        v-if="imgList.length === 0"
                        class="w100 f aic mt05 aic pt1 pb1 pl1 pr1 border-radius" style="border: 1px dashed #D9DDE8;">
                        <div
                            @click="uploadImg" 
                            class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
                            <input 
                                type="file" 
                                accept="image/*" 
                                ref="file"
                                @change="uploadImage($event)" 
                                id="file-input">
                            <i>Tải ảnh nền</i>
                        </div>
                        <div>
                            <div class="p4">Tối đa 1 ảnh </div>
                            <div class="h6 blue cursor"> (Ảnh không đươc quá 2Mb) </div>
                        </div>
                    </div>
                    <div id="list_img-relate" class="list__img f aic mt05">
                        <div
                            class="img-upload__item relative"
                            v-for="(item, index) in imgList"
                            :key="index">
                            <div 
                                @click="handleDeleteImg(item)"
                                class="img-upload__delete absolute jcc aic white cursor">
                                <div class="h6">x</div>
                            </div>
                            <img class="img-upload__img border border-radius mr05" :src="item" alt="">
                        </div>
                    </div>
                </div>

                <!-- Link ảnh hoặc video trang Landing -->
                <div class="f fdc pb1">
                    <label class="h7 pb05">Link ảnh hoặc video trang Landing</label>
                    <input 
                        placeholder="Link ảnh hoặc video trang Landing"
                        class="border-radius h6 pt075 pb075 pl075 pr075" cols="30" rows="10"
                        v-model="formInput.link_img" />
                </div>

                <!-- mô tả trang landing -->
                <div class="f fdc pb1">
                    <label class="h7 pb05">Mô tả</label>
                    <textarea 
                        placeholder="Mô tả trang landing"
                        class="border-radius h6 pt05 pb05 pl05 pr05" cols="30" rows="10"
                        v-model="formInput.description"></textarea>
                </div>
                
                <!-- đường dẫn landing -->
                <div class="f fdc pb1 border-bottom">
                    <label class="h7">Đường dẫn trang Landing của bạn</label>
                    <div class="w100 f jcb aic mt05 pt075 pb075 pl075 pr075 border border-radius bg-gray-3">
                        <div class="h6 blue"> {{handleMakeLink}} </div>
                        <div class="icon--copy cursor"></div>
                    </div>
                </div>

                <!-- cài đặt form --> 
                <div class="f fdc pb1 mt1">
                    <label class="h7">Cài đặt form</label>
                    <div 
                        @click="handleUserForm"
                        class="w100 h7 mt05 pt075 pb075 border border-radius align-c white bg-black cursor">
                        Dùng form cho Landing
                    </div>
                </div>
            </div>

            <!-- footer -->
            <div 
                v-if="formStatus === true"
                class="landing-new__footer f jcb aic">
                <div class="f aic">
                    <span class="h6">Vị trí form:</span>
                    <span class="cursor">
                        <select 
                            v-model="formInput.style"
                            class="brown h7">
                            <option value="3" >Căn giữa</option>
                            <option value="2">Căn phải</option>
                            <option value="1">Căn trái</option>
                        </select>
                    </span>
                    <div class="triangle"></div>

                </div>
                <div class="f jce aic">
                    <div class="pt075 pb075 pl1 pr1 p5 border-radius border">Hủy bỏ</div>
                    <div
                        v-if="$route.params.id"
                        @click="handleUpdateData" 
                        class="pt075 pb075 pl1 pr1 ml1 p5 border-radius white bg-brown cursor">Cập nhật</div>
                    <div
                        v-else
                        @click="handleAddLanding" 
                        class="pt075 pb075 pl1 pr1 ml1 p5 border-radius white bg-brown cursor">Hoàn thành</div>
                </div>
            </div> 
            <div
                v-else >
                <div class="f jce aic">
                    <div
                        @click="$router.go(-1)" 
                        class="pt075 pb075 pl1 pr1 p5 border-radius border cursor">Hủy bỏ</div>
                    <div
                        v-if="$route.params.id"
                        @click="handleUpdateData" 
                        class="pt075 pb075 pl1 pr1 ml1 p5 border-radius white bg-brown cursor">Cập nhật</div>
                    <div
                        v-else
                        @click="handleAddLanding" 
                        class="pt075 pb075 pl1 pr1 ml1 p5 border-radius white bg-brown cursor">Hoàn thành</div>
                </div>
            </div>
        </div>

        <!-- Đường dẫn trang landing -->
        <div 
            v-if="formStatus === true"
            class="form-setting__container px1 border-radius mb1 bg-white-1">
            <div 
                class="setting-landing f jcb aic">
                <div 
                    @click="settingRoute = true, typeRoute = 'Chọn trang Landing'"
                    class="p4 w50 pt05 pb05 border-radius align-c bg-black white cursor">
                    Chọn trang Landing
                </div>
                <div
                    @click="settingRoute = true, typeRoute = 'Dùng đường dẫn đích đến'"
                    class="p4 w50 pt05 pb05 ml1 border border-radius align-c cursor">
                    Dùng đường dẫn đích đến
                </div>
            </div>
            <div>
                <div 
                    v-if="typeRoute === 'Chọn trang Landing' && routeLanding !== ''"
                    class="mt025 w100 one-line h7 gray-1-text">
                    Chuyển hướng đến landing: <span class="p4 black">{{routeLanding}}</span>
                </div>
                <div 
                    v-if="typeRoute === 'Dùng đường dẫn đích đến' && routeLanding !== ''"
                    class="mt025 w100 one-line h7 gray-1-text">
                    Chuyển hướng đến Link: <a target="_blank" :href="routeLanding"><span class="p4 blue">{{routeLanding}}</span></a>
                </div>
                <div 
                    v-if="$route.params.id && routeLanding !== ''"
                    class="mt025 w100 one-line h7 gray-1-text">
                    Chuyển hướng đến : <a target="_blank" :href="routeLanding"><span class="p4 blue">{{routeLanding}}</span></a>
                </div>
            </div>
        </div>

        <!-- cài đặt form -->
        <div
            v-if="formStatus === true" 
            class="form-setting__container">
            <div class="form-setting__title border-radius bg-white mb1">
                <input 
                    type="text" 
                    placeholder="Nhập tiêu đề form"
                    class="form-setting__title-input w100 h2 border-radius gray">
            </div>

            <!-- list câu hỏi -->
            <draggable :list="listQuestion" tag="div" draggable=".step">
                <div 
                    class="step pt1 pb1 pl1 pr1 border-radius bg-white-1 mb1"
                    v-for="(item, index) in listQuestion"
                    :key="index">
                    <div>
                        <div class="f jcb aic mb1">
                            <div class="p5 gray">
                                Câu hỏi {{ index + 1}}
                            </div>
                            <div class="f aic">
                                <div 
                                    v-if="item !== 'Họ và tên' && item !== 'Số điện thoại' && item !== 'Email'"
                                    @click="listQuestion.splice(index, 1)"
                                    class="icon--delete mb025"></div>
                                <div class="ml05">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.25 13.9062C12.4062 13.75 12.4062 13.5 12.25 13.375L11.625 12.75C11.5 12.5938 11.25 12.5938 11.0938 12.75L9.8125 14.1562H9.75V9.75H14.125V9.8125L12.7188 11.125C12.5625 11.2812 12.5625 11.5312 12.7188 11.6562L13.3438 12.2812C13.4688 12.4375 13.7188 12.4375 13.875 12.2812L16.875 9.28125C17.0312 9.125 17.0312 8.90625 16.875 8.75L13.875 5.75C13.7188 5.59375 13.4688 5.59375 13.3438 5.75L12.7188 6.375C12.5625 6.5 12.5625 6.75 12.7188 6.90625L14.125 8.1875V8.25H9.75V3.875H9.8125L11.0938 5.28125C11.25 5.4375 11.5 5.4375 11.625 5.28125L12.25 4.65625C12.4062 4.53125 12.4062 4.28125 12.25 4.125L9.25 1.125C9.09375 0.96875 8.875 0.96875 8.71875 1.125L5.71875 4.125C5.5625 4.28125 5.5625 4.53125 5.71875 4.65625L6.34375 5.28125C6.46875 5.4375 6.71875 5.4375 6.875 5.28125L8.1875 3.875H8.25V8.25H3.84375V8.1875L5.25 6.90625C5.40625 6.75 5.40625 6.5 5.25 6.375L4.625 5.75C4.5 5.59375 4.25 5.59375 4.09375 5.75L1.09375 8.75C0.9375 8.90625 0.9375 9.125 1.09375 9.28125L4.09375 12.2812C4.25 12.4375 4.5 12.4375 4.625 12.2812L5.25 11.6562C5.40625 11.5312 5.40625 11.2812 5.25 11.125L3.84375 9.8125V9.75H8.25V14.1562H8.1875L6.875 12.75C6.71875 12.5938 6.46875 12.5938 6.34375 12.75L5.71875 13.375C5.5625 13.5 5.5625 13.75 5.71875 13.9062L8.71875 16.9062C8.875 17.0625 9.09375 17.0625 9.25 16.9062L12.25 13.9062Z" fill="#757575"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div 
                            v-if="item === 'Họ và tên' || item === 'Số điện thoại' || item === 'Email'"
                            class="px075 border border-radius h6">
                            {{item}}
                        </div>
                        <input 
                            v-else
                            v-model="listQuestion[index]"
                            placeholder="Nhập câu hỏi"
                            class="h6 w100">
                    </div>
                    <!-- <div v-else>
                        <div class="f jcb aic mb1">
                            <div class="p5 gray">
                                Câu hỏi {{ index + 1}}
                            </div>
                            <div>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.25 13.9062C12.4062 13.75 12.4062 13.5 12.25 13.375L11.625 12.75C11.5 12.5938 11.25 12.5938 11.0938 12.75L9.8125 14.1562H9.75V9.75H14.125V9.8125L12.7188 11.125C12.5625 11.2812 12.5625 11.5312 12.7188 11.6562L13.3438 12.2812C13.4688 12.4375 13.7188 12.4375 13.875 12.2812L16.875 9.28125C17.0312 9.125 17.0312 8.90625 16.875 8.75L13.875 5.75C13.7188 5.59375 13.4688 5.59375 13.3438 5.75L12.7188 6.375C12.5625 6.5 12.5625 6.75 12.7188 6.90625L14.125 8.1875V8.25H9.75V3.875H9.8125L11.0938 5.28125C11.25 5.4375 11.5 5.4375 11.625 5.28125L12.25 4.65625C12.4062 4.53125 12.4062 4.28125 12.25 4.125L9.25 1.125C9.09375 0.96875 8.875 0.96875 8.71875 1.125L5.71875 4.125C5.5625 4.28125 5.5625 4.53125 5.71875 4.65625L6.34375 5.28125C6.46875 5.4375 6.71875 5.4375 6.875 5.28125L8.1875 3.875H8.25V8.25H3.84375V8.1875L5.25 6.90625C5.40625 6.75 5.40625 6.5 5.25 6.375L4.625 5.75C4.5 5.59375 4.25 5.59375 4.09375 5.75L1.09375 8.75C0.9375 8.90625 0.9375 9.125 1.09375 9.28125L4.09375 12.2812C4.25 12.4375 4.5 12.4375 4.625 12.2812L5.25 11.6562C5.40625 11.5312 5.40625 11.2812 5.25 11.125L3.84375 9.8125V9.75H8.25V14.1562H8.1875L6.875 12.75C6.71875 12.5938 6.46875 12.5938 6.34375 12.75L5.71875 13.375C5.5625 13.5 5.5625 13.75 5.71875 13.9062L8.71875 16.9062C8.875 17.0625 9.09375 17.0625 9.25 16.9062L12.25 13.9062Z" fill="#757575"/>
                                </svg>
                            </div>
                        </div>
                        <div class="h7 pt075 pb075 pl075 pr075 border-radius-s border">
                            {{item}}
                        </div>
                    </div> -->
                </div>
            </draggable>
            <div 
                v-if="listQuestion.length < 8"
                @click="listQuestion.push('')"
                class="w100 px1 border-radius bg-brown white align-c">
                Thêm câu hỏi
            </div>
            <div 
                v-else
                class="w100 px1 border-radius bg-brown-1 white align-c">
                Thêm câu hỏi
            </div>
        </div>

        <AddRouteAction 
            v-if="settingRoute"
            :typeRoute='typeRoute'
            @updateRouteLanding='updateRouteLanding'
            @closePopup='closePopup'/>

        <NotiView 
            v-if="notiView"
            :title="title"
            :logo="logo"
            @closePopup='closeNoti'/>

        <HistoryFeedback 
            v-if="historyFeedback"
            @closePopup='closePopupHistory'/>
    </div>
</template> 

<script>
import draggable from 'vuedraggable'
import AddRouteAction from './AddRouteAction/AddRouteAction.vue'
import NotiView from '@/components/NotiView/NotiView.vue'
import HistoryFeedback from '@/components/historyFeedback/historyFeedback.vue'

import { HTTP } from '@/http-default'
export default {
    components : {
        draggable,
        AddRouteAction,
        NotiView,
        HistoryFeedback
    },
    props : {
        dataAdd : {
            type: Object,
            default () {
                return {}
            }
        },
        dataEdit : {
            type : Object,
            default () {
                return {}
            }
        }
    },

    watch : {
        'dataEdit' : function () {
            this.formInput = {...this.dataEdit}
            this.listQuestion = this.dataEdit.question
            this.routeLanding = this.dataEdit.next_route
            this.imgList.push(this.dataEdit.img_landing)
        }
    },

    data () {
        return {
            historyFeedback : false,

            notiView : false,
            title : '',
            logo : '',

            listAddField : {},

            settingRoute : false,
            typeRoute : '',
            routeLanding : '',
 
            // color background
            colorBg : [
                {
                    color: 'red',
                    border: '#B82E45',
                    bgColor: '#F8EBED'
                },
                {   
                    color: 'blue',
                    border: '#41BAF9',
                    bgColor: '#EAF2FA'
                },
                {
                    color: 'yellow',
                    border: '#FF8A00',
                    bgColor: '#FFF4E6'
                },
                {
                    color: 'green',
                    border: '#69BE31',
                    bgColor: '#F0F9EB'
                },
                {
                    color: 'pink',
                    border: '#ED417F',
                    bgColor: '#F6EBF9'
                },
                {
                    color: 'brown',
                    border: '#F56048',
                    bgColor: '#F1ECEA'
                }
            ],
            colorBgPick: '#F8EBED',

            // question
            formStatus : false,
            listQuestion : [
                'Họ và tên',
                'Số điện thoại',
                'Email',
                ''
            ],

            imgList : [],

            // formInput
            formInput : {
                title_landing: "",
                description: "",
                link_img: '',
                img_landing: null,
                key_landing: this.handleKeyLanding(20),
                question: "",
                style: "",
                user_created: ''
            }
        }
    },

    computed : {
        handleMakeLink () {
            var url = 'https://test.drmom.xyz/?#/landing/'

            url = url + this.formInput.title_landing.replaceAll(' ', '-').toLowerCase()

            return url
        },
    },

    methods : {
        closePopup () {
            this.settingRoute = false
        },

        closeNoti () {
            this.notiView = false
        },

        updateRouteLanding (value) {
            this.routeLanding = value
        },

        closePopupHistory () {
            this.historyFeedback = false
        },

        handleKeyLanding (length) {
            var result           = '';
                var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        handleDeleteImg (value) {
            for (let i =0 ; i < this.imgList.length; i++) {
                if (this.imgList[i] == value) {
                    this.imgList.splice(i, 1)
                }
            }
            this.formInput.img_landing = null
        },

        // sử dụng form
        handleUserForm () {
            this.formStatus = true
        },

        // upload img
        uploadImg () {
            var input = document.getElementById('file-input')
            input.click()
        },

        uploadImage(event) {
            var url = URL.createObjectURL(event.target.files[0]);
            this.imgList.push(url)
            this.formInput.img_landing = event.target.files[0]
        },

        getFormInput () {
            for (let item in this.dataAdd.props.listfieldaddnew) {
                this.listAddField[item] = ''
            }
        },

        handleUpdateData () {
            var dataInput = {...this.formInput}

            for (let i = 0; i < dataInput.question.length; i++) {
                if (dataInput.question[i] === '') {
                    dataInput.question.splice(i, 1)
                }
            }

            console.log(dataInput);

            delete dataInput.img_landing
            var data = JSON.stringify(dataInput)

            HTTP.patch('v1/api/landing/' + this.$route.params.id + '/', data)
            .then(() => {
                this.title = 'Cập nhật thông tin thành công'
                this.logo = 'noti-success'
                this.notiView = true
            })
            .catch(() => {
                this.title = 'Cập nhật thông tin thất bại'
                this.logo = 'noti-warning'
                this.notiView = true
            })
        }, 

        handleAddLanding (event) {
            const idUser = JSON.parse(localStorage.getItem('idUser'))
            event.preventDefault() 

            for (let i = 0; i < this.listQuestion.length; i++) {
                if (this.listQuestion[i] === '') {
                    this.listQuestion.splice(i, 1)
                }
            }

            var question = JSON.stringify(
                this.listQuestion
            )
            
            let data = new FormData(); 

            if (this.formInput.img_landing !== null) {
                data.append('img_landing', this.formInput.img_landing);
            }

            data.append('title_landing', this.formInput.title_landing);
            data.append('style', this.formInput.style);
            data.append('link_img', this.formInput.link_img);
            data.append('description', this.formInput.description);
            data.append('key_landing', this.formInput.key_landing);
            data.append('color', this.colorBgPick);
            data.append('question', question);
            data.append('user_created', idUser);
            data.append('next_route', this.routeLanding);

            HTTP.post('v1/api/landing/', data)
            .then(() => {
                this.$router.go(-1)
            })
        }
    },

    created () {
        this.getFormInput()
    }
}
</script>

<style scoped lang='scss'>
    @import 'AddLanding.scss';
</style>