<template>
  <div class="forums__container h100vh w100 bg-gray-2 page page--forums">
    <HeaderType2 v-if="!isMobile"></HeaderType2>
    <HeaderLanding v-else></HeaderLanding>
    <div class="banner">
      <img 
        class="w100"
        src="../../assets/forums/banner_forum.jpeg" alt="">
    </div>
    <PostForum :isMobile="isMobile" />
    <FooterSection></FooterSection>
  </div>
</template>

<script>
import HeaderType2 from '@/components/HeaderType2/HeaderType2.vue'
import FooterSection from '@/components/FooterSection/FooterSection.vue'
import PostForum from '@/components/PostForum/PostForum.vue'
import HeaderLanding from '../Landingpage/HeaderLanding/HeaderLanding.vue'

export default {
  components: {
    HeaderType2,
    FooterSection,
    PostForum,
    HeaderLanding,
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
}
</script>
<style scoped lang='scss'>
    @import 'WebPostView.scss';
</style>