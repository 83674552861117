@<template>
    <div class="popup popup-setting f jcc aic">
        <div class="setting__container px1 border-radius bg-white-1">
            <div class="setting__header p3 brown mb1">
                Thiết lập quyền user
            </div>
            <div class="setting__body">
                <div class="p4 mb1">
                    Chức năng
                </div>

                <div
                    v-for="(item, key) in dataSetting"
                    :key="key"
                    class="f jcb aic px1 border border-radius bg-gray-2 mb1">
                    <div class="h5">
                        Quản lý {{ renderTitle(key) }}
                    </div>
                    <div>
                        <input 
                            type="checkbox"
                            v-model="dataSetting[key]"
                            :value="dataSetting[key]">
                    </div>
                </div>
            </div>
            <div class="setting__action f jce aic">
                <div 
                    @click="closePopup"
                    class="px1 border-radius h7 border cursor">Đóng</div>
                <div 
                    @click="handleSave"
                    class="px1 ml1 border-radius h7 bg-brown white cursor">Lưu lại</div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        dataUser : {
            type : Object,
            default () {
                return {}
            }
        }
    },

    data () {
        return {
            dataSetting : {}
        }
    },

    watch : {
        'dataUser' : function () {
            this.dataSetting = {...this.dataUser.permisson}
        }
    },

    methods : {
        closePopup () {
            this.$emit('closePopup')
        },

        handleSave () {
            var data = JSON.stringify({
                'permisson' : this.dataSetting
            })
            HTTP.patch('v1/api/customer/' + this.dataUser.id + '/', data)
            .then(() => {
                this.closePopup()
                const noti = {isShow: true, title: 'Cập nhật thành công', logo: 'noti-success'}
                this.$emit('updateData', noti)
            })
            .catch((e) => {
                const noti = {isShow: true, title: 'Cập nhật thất bại', logo: 'noti-warning'} 
                this.$emit('updateData', noti)
            })
        },

        renderTitle (key) {
            const obj = {
                "user": 'Người dùng',
                "funnel": 'Phễu',
                "gift": 'Quà tặng',
                "customer": 'Khách hàng',
                "order": 'Đơn hàng',
                "product": 'Sản phẩm',
                "post": 'Bài viết',
                "can_post_forum": 'Đăng bài Forum', 
            }

            return obj[key]
        },
    },  

    mounted () {
        this.dataSetting = {...this.dataUser.permisson}
    }
}
</script>

<style scoped lang='scss'>
    @import 'PermissionView.scss';
</style>