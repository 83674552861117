<template>
  <div
    :class="[
      isMobile
        ? 'aside-menu white'
        : 'sidebar w15 pr2 pt15 bg-white-1 gray-1-text',
      isShowMenuMobile && 'active-menu',
    ]"
  >
    <div class="relative menu-container">
      <div
        v-if="isShowMenuMobile"
        class="overlay"
        @click="$emit('close-nav')"
      ></div>
      <div class="menu-content px1">
        <div
          v-if="account && isMobile"
          class="action__container relative f aic border-radius ph1 gap1 mb1"
        >
          <div class="">
            <img class="image_user" :src="myAccount.avatar" alt="" />
          </div>
          <div v-if="myAccount.full_name !== ''" class="p4">
            {{ myAccount.full_name }}
          </div>
          <div v-if="myAccount.full_name === ''" class="p4">
            {{ myAccount.user__username }}
          </div>
        </div>
        <div class="sidebar__item cursor f ai w100">
          <div
            v-if="$route.params.path === 'quan-ly-user'"
            class="sidebar__item active cursor f ai w100 pl15 pt075 pb075"
          >
            <div class="icon--user-side-bar w10 mr15"></div>
            <router-link
              :to="{ name: 'body-view', params: { path: 'quan-ly-user' } }"
            >
              <div
                @click="handleClick"
                class="sidebar__item-title ml025 active-text qcont p6"
              >
                User
              </div>
            </router-link>
          </div>
          <div v-else class="sidebar__item cursor f ai w100 pl15 pt075 pb075">
            <div class="icon--user-side-bar w10 mr15"></div>
            <router-link
              :to="{ name: 'body-view', params: { path: 'quan-ly-user' } }"
            >
              <div
                @click="handleClick"
                class="sidebar__item-title qcont ml025 p6"
              >
                User
              </div>
            </router-link>
          </div>
        </div>
        <div
          v-for="(item, index) in sidebarSetting"
          :key="index"
          class="sidebar__item cursor f ai w100"
        >
          <div
            v-if="item.path == path"
            class="sidebar__item active cursor f ai w100 pl15 pt075 pb075"
          >
            <svg viewBox="0 0 20 16" class="w10 mr15">
              <use
                xlink="http://www.w3.org/2000/svg"
                :href="'#' + item.icon"
                x="0"
                y="0"
              ></use>
            </svg>
            <router-link :to="item.path">
              <div
                @click="handleClick"
                class="sidebar__item-title active-text qcont p6"
              >
                {{ item.titlemenu }}
              </div>
            </router-link>
          </div>
          <div v-else class="sidebar__item cursor f ai w100 pl15 pt075 pb075">
            <svg viewBox="0 0 20 16" class="w10 mr15">
              <use
                xlink="http://www.w3.org/2000/svg"
                :href="'#' + item.icon"
                x="0"
                y="0"
              ></use>
            </svg>
            <router-link :to="item.path">
              <div @click="handleClick" class="sidebar__item-title qcont p6">
                {{ item.titlemenu }}
              </div>
            </router-link>
          </div>
        </div>
        <div class="sidebar__item cursor f ai w100">
          <div
            v-if="$route.params.path === 'website'"
            class="sidebar__item active cursor f ai w100 pl15 pt075 pb075"
          >
            <div class="icon--global w10 mr15"></div>
            <router-link
              :to="{ name: 'body-view', params: { path: 'website' } }"
            >
              <div
                @click="handleClick"
                class="sidebar__item-title active-text qcont p6"
              >
                Website
              </div>
            </router-link>
          </div>
          <div v-else class="sidebar__item cursor f ai w100 pl15 pt075 pb075">
            <div class="icon--global w10 mr15"></div>
            <router-link
              :to="{ name: 'body-view', params: { path: 'website' } }"
            >
              <div @click="handleClick" class="sidebar__item-title qcont p6">
                Website
              </div>
            </router-link>
          </div>
        </div>
        <div class="sidebar__item cursor f aic w100">
          <div class="sidebar__item cursor f aic w100 pl15 pt075 pb075">
            <div class="w10 mr15">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="homeSvg"
                  d="M17.8125 6.75L10.0938 0.40625C9.8125 0.15625 9.40625 0.03125 9 0.03125C8.5625 0.03125 8.15625 0.15625 7.875 0.40625L0.15625 6.75C0.0625 6.84375 0 6.96875 0 7.125C0 7.25 0.03125 7.375 0.09375 7.4375L0.4375 7.84375C0.5 7.9375 0.65625 8 0.8125 8C0.9375 8 1.03125 7.96875 1.125 7.90625L2 7.1875V9H1.96875V15.5C1.96875 15.7812 2.1875 16 2.46875 16H15.5C15.75 16 16 15.7812 16 15.5V7.1875L16.8438 7.90625C16.9375 7.96875 17.0312 8.03125 17.1562 8.03125C17.3125 8.03125 17.4688 7.9375 17.5625 7.84375L17.875 7.4375C17.9375 7.375 18 7.25 18 7.125C18 6.96875 17.9062 6.84375 17.8125 6.75ZM14.5 7H14.4688V14.5H11V10C11 9.46875 10.5312 9 10 9H8C7.4375 9 7 9.46875 7 10V14.5H3.46875V6.09375H3.5V5.96875L9 1.4375L14.5 5.96875V7Z"
                  fill="#6F382B"
                />
              </svg>
            </div>
            <router-link
              :to="{ name: 'body-view', params: { path: 'forums' } }"
            >
              <div @click="handleClick" class="sidebar__item-title qcont p6">
                Forums
              </div>
            </router-link>
          </div>
        </div>
        <div v-if="isMobile" class="sidebar__item cursor f jcc aic border border-radius pv05 absolute" style="bottom: 1em; left: 1em; width: 90%;"  @click="handleLogout">
            <p class="qcont h6 align-r">
                Đăng xuất
            </p>
        </div>
        <div class="hide">
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="homeSvg"
              d="M17.8125 6.75L10.0938 0.40625C9.8125 0.15625 9.40625 0.03125 9 0.03125C8.5625 0.03125 8.15625 0.15625 7.875 0.40625L0.15625 6.75C0.0625 6.84375 0 6.96875 0 7.125C0 7.25 0.03125 7.375 0.09375 7.4375L0.4375 7.84375C0.5 7.9375 0.65625 8 0.8125 8C0.9375 8 1.03125 7.96875 1.125 7.90625L2 7.1875V9H1.96875V15.5C1.96875 15.7812 2.1875 16 2.46875 16H15.5C15.75 16 16 15.7812 16 15.5V7.1875L16.8438 7.90625C16.9375 7.96875 17.0312 8.03125 17.1562 8.03125C17.3125 8.03125 17.4688 7.9375 17.5625 7.84375L17.875 7.4375C17.9375 7.375 18 7.25 18 7.125C18 6.96875 17.9062 6.84375 17.8125 6.75ZM14.5 7H14.4688V14.5H11V10C11 9.46875 10.5312 9 10 9H8C7.4375 9 7 9.46875 7 10V14.5H3.46875V6.09375H3.5V5.96875L9 1.4375L14.5 5.96875V7Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="icon--order"
              d="M17.2188 2H4.5L4.21875 0.625C4.15625 0.28125 3.84375 0 3.5 0H0.375C0.15625 0 0 0.1875 0 0.375V1.125C0 1.34375 0.15625 1.5 0.375 1.5H2.875L5.03125 12.625C4.6875 13 4.5 13.4688 4.5 14C4.5 15.125 5.375 16 6.5 16C7.59375 16 8.5 15.125 8.5 14C8.5 13.6562 8.375 13.3125 8.21875 13H12.75C12.5938 13.3125 12.5 13.6562 12.5 14C12.5 15.125 13.375 16 14.5 16C15.5938 16 16.5 15.125 16.5 14C16.5 13.4375 16.25 12.9375 15.875 12.5625L15.9062 12.4375C16 11.9688 15.6562 11.5 15.1562 11.5H6.34375L6.0625 10H15.8125C16.1875 10 16.4688 9.78125 16.5625 9.4375L17.9688 2.9375C18.0625 2.46875 17.7188 2 17.2188 2ZM6.5 14.75C6.0625 14.75 5.75 14.4375 5.75 14C5.75 13.5938 6.0625 13.25 6.5 13.25C6.90625 13.25 7.25 13.5938 7.25 14C7.25 14.4375 6.90625 14.75 6.5 14.75ZM14.5 14.75C14.0625 14.75 13.75 14.4375 13.75 14C13.75 13.5938 14.0625 13.25 14.5 13.25C14.9062 13.25 15.25 13.5938 15.25 14C15.25 14.4375 14.9062 14.75 14.5 14.75ZM15.2188 8.5H5.75L4.78125 3.5H16.3125L15.2188 8.5Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="icon--customer"
              d="M15 7C16.6562 7 18 5.65625 18 4C18 2.34375 16.6562 1 15 1C13.3438 1 12 2.34375 12 4C12 5.65625 13.3438 7 15 7ZM15 2.5C15.8125 2.5 16.5 3.1875 16.5 4C16.5 4.84375 15.8125 5.5 15 5.5C14.1562 5.5 13.5 4.84375 13.5 4C13.5 3.1875 14.1562 2.5 15 2.5ZM8.5 7.625C8.125 7.625 7.75 7.6875 7.375 7.8125C6.9375 7.9375 6.46875 8 5.96875 8C5.5 8 5.03125 7.9375 4.59375 7.8125C4.21875 7.6875 3.84375 7.625 3.46875 7.625C2.34375 7.625 1.25 8.15625 0.59375 9.09375C0.21875 9.6875 0 10.375 0 11.125V12.5C0 13.3438 0.65625 14 1.5 14H10.5C11.3125 14 12 13.3438 12 12.5V11.125C12 10.375 11.75 9.6875 11.375 9.09375C10.7188 8.15625 9.625 7.625 8.5 7.625ZM10.5 12.5H1.5V11.125C1.5 10.2188 2.0625 9.4375 2.875 9.15625C3.1875 9.03125 3.53125 9.03125 3.875 9.15625C4.5625 9.375 5.28125 9.5 6 9.5C6.71875 9.5 7.40625 9.375 8.125 9.15625C8.4375 9.03125 8.78125 9.03125 9.125 9.15625C9.90625 9.4375 10.5 10.2188 10.5 11.125V12.5ZM6 7C7.90625 7 9.5 5.4375 9.5 3.5C9.5 1.59375 7.90625 0 6 0C4.0625 0 2.5 1.59375 2.5 3.5C2.5 5.4375 4.0625 7 6 7ZM6 1.5C7.09375 1.5 8 2.40625 8 3.5C8 4.625 7.09375 5.5 6 5.5C4.875 5.5 4 4.625 4 3.5C4 2.40625 4.875 1.5 6 1.5ZM19.4688 8.9375C18.9375 8.125 18.0312 7.6875 17.0625 7.6875C16.2188 7.6875 16 8 15 8C14 8 13.7812 7.6875 12.9062 7.6875C12.4688 7.6875 12.0938 7.78125 11.7188 7.96875C11.875 8.125 12.0625 8.34375 12.2188 8.53125C12.3438 8.75 12.4688 9 12.5938 9.21875C12.6875 9.21875 12.7812 9.1875 12.9062 9.1875C13.4375 9.1875 13.8125 9.5 15 9.5C16.1562 9.5 16.5312 9.1875 17.0625 9.1875C17.5625 9.1875 18 9.40625 18.2188 9.75C18.4062 10 18.5 10.2812 18.5 10.5938V11.5H13V12.5C13 12.6875 12.9688 12.8438 12.9375 13H18.75C19.4375 13 20 12.4688 20 11.75V10.5938C20 9.96875 19.8125 9.40625 19.4688 8.9375Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="20"
            height="14"
            viewBox="0 0 20 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="forumSvg"
              d="M17 6C18.375 6 19.5 4.90625 19.5 3.5C19.5 2.125 18.375 1 17 1C15.5938 1 14.5 2.125 14.5 3.5C14.5 4.90625 15.5938 6 17 6ZM17 2.5C17.5312 2.5 18 2.96875 18 3.5C18 4.0625 17.5312 4.5 17 4.5C16.4375 4.5 16 4.0625 16 3.5C16 2.96875 16.4375 2.5 17 2.5ZM3 6C4.375 6 5.5 4.90625 5.5 3.5C5.5 2.125 4.375 1 3 1C1.59375 1 0.5 2.125 0.5 3.5C0.5 4.90625 1.59375 6 3 6ZM3 2.5C3.53125 2.5 4 2.96875 4 3.5C4 4.0625 3.53125 4.5 3 4.5C2.4375 4.5 2 4.0625 2 3.5C2 2.96875 2.4375 2.5 3 2.5ZM15.375 9.09375C14.5 7.84375 12.8438 7.34375 11.375 7.8125C10.9375 7.9375 10.4688 8 9.96875 8C9.5 8 9.03125 7.9375 8.59375 7.8125C7.125 7.34375 5.4375 7.8125 4.59375 9.09375C4.21875 9.6875 3.96875 10.375 3.96875 11.125V12.5C3.96875 13.3438 4.65625 14 5.46875 14H14.4688C15.3125 14 15.9688 13.3438 15.9688 12.5V11.125C16 10.375 15.75 9.6875 15.375 9.09375ZM14.5 12.5H5.5V11.125C5.5 9.96875 6.40625 9.03125 7.53125 9C8.3125 9.34375 9.15625 9.5 10 9.5C10.8125 9.5 11.6562 9.34375 12.4375 9C13.5625 9.03125 14.5 9.96875 14.5 11.125V12.5ZM17.375 7H16.625C16.0625 7 15.5625 7.1875 15.1562 7.46875C15.5625 7.78125 15.9375 8.15625 16.2188 8.59375C16.3438 8.5625 16.4688 8.5 16.5938 8.5H17.3438C17.9688 8.5 18.4688 9.03125 18.4688 9.625C18.4688 10.0625 18.8125 10.375 19.2188 10.375C19.6562 10.375 19.9688 10.0625 19.9688 9.625C20 8.1875 18.8125 7 17.375 7ZM10 7C11.9062 7 13.5 5.4375 13.5 3.5C13.5 1.59375 11.9062 0 10 0C8.0625 0 6.5 1.59375 6.5 3.5C6.5 5.4375 8.0625 7 10 7ZM10 1.5C11.0938 1.5 12 2.40625 12 3.5C12 4.625 11.0938 5.5 10 5.5C8.875 5.5 8 4.625 8 3.5C8 2.40625 8.875 1.5 10 1.5ZM4.8125 7.46875C4.40625 7.1875 3.90625 7 3.375 7H2.625C1.15625 7 0 8.1875 0 9.625C0 10.0625 0.3125 10.375 0.75 10.375C1.15625 10.375 1.5 10.0625 1.5 9.625C1.5 9.03125 2 8.5 2.625 8.5H3.375C3.5 8.5 3.625 8.5625 3.75 8.59375C4.03125 8.15625 4.40625 7.78125 4.8125 7.46875Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="icon--funnel"
              d="M15.4688 0H2.5C1.15625 0 0.46875 1.625 1.4375 2.5625L6.5 7.625V13C6.5 13.5 6.71875 13.9375 7.09375 14.25L9.09375 15.75C10.0625 16.4062 11.5 15.7812 11.5 14.5312V7.625L16.5312 2.5625C17.5 1.625 16.8125 0 15.4688 0ZM10 7V14.5L8 13V7L2.5 1.5H15.5L10 7Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="icon--gift"
              d="M14.5 4.5H13.6562C13.875 4.125 14 3.71875 14 3.25C14 1.75 12.75 0.5 11.25 0.5C9.9375 0.5 9.09375 1.1875 8.03125 2.65625C6.9375 1.1875 6.09375 0.5 4.8125 0.5C3.28125 0.5 2.0625 1.75 2.0625 3.25C2.0625 3.71875 2.15625 4.125 2.375 4.5H1.5C0.65625 4.5 0 5.1875 0 6V10C0 10.2812 0.21875 10.5 0.5 10.5H1V13.875C1 14.7812 1.71875 15.5 2.625 15.5H13.3438C14.25 15.5 15 14.7812 15 13.875V10.5H15.5C15.75 10.5 16 10.2812 16 10V6C16 5.1875 15.3125 4.5 14.5 4.5ZM7.25 14H2.625C2.5625 14 2.5 13.9375 2.5 13.875V10.5H6V9H1.5V6H7.25V14ZM4.78125 4.5C4.09375 4.5 3.53125 3.96875 3.53125 3.25C3.53125 2.5625 4.09375 2 4.78125 2C5.46875 2 5.96875 2.25 7.4375 4.40625L7.5 4.5H4.78125ZM8.59375 4.40625C10.0625 2.25 10.5625 2 11.25 2C11.9375 2 12.5 2.5625 12.5 3.25C12.5 3.96875 11.9375 4.5 11.25 4.5H8.53125L8.59375 4.40625ZM14.5 9H10V10.5H13.5V13.875C13.5 13.9375 13.4375 14 13.3438 14H8.75V6H14.5V9Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="itemSvg"
              d="M17.2188 2H4.5L4.21875 0.625C4.15625 0.28125 3.84375 0 3.5 0H0.375C0.15625 0 0 0.1875 0 0.375V1.125C0 1.34375 0.15625 1.5 0.375 1.5H2.875L5.03125 12.625C4.6875 13 4.5 13.4688 4.5 14C4.5 15.125 5.375 16 6.5 16C7.59375 16 8.5 15.125 8.5 14C8.5 13.6562 8.375 13.3125 8.21875 13H12.75C12.5938 13.3125 12.5 13.6562 12.5 14C12.5 15.125 13.375 16 14.5 16C15.5938 16 16.5 15.125 16.5 14C16.5 13.4375 16.25 12.9375 15.875 12.5625L15.9062 12.4375C16 11.9688 15.6562 11.5 15.1562 11.5H6.34375L6.0625 10H15.8125C16.1875 10 16.4688 9.78125 16.5625 9.4375L17.9688 2.9375C18.0625 2.46875 17.7188 2 17.2188 2ZM6.5 14.75C6.0625 14.75 5.75 14.4375 5.75 14C5.75 13.5938 6.0625 13.25 6.5 13.25C6.90625 13.25 7.25 13.5938 7.25 14C7.25 14.4375 6.90625 14.75 6.5 14.75ZM14.5 14.75C14.0625 14.75 13.75 14.4375 13.75 14C13.75 13.5938 14.0625 13.25 14.5 13.25C14.9062 13.25 15.25 13.5938 15.25 14C15.25 14.4375 14.9062 14.75 14.5 14.75ZM15.2188 8.5H5.75L4.78125 3.5H16.3125L15.2188 8.5Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="icon--post"
              d="M17.25 0H3.5C2.84375 0 2.28125 0.4375 2.0625 1H0.75C0.3125 1 0 1.34375 0 1.75V10.25C0 11.2188 0.78125 12 1.75 12H17.25C17.6562 12 18 11.6875 18 11.25V0.75C18 0.34375 17.6562 0 17.25 0ZM1.5 10.25V2.5H2V10.25C2 10.4062 1.875 10.5 1.75 10.5C1.59375 10.5 1.5 10.4062 1.5 10.25ZM16.5 10.5H3.46875C3.46875 10.4375 3.5 10.3438 3.5 10.25V1.5H16.5V10.5ZM5.375 6.75H9.625C9.8125 6.75 10 6.59375 10 6.375V3.375C10 3.1875 9.8125 3 9.625 3H5.375C5.15625 3 5 3.1875 5 3.375V6.375C5 6.59375 5.15625 6.75 5.375 6.75ZM6.25 4.25H8.75V5.5H6.25V4.25ZM5 8.625C5 8.84375 5.15625 9 5.375 9H9.625C9.8125 9 10 8.84375 10 8.625V7.875C10 7.6875 9.8125 7.5 9.625 7.5H5.375C5.15625 7.5 5 7.6875 5 7.875V8.625ZM11 8.625C11 8.84375 11.1562 9 11.375 9H14.625C14.8125 9 15 8.84375 15 8.625V7.875C15 7.6875 14.8125 7.5 14.625 7.5H11.375C11.1562 7.5 11 7.6875 11 7.875V8.625ZM11 4.125C11 4.34375 11.1562 4.5 11.375 4.5H14.625C14.8125 4.5 15 4.34375 15 4.125V3.375C15 3.1875 14.8125 3 14.625 3H11.375C11.1562 3 11 3.1875 11 3.375V4.125ZM11 6.375C11 6.59375 11.1562 6.75 11.375 6.75H14.625C14.8125 6.75 15 6.59375 15 6.375V5.625C15 5.4375 14.8125 5.25 14.625 5.25H11.375C11.1562 5.25 11 5.4375 11 5.625V6.375Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="icon--product"
              d="M15.9062 5.78125L14.3125 1.03125C14.125 0.4375 13.5625 0 12.9062 0H3.0625C2.40625 0 1.84375 0.4375 1.65625 1.03125L0.0625 5.78125C0 5.9375 0 6.09375 0 6.25V14.5C0 15.3438 0.65625 16 1.5 16H14.5C15.3125 16 16 15.3438 16 14.5V6.25C16 6.09375 15.9688 5.9375 15.9062 5.78125ZM14.4062 6H8.75V1.5H12.9062L14.4062 6ZM3.0625 1.5H7.25V6H1.5625L3.0625 1.5ZM1.5 14.5V7.5H14.5V14.5H1.5Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="stallSvg"
              d="M19.8125 3.71875L17.7812 0.46875C17.5938 0.1875 17.2812 0 16.9375 0H4.3125C3.96875 0 3.625 0.1875 3.4375 0.46875L1.4375 3.71875C0.5 5.1875 1.125 7.1875 2.625 7.8125V14.9062C2.625 15.5312 3.0625 16 3.625 16H17.625C18.1562 16 18.625 15.5312 18.625 14.9062V7.8125C20.125 7.1875 20.7188 5.1875 19.8125 3.71875ZM17.125 14.5H4.125V11.5H17.125V14.5ZM17.0938 10H4.125V8C4.875 7.875 5.53125 7.5 6 6.96875C6.5625 7.59375 7.375 8 8.3125 8C9.21875 8 10.0312 7.59375 10.5938 6.96875C11.1562 7.59375 12 8 12.9062 8C13.8438 8 14.6562 7.59375 15.2188 6.96875C15.6875 7.5 16.3438 7.875 17.0938 8V10ZM18.5938 5.84375C18.5 6.0625 18.25 6.4375 17.75 6.5C17.6875 6.5 17.5938 6.53125 17.5312 6.53125C17.0625 6.53125 16.6562 6.3125 16.3438 5.96875L15.2188 4.71875L14.125 5.96875C13.8125 6.3125 13.375 6.5 12.9062 6.5C12.4688 6.5 12.0312 6.3125 11.7188 5.96875L10.625 4.71875L9.5 5.96875C9.1875 6.3125 8.75 6.5 8.3125 6.5C7.84375 6.5 7.4375 6.3125 7.125 5.96875L6 4.71875L4.875 5.96875C4.5625 6.3125 4.15625 6.5 3.6875 6.5C3.625 6.5 3.53125 6.5 3.46875 6.5C2.96875 6.4375 2.71875 6.0625 2.625 5.84375C2.46875 5.5 2.40625 5 2.6875 4.53125L4.5625 1.5H16.6562L18.5312 4.53125C18.8125 5 18.75 5.5 18.5938 5.84375Z"
              fill="#6F382B"
            />
          </svg>
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="userSvg"
              d="M9.78125 9.5C8.875 9.5 8.46875 10 7 10C5.5 10 5.09375 9.5 4.1875 9.5C1.875 9.5 0 11.4062 0 13.7188V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V13.7188C14 11.4062 12.0938 9.5 9.78125 9.5ZM12.5 14.5H1.5V13.7188C1.5 12.2188 2.6875 11 4.1875 11C4.65625 11 5.375 11.5 7 11.5C8.59375 11.5 9.3125 11 9.78125 11C11.2812 11 12.5 12.2188 12.5 13.7188V14.5ZM7 9C9.46875 9 11.5 7 11.5 4.5C11.5 2.03125 9.46875 0 7 0C4.5 0 2.5 2.03125 2.5 4.5C2.5 7 4.5 9 7 9ZM7 1.5C8.625 1.5 10 2.875 10 4.5C10 6.15625 8.625 7.5 7 7.5C5.34375 7.5 4 6.15625 4 4.5C4 2.875 5.34375 1.5 7 1.5Z"
              fill="#6F382B"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sidebar: Array,
    isMobile: {
      type: Boolean,
      default: false,
    },
    isShowMenuMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      path: "",
      myAccount: {},
      sidebarSetting: [],
      account: null,
    };
  },
  methods: {
    handleClick(event) {
      var container =
        event.target.parentElement.parentElement.parentElement.parentElement;
      var sidebarItem = container.getElementsByClassName("sidebar__item");
      for (let i = 0; i < sidebarItem.length; i++) {
        var titleItem = sidebarItem[i].getElementsByClassName(
          "sidebar__item-title"
        )[0];
        sidebarItem[i].classList.remove("active");
        titleItem.classList.remove("active-text");
      }

      var button = event.target.parentElement.parentElement.parentElement;
      button.classList.add("active");
      event.target.classList.add("active-text");
    },

    checkDataPermission() {
      this.myAccount = JSON.parse(localStorage.getItem("myAccount"));
      this.sidebar.forEach((item) => {
        if (this.myAccount.permisson[item.name]) {
          this.sidebarSetting.push(item);
        }
      });
    },

    getDataAccount() {
      var account = localStorage.getItem("user");
      this.account = account;

      var myAccount = localStorage.getItem("myAccount");
      this.myAccount = JSON.parse(myAccount);
    },

    handleLogout() {
      // window.localStorage.clear();
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("myAccount");
      window.localStorage.removeItem("idUser");
      window.localStorage.removeItem("role");

      if (this.$route.path !== '/') this.$router.push({ name: "website", params: { path: "/" } });
      else window.location.reload()
    },
  },
  created() {
    this.checkDataPermission();
    this.getDataAccount();
  },

  mounted() {
    this.path = "/" + this.$route.params.path;
  },
};
</script>

<style scoped lang="scss">
@import "SidebarView.scss";
</style>