@<template>
  <div
    v-if="!$route.params.id"
    class="forum-website__container relative bg-yellow-1"
  >
    <div class="forum-website__content">
      <div class="forum-website__category">
        <div
          v-if="userId && $route.path.includes('forums')"
          class="f aic jce mb1"
        >
          <div v-if="canPostForum" class="w100 post-button f jcb aic" @click="isAddNewPost = true">
            <p class="p4 px1 gray">Đăng bài viết...</p>
            <div class="f aic gap05 ph1 jce">
              <img src="@/assets/icons/image.svg" />
              <img src="@/assets/icons/text.svg" />
            </div>
          </div>
          <div v-else class="red p6">
            Bạn không thể đăng bài viết!
          </div>
        </div>
        <div class="forum-category__body f fdc gap1">
          <PostViewComponent
            v-for="(item, index) in dataPost"
            :key="index"
            :data="item"
          />
        </div>
      </div>

      <!-- Bài viết -->
      <div class="forum-website__post">
        <!-- Bài viết đã ghim -->
        <div
          class="forum-website__post-trend border border-radius shadow bg-white-1 mb1"
        >
          <div class="post-trend__header pt05 pb05 pl1 pr1 p2">
            Bài viết đã ghim
          </div>
          <div class="post-trend__body pl1 pr1">
            <div
              @click="postActive = item"
              v-for="(item, index) in postPin"
              :key="index"
              class="pt1 pb1 border-top f aic relative"
            >
              <img src="@/assets/icons/pin.svg" alt="" class="absolute" style="width: 12px; top: -0.5em; right: 0.25em; border: none;" />
              <div v-if="item.media[0]">
                <img
                  class="border-radius"
                  :src="item.media[0].file_media"
                  alt=""
                />
              </div>
              <div class="ml1 w100">
                <div class="f jcb aic mb025">
                  <div v-if="item.category[0]" class="p4 brown">
                    {{ item.category[0].category_name }}
                  </div>
                  <div class="h6 gray-1-text">
                    {{ convertTime(item.time_published) }}
                  </div>
                </div>
                <div class="two-line p5">
                  {{ item.title_post }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Bài viết mới -->
        <!-- <div
          class="forum-website__post-new border border-radius shadow bg-white-1"
        >
          <div class="post-trend__header pt05 pb05 pl1 pr1 p2">
            Bài viết mới
          </div>
          <div class="post-trend__body pl1 pr1">
            <router-link
              v-for="(item, index) in handlePostNew()"
              :key="index"
              :to="{ name: 'body-detail', params: { id: 'post-' + item.id } }"
              class="pt1 pb1 border-top f aic"
            >
              <div v-if="item.media[0]">
                <img
                  class="border-radius"
                  :src="item.media[0].file_media"
                  alt=""
                />
              </div>
              <div class="ml1 w100">
                <div class="f jcb aic mb025">
                  <div v-if="item.category[0]" class="p4 brown">
                    {{ item.category[0].category_name }}
                  </div>
                  <div class="h6 gray-1-text">
                    {{ convertTime(item.time_published) }}
                  </div>
                </div>
                <div class="two-line p5">
                  {{ item.title_post }}
                </div>
              </div>
            </router-link>
          </div>
        </div> -->
      </div>
    </div>
    <!-- Danh sách chuyên mục bài viết -->

    <template v-if="!isMobile">
      <div class="forum-bg-1">
        <img src="../../assets/forum-bg-1.png" alt="" />
      </div>
      <div class="forum-bg-2">
        <img src="../../assets/forum-bg-2.png" alt="" />
      </div>
    </template>

    <Popup :isShow="isAddNewPost" @close="isAddNewPost = fasle">
      <AddNewPost
        class="mv05"
        :dataInput="dataAddNewPost"
        :isUserCreate="true"
        :isMobile="isMobile"
        @createSuccess="onCreateSuccess"
      />
    </Popup>
    <NotiView
      v-if="notiView"
      @closePopup="notiView = false"
      :title="titleNotiview"
      :logo="logo"
    />

    <Popup :isShow="!!postActive" paddingClass="popup-show-comment" @close="postActive = null">
      <ForumPostDetail :data="postActive"/>
    </Popup>
  </div>
</template>

<script>
import AddNewPost from "@/components/AddNew/AddNewPost/AddNewPost.vue";
import NotiView from "@/components/NotiView/NotiView.vue";
import PostViewComponent from "@/components/ForumPageView/PostViewComponent.vue";
import Popup from "@/components/Popup/index.vue";
import ForumPostDetail from "@/components/ForumPageView/ForumPostDetail.vue";
import axios from "axios";
export default {
  metaInfo: {
    title: 'Forum',
    meta: [
      { property: 'og:title', content: 'Dr.Mom Phan Hồng Thu' },
      { property: 'og:description', content: 'Mẹ luôn là bác sĩ tốt nhất' }
    ]
  },

  components: {
    AddNewPost,
    Popup,
    NotiView,
    PostViewComponent,
    ForumPostDetail
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dataCategory: [],
      dataPost: [],
      postPin: [],
      dataComment: [],
      isAddNewPost: false,
      notiView: false,
      titleNotiview: "",
      logo: "",
      dataAddNewPost: {
        id: "",
        title_post: "",
        slug: "",
        permalink: "",
        time_published: "",
        content: "",
        user_created: "",
        short_description: "",
        thumbnail: "",
        thumbnail_url: "",
        status_show_post: "",
        status_post: "",
        total_click: "",
        total_share: "",
        title_seo: "",
        description_seo: "",
        keyword: "",
        media: "",
        category: "",
        tag: "",
        system_show: "",
        avatar: [],
      },

      permissionData: [],
      canPostForum: false,

      showDetailPost: false,
      postActive: null,
    };
  },

  computed: {
    userId() {
      return localStorage.getItem("idUser");
    },

    myAccount() {
      const data = localStorage.getItem("myAccount");
      return data ? JSON.parse(data) : null;
    }
  },

  methods: {
    async getUser() {
      if (this.myAccount) {
        var url = "https://api.drmom.store/";
        await axios.get(`${url}v1/api/customer/${this.myAccount.id}/`).then((res) => {
          const data = res.data
          this.permissionData = data.permisson
          this.canPostForum = !!this.permissionData?.can_post_forum
        });
      }
    },

    convertTime(value) {
      var time = value.split("T");
      return time[0];
    },

    handlePostNew() {
      var data = [...this.dataPost];
      var dataPostNew = data.splice(0, 5);
      return dataPostNew;
    },

    handlePostPin() {
      var data = [...this.dataPost];

      var dataPost = data.filter(e => e.pin)

      this.postPin = dataPost;
    },

    handlePostCategory(value) {
      var count = this.dataPost.filter(
        (item) => item.category[0]?.id === value
      );
      if (count.length !== 0) {
        return count[0];
      } else {
        return [];
      }
    },

    handleCountPost(value) {
      var count = this.dataPost.filter(
        (item) => item.category[0]?.id === value
      );
      return count.length;
    },

    async getData() {
      var url = "https://api.drmom.store/";
      await axios.get(url + "v1/api/post/" + "?depth=1").then((res) => {
        var data = res.data.results;
        if (this.$route.params.path === "forums") {
          this.dataPost = data.filter(
            (item) =>
              item.system_show[0]?.id == 1 &&
              item.approved_status === "approved"
          );
        } else {
          this.dataPost = data.filter(
            (item) =>
              item.system_show[0]?.id == 2 &&
              item.approved_status === "approved"
          );
        }
      });

      await axios.get(url + "v1/api/categorypost/").then((res) => {
        this.dataCategory = res.data.results;
      });

      await axios
        .get("https://api.drmom.store/v1/api/commentpost/?depth=1")
        .then((res) => {
          this.dataComment = res.data.results;
          this.dataComment = this.dataComment.filter((x) => x.user_customer);
        });

      this.dataPost = this.dataPost.map((e) => ({
        ...e,
        dataComment: this.dataComment.filter((x) => x.post.id === e.id),
      }));

      this.handlePostPin();
    },

    onCreateSuccess({ isError }) {
      this.isAddNewPost = false;
      this.notiView = true;
      this.titleNotiview = isError
        ? "Tạo bài viết thất bại"
        : "Tạo bài viết thành công. Đang chờ admin duyệt bài";
      this.logo = isError ? "noti-warning" : "noti-success";
    },
  },

  created() {
    this.getData();
    this.getUser();
  },
};
</script>

<style scoped lang='scss'>
@import "ForumPageView.scss";
</style>

<style lang='scss'>
.layout-landing + .forums__container {
  height: auto !important;
}
</style>