@<template>
  <div class="landing__container">
    <HeaderLanding :isMobile="isMobile()" class="fixed z10" />

    <!-- webdite -->
    <div v-if="!isMobile()" class="intro__container relative">
      <div class="intro__bg absolute z1"></div>

      <div class="intro__img-human absolute z2">
        <img
          class="img-human absolute z2"
          loading="lazy"
          src="./imgLanding/intro_img_4.png"
          alt=""
        />
        <img
          class="human__bg absolute z1"
          loading="lazy"
          src="./imgLanding/intro_img_5.jpg"
          alt=""
        />
      </div>

      <div class="intro__title absolute f fdc jcc z3">
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="500"
          class="mb05"
        >
          <div class="intro__title-header mb05 brown">Dr.Mom Phan Hồng Thu</div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="1000"
          class="mb1"
        >
          <div class="intro__title-body">
            Mẹ luôn là bác sĩ <span class="brown">tốt nhất</span>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="1500"
          class="intro__title-foot gray-1-text"
        >
          Chúng tôi luôn hoàn toàn tập trung vào việc giúp đỡ mẹ và bé
        </div>
      </div>

      <div class="intro__cart absolute f jcc aic z3">
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          :data-aos-delay="300 * (index + 1)"
          v-for="(item, index) in dataCart"
          :key="index"
          class="intro__item pt15 f fdc aic pb15 pl1 pr1 border-radius bg-white-1"
        >
          <div class="intro__item-img mb1 f jcc aic bg-gray-2">
            <svg
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 13.5C14.2031 13.5 17.25 10.5 17.25 6.75C17.25 3.04688 14.2031 0 10.5 0C6.75 0 3.75 3.04688 3.75 6.75C3.75 10.5 6.75 13.5 10.5 13.5ZM10.5 2.25C12.9375 2.25 15 4.3125 15 6.75C15 9.23438 12.9375 11.25 10.5 11.25C8.01562 11.25 6 9.23438 6 6.75C6 4.3125 8.01562 2.25 10.5 2.25ZM14.7656 14.2969L12.1406 21.5156L11.25 17.625L12.75 15H8.25L9.75 17.625L8.8125 21.5156L6.1875 14.2969C2.71875 14.3438 0 17.1094 0 20.5781V21.75C0 23.0156 0.984375 24 2.25 24H18.75C19.9688 24 21 23.0156 21 21.75V20.5781C21 17.1094 18.2344 14.3438 14.7656 14.2969ZM4.5 21.75H2.25V20.5781C2.25 18.8906 3.23438 17.4844 4.73438 16.8281L6.51562 21.75H4.5ZM18.75 21.75H14.4375L16.2656 16.8281C17.7188 17.4844 18.75 18.8906 18.75 20.5781V21.75Z"
                fill="#757575"
              />
            </svg>
          </div>
          <div class="intro__item-title h3 brown mb025">
            {{ item.title }}
          </div>
          <div class="intro__item-text p6 align-c gray">
            {{ item.text }}
          </div>
        </div>
      </div>

      <img
        class="img__bg-1 absolute"
        loading="lazy"
        src="./imgLanding/intro_img_1.png"
        alt=""
      />
      <img
        class="img__bg-2 absolute"
        loading="lazy"
        src="./imgLanding/intro_img_2.jpg"
        alt=""
      />
      <img
        class="img__bg-3 absolute"
        loading="lazy"
        src="./imgLanding/intro_img_3.jpg"
        alt=""
      />
    </div>

    <!-- mobile -->
    <!-- head content -->
    <div v-if="isMobile()" class="intro__container relative">
      <div class="intro__bg absolute z1"></div>

      <div class="img-human__container f jcc absolute">
        <img
          class="img-human absolute z2"
          loading="lazy"
          src="./imgLanding/intro_img_4.png"
          alt=""
        />
      </div>

      <div class="intro__title absolute f fdc jcc aic z3">
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="500"
          class="mb05"
        >
          <div class="intro__title-header p6 mb05 brown">
            Dr.Mom Phan Hồng Thu
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="1000"
          class="mb1"
        >
          <div class="intro__title-body">
            Mẹ luôn là bác sĩ <span class="brown">tốt nhất</span>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="1500"
          class="intro__title-foot gray-1-text p6"
        >
          Chúng tôi luôn hoàn toàn tập trung vào việc giúp đỡ mẹ và bé
        </div>
      </div>

      <div class="intro__flickity absolute w100 z3">
        <Flickity ref="flickity" :options="flickityOptions">
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            :data-aos-delay="300 * (index + 1)"
            v-for="(item, index) in dataCart"
            :key="index"
            class="carousel-cell px05"
          >
            <div
              class="intro__item f fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1"
              style="height: 70%"
            >
              <div class="intro__item-img mb1 f jcc aic bg-gray-2">
                <svg
                  width="21"
                  height="24"
                  viewBox="0 0 21 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 13.5C14.2031 13.5 17.25 10.5 17.25 6.75C17.25 3.04688 14.2031 0 10.5 0C6.75 0 3.75 3.04688 3.75 6.75C3.75 10.5 6.75 13.5 10.5 13.5ZM10.5 2.25C12.9375 2.25 15 4.3125 15 6.75C15 9.23438 12.9375 11.25 10.5 11.25C8.01562 11.25 6 9.23438 6 6.75C6 4.3125 8.01562 2.25 10.5 2.25ZM14.7656 14.2969L12.1406 21.5156L11.25 17.625L12.75 15H8.25L9.75 17.625L8.8125 21.5156L6.1875 14.2969C2.71875 14.3438 0 17.1094 0 20.5781V21.75C0 23.0156 0.984375 24 2.25 24H18.75C19.9688 24 21 23.0156 21 21.75V20.5781C21 17.1094 18.2344 14.3438 14.7656 14.2969ZM4.5 21.75H2.25V20.5781C2.25 18.8906 3.23438 17.4844 4.73438 16.8281L6.51562 21.75H4.5ZM18.75 21.75H14.4375L16.2656 16.8281C17.7188 17.4844 18.75 18.8906 18.75 20.5781V21.75Z"
                    fill="#757575"
                  />
                </svg>
              </div>
              <div class="intro__item-title h3 brown mb025">
                {{ item.title }}
              </div>
              <div class="intro__item-text p6 align-c gray">
                {{ item.text }}
              </div>
            </div>
          </div>
        </Flickity>
      </div>

      <img
        class="img__bg-1 absolute"
        src="./imgLanding/intro_img_1.png"
        alt=""
        loading="lazy"
      />
      <img
        class="img__bg-2 absolute"
        src="./imgLanding/intro_img_2.jpg"
        alt=""
        loading="lazy"
      />
      <img
        class="img__bg-3 absolute"
        src="./imgLanding/intro_img_3.jpg"
        alt=""
        loading="lazy"
      />
    </div>

    <!-- responsive -->
    <!-- Post -->
    <div class="welcome__container f fdc aic z4">
      <div
        data-aos="zoom-in"
        data-aos-duration="1500"
        class="welcome__header w100 f fdc aic mb15"
      >
        <div class="welcome__header-title mb05">
          <div class="price-large brown">Chào mừng đến với</div>
        </div>
        <div class="welcome__header-text">
          <div class="h1 align-c">Dr.Mom - Mẹ luôn là bác sĩ tốt nhất</div>
        </div>
      </div>

      <div v-if="!isMobile()" class="welcome__list mb2">
        <div class="welcome__item">
          <div
            v-if="dataPost[0]"
            data-aos="fade-down-right"
            data-aos-duration="1000"
          >
            <div class="relative item__container">
              <img
                :src="dataPost[0].media[0].file_media"
                alt=""
                loading="lazy"
                width="370"
                height="550"
              />
              <div
                data-aos="fade-down-up"
                data-aos-duration="1000"
                class="item__title absolute px05"
              >
                <router-link
                  :to="{
                    name: 'body-detail',
                    params: {
                      path: 'post-website',
                      id: 'post-' + dataPost[0].id,
                    },
                  }"
                >
                  <span class="white bold h4">{{
                    dataPost[0].title_post
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else data-aos="fade-down-right" data-aos-duration="1000">
            <img src="./imgLanding/welcome_img_1.png" loading="lazy" alt="" />
          </div>
          <div
            v-if="dataPost[1]"
            data-aos="fade-down-right"
            data-aos-duration="1000"
          >
            <div class="relative item__container">
              <img
              loading="lazy"
                :src="dataPost[1].media[0].file_media"
                alt=""
                width="370"
                height="250"
              />
              <div
                data-aos="fade-down-up"
                data-aos-duration="1000"
                class="item__title absolute px05"
              >
                <router-link
                  :to="{
                    name: 'body-detail',
                    params: {
                      path: 'post-website',
                      id: 'post-' + dataPost[1].id,
                    },
                  }"
                >
                  <span class="white bold h4">{{
                    dataPost[1].title_post
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else data-aos="fade-down-right" data-aos-duration="1000">
            <img
              src="./imgLanding/welcome_img_2.png"
              loading="lazy"
              alt=""
              width="370"
              height="250"
            />
          </div>
        </div>
        <div class="welcome__item">
          <div v-if="dataPost[2]" data-aos="fade-down" data-aos-duration="1000">
            <div class="relative item__container">
              <img
                :src="dataPost[2].media[0].file_media"
                loading="lazy"
                alt=""
                width="370"
                height="250"
              />
              <div
                data-aos="fade-down-up"
                data-aos-duration="1000"
                class="item__title absolute px05"
              >
                <router-link
                  :to="{
                    name: 'body-detail',
                    params: {
                      path: 'post-website',
                      id: 'post-' + dataPost[2].id,
                    },
                  }"
                >
                  <span class="white bold h4">{{
                    dataPost[2].title_post
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else data-aos="fade-down" data-aos-duration="1000">
            <img
              src="./imgLanding/welcome_img_3.png"
              loading="lazy"
              alt=""
              width="370"
              height="250"
            />
          </div>

          <div v-if="dataPost[3]" data-aos="fade-up" data-aos-duration="1000">
            <div class="relative item__container">
              <img
                :src="dataPost[3].media[0].file_media"
                loading="lazy"
                alt=""
                width="370"
                height="550"
              />
              <div
                data-aos="fade-down-up"
                data-aos-duration="1000"
                class="item__title absolute px05"
              >
                <router-link
                  :to="{
                    name: 'body-detail',
                    params: {
                      path: 'post-website',
                      id: 'post-' + dataPost[3].id,
                    },
                  }"
                >
                  <span class="white bold h4">{{
                    dataPost[3].title_post
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else data-aos="fade-up" data-aos-duration="1000">
            <img
              src="./imgLanding/welcome_img_4.png"
              loading="lazy"
              alt=""
              width="370"
              height="550"
            />
          </div>
        </div>
        <div class="welcome__item">
          <div
            v-if="dataPost[4]"
            data-aos="fade-down-left"
            data-aos-duration="1000"
          >
            <div class="relative item__container">
              <img
                :src="dataPost[4].media[0].file_media"
                loading="lazy"
                alt=""
                width="370"
                height="550"
              />
              <div
                data-aos="fade-down-up"
                data-aos-duration="1000"
                class="item__title absolute px05"
              >
                <router-link
                  :to="{
                    name: 'body-detail',
                    params: {
                      path: 'post-website',
                      id: 'post-' + dataPost[4].id,
                    },
                  }"
                >
                  <span class="white bold h4">{{
                    dataPost[4].title_post
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else data-aos="fade-down-left" data-aos-duration="1000">
            <img src="./imgLanding/welcome_img_5.png" loading="lazy" alt="" />
          </div>
          <div
            v-if="dataPost[5]"
            data-aos="fade-down-left"
            data-aos-duration="1000"
          >
            <div class="relative item__container">
              <img
                :src="dataPost[5].media[0].file_media"
                loading="lazy"
                alt=""
                width="370"
                height="250"
              />
              <div
                data-aos="fade-down-up"
                data-aos-duration="1000"
                class="item__title absolute px05"
              >
                <router-link
                  :to="{
                    name: 'body-detail',
                    params: {
                      path: 'post-website',
                      id: 'post-' + dataPost[5].id,
                    },
                  }"
                >
                  <span class="white bold h4">{{
                    dataPost[5].title_post
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div v-else data-aos="fade-down-left" data-aos-duration="1000">
            <img src="./imgLanding/welcome_img_6.png" alt="" loading="lazy" />
          </div>
        </div>
      </div>

      <div
        data-aos="zoom-in"
        data-aos-duration="1000"
        class="welcome__title mt05 mb2"
      >
        <div class="h4 align-c">
          Trong gần 10 năm vừa qua Thu đã giúp hàng nghìn em bé khỏe mạnh, tránh
          được hệ lụy từ kháng sinh, thuốc tây, phương pháp điều trị sai cách,
          hàng nghìn mẹ được nuôi con trong bình an và hạnh phúc, làm chủ việc
          chăm sóc sức khỏe cho con tại nhà! Tôi là chuyên gia Nhi Khoa, chuyên
          sâu về miễn dịch và hô hấp Nhi.
        </div>
      </div>
      <router-link
        to="/post-website"
        data-aos="zoom-in"
        data-aos-duration="1000"
        class="mt05 pt1 pb1 pl2 pr2 align-c border-radius bg-blue white cursor"
      >
        Xem thêm
      </router-link>
    </div>

    <!-- responsive -->
    <!-- About us -->
    <div class="about__container relative f jcc aic">
      <div
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="500"
        class="about__img mr5"
      >
        <img src="./imgLanding/about_img_1.png" loading="lazy" alt="" />
      </div>
      <div class="about__content f fdc ais">
        <div
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-easing="linear"
          data-aos-delay="500"
          class="mb05"
        >
          <div class="price-large brown">Về tôi</div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-easing="linear"
          data-aos-delay="700"
          class="mb1"
        >
          <div class="p-large">Dr.Mom Phan Hồng Thu</div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-easing="linear"
          data-aos-delay="900"
          class="mb15"
        >
          <div class="h4 brown">
            Tôi là chuyên gia Nhi Khoa, chuyên sâu về miễn dịch và hô hấp Nhi
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-easing="linear"
          data-aos-delay="1100"
          class="mt05 h7 pt1 pb1 pl2 pr2 align-c border-radius bg-blue white cursor"
        >
          Xem thêm về tôi
        </div>
      </div>

      <div v-if="!isMobile()" class="about__bg absolute">
        <img src="./imgLanding/about_img_2.png" loading="lazy" alt="" />
      </div>
    </div>

    <!-- responsive -->
    <!-- Kiến thức cho mẹ -->
    <div class="couse__container f fdc aic z4">
      <div
        data-aos="zoom-in-up"
        data-aos-duration="500"
        data-aos-easing="linear"
        data-aos-delay="500"
        class="couse__header w100 f fdc aic mb2"
      >
        <div class="couse__header-title mb05">
          <div class="price-large brown">Kiến thức cho mẹ</div>
        </div>
        <div class="couse__header-text">
          <div class="p-large">Khóa học của Thu</div>
        </div>
      </div>
      <div
        class="couse__container-flickity"
        style="width: calc((368px + 2em) * 3); height: 45vh"
      >
        <Flickity ref="flickity" :options="flickityOptions">
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_1.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">
                  Miễn dịch khỏe cả đời yên vui
                </div>
                <div class="course__item-description h5 gray">
                  Khóa học giúp bạn biết cách làm thế nào rèn luyện cho con một
                  hệ miễn dịch thượng thừa
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_2.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">
                  Tiêu hóa khỏe - Mẹ an nhiên
                </div>
                <div class="course__item-description h5 gray">
                  Khóa học này giúp bạn có được kiến thức để làm thế nào giúp
                  con bạn thông minh hơn.
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_3.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">Ngủ ngoan bé yêu</div>
                <div class="course__item-description h5 gray">
                  Giúp bạn biết được phương pháp cải thiện chất lượng giấc ngủ,
                  giúp trẻ ngủ sâu và đi vào giấc.
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_1.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">
                  Miễn dịch khỏe cả đời yên vui
                </div>
                <div class="course__item-description h5 gray">
                  Khóa học giúp bạn biết cách làm thế nào rèn luyện cho con một
                  hệ miễn dịch thượng thừa
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_2.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">
                  Tiêu hóa khỏe - Mẹ an nhiên
                </div>
                <div class="course__item-description h5 gray">
                  Khóa học này giúp bạn có được kiến thức để làm thế nào giúp
                  con bạn thông minh hơn.
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_3.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">Ngủ ngoan bé yêu</div>
                <div class="course__item-description h5 gray">
                  Giúp bạn biết được phương pháp cải thiện chất lượng giấc ngủ,
                  giúp trẻ ngủ sâu và đi vào giấc.
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_1.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">
                  Miễn dịch khỏe cả đời yên vui
                </div>
                <div class="course__item-description h5 gray">
                  Khóa học giúp bạn biết cách làm thế nào rèn luyện cho con một
                  hệ miễn dịch thượng thừa
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_2.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">
                  Tiêu hóa khỏe - Mẹ an nhiên
                </div>
                <div class="course__item-description h5 gray">
                  Khóa học này giúp bạn có được kiến thức để làm thế nào giúp
                  con bạn thông minh hơn.
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-cell">
            <div class="course__item border-radius bg-white-1">
              <div class="course__item-img">
                <img src="./imgLanding/course_img_3.png" loading="lazy" alt="" />
              </div>
              <div class="course__item-body f fdc ais pt2 pb2 pl1 pr1">
                <div class="course__item-title h3 mb05">Ngủ ngoan bé yêu</div>
                <div class="course__item-description h5 gray">
                  Giúp bạn biết được phương pháp cải thiện chất lượng giấc ngủ,
                  giúp trẻ ngủ sâu và đi vào giấc.
                </div>
              </div>
            </div>
          </div>
        </Flickity>
      </div>
    </div>

    <!-- responsive -->
    <!-- Rate -->
    <div class="review__container relative z1">
      <div class="review__container-bg-1 absolute z6">
        <img src="./imgLanding/about_img_2.png" loading="lazy" alt="" />
      </div>
      <div class="absolute w100 f fdc aic z5">
        <div
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="300"
          class="review__header w100 f fdc aic mb2 z5"
        >
          <div class="review__header-title mb05">
            <div class="price-large brown">Cảm nhận của khách hàng</div>
          </div>
          <div class="review__header-text">
            <div class="p-large">Về Dr.Mom</div>
          </div>
        </div>
        <div
          class="review__flickity z10"
          style="width: calc((368px + 2em) * 3); height: 45vh"
        >
          <Flickity ref="flickity" :options="flickityOptionsReviews">
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Được biết đến DrMom sớm ngày nào con khỏe ngày đó, hạn chế
                    được bao nhiêu bệnh kháng sinh
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Lê Thị Linh</div>
                    </div>
                    <div class="p6">Nhân viên bán hàng</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Được biết đến DrMom sớm ngày nào con khỏe ngày đó, hạn chế
                    được bao nhiêu bệnh kháng sinh
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Nguyễn Vân Anh</div>
                    </div>
                    <div class="p6">Mẹ bỉm sữa</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Được biết đến DrMom sớm ngày nào con khỏe ngày đó, hạn chế
                    được bao nhiêu bệnh kháng sinh
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Trần Thùy Chi</div>
                    </div>
                    <div class="p6">Nhân viên văn phòng</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Jane Cooper</div>
                    </div>
                    <div class="p6">CEO at ABC Corporation</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Jane Cooper</div>
                    </div>
                    <div class="p6">CEO at ABC Corporation</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Jane Cooper</div>
                    </div>
                    <div class="p6">CEO at ABC Corporation</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Jane Cooper</div>
                    </div>
                    <div class="p6">CEO at ABC Corporation</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Jane Cooper</div>
                    </div>
                    <div class="p6">CEO at ABC Corporation</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-cell">
              <div class="review__item">
                <div
                  class="review__item-comment relative pt2 pb2 pl15 pr15 mb2 border-radius bg-white-1"
                >
                  <div class="comment__title h2">Cảm ơn DrMom</div>
                  <div class="comment__description h5 gray-1-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                  </div>
                  <div class="comment__triangle absolute"></div>
                </div>
                <div class="review__item-info f aic">
                  <img src="./imgLanding/review_img_avatar.png" loading="lazy" alt="" />
                  <div class="f fdc ais ml1">
                    <div class="mb025">
                      <div class="h5">Jane Cooper</div>
                    </div>
                    <div class="p6">CEO at ABC Corporation</div>
                  </div>
                </div>
              </div>
            </div>
          </Flickity>
        </div>
      </div>
      <div class="review__container-bg absolute z1 w100"></div>
    </div>

    <!-- responsive -->
    <!-- Post -->
    <div class="religon__container w100 f fdc aic">
      <div class="religon__header w100 f fdc aic mb5">
        <div class="religon__header-title mb05">
          <div class="price-large brown">Đạo và đời</div>
        </div>
        <div class="religon__header-text">
          <div class="p-large">Kiến thức hữu ích cho bạn</div>
        </div>
      </div>
      <div class="religon__body w100 f fdc aic">
        <div
          v-if="!isMobile() && dataPost.length > 0"
          class="religon__list f aic mb5"
        >
          <div
            class="religon__item border-radius bg-white-1"
            v-for="item in dataPost.slice(0, 3)"
            :key="item.id"
          >
            <router-link
              :to="{
                name: 'body-detail',
                params: { path: 'post-website', id: 'post-' + item.id },
              }"
            >
              <div class="religon__item-img">
                <img class="item-img" :src="item.media[0].file_media" loading="lazy" alt="" />
              </div>
              <div class="religon__item-body pt2 pb2 pl1 pr1">
                <div class="h4 mb05 item__title" v-html="item.title_post"></div>
                <div
                  class="h5 gray-1-text item__content"
                  v-html="item.content"
                ></div>
              </div>
            </router-link>
          </div>
          <!-- <div class="religon__item border-radius bg-white-1">
                        <div class="religon__item-img">
                            <img src="./imgLanding/religon_img_2.png" alt="">
                        </div>
                        <div class="religon__item-body pt2 pb2 pl1 pr1">
                            <div class="h4 mb05">
                                Tiêu hóa khỏe - Mẹ an nhiên
                            </div>
                            <div class="h5 gray-1-text">
                                Khóa học này giúp bạn có được kiến thức để làm thế nào giúp con bạn thông minh hơn
                            </div>
                        </div>
                    </div>
                    <div class="religon__item border-radius bg-white-1">
                        <div class="religon__item-img">
                            <img src="./imgLanding/religon_img_3.png" alt="">
                        </div>
                        <div class="religon__item-body pt2 pb2 pl1 pr1">
                            <div class="h4 mb05">
                                Ngủ ngoan bé yêu
                            </div>
                            <div class="h5 gray-1-text">
                                Giúp bạn biết được phương pháp cải thiện chất lượng giấc ngủ, giúp trẻ ngủ sâu
                            </div>
                        </div>
                    </div> -->
        </div>
        <div v-else-if="dataPost.length > 0" class="religon__body-flickity">
          <Flickity
            ref="flickity"
            :options="flickityPost"
            v-if="dataPost.length > 0"
          >
            <div>
              <div class="carousel-cell">
                <div class="religon__item border-radius bg-white-1">
                  <div class="religon__item-img">
                    <img
                      class="w100"
                      loading="lazy"
                      src="./imgLanding/religon_img_1.png"
                      alt=""
                    />
                  </div>
                  <div class="religon__item-body pt2 pb2 pl1 pr1">
                    <div class="h4 mb05">Miễn dịch khỏe cả đời yên vui</div>
                    <div class="h5 gray-1-text">
                      Khóa học giúp bạn biết cách làm thế nào rèn luyện cho con
                      một hệ miễn dịch thượng thừa
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="carousel-cell">
                <div class="religon__item border-radius bg-white-1">
                  <div class="religon__item-img">
                    <img
                      class="w100"
                      src="./imgLanding/religon_img_2.png"
                      alt=""
                    />
                  </div>
                  <div class="religon__item-body pt2 pb2 pl1 pr1">
                    <div class="h4 mb05">Tiêu hóa khỏe - Mẹ an nhiên</div>
                    <div class="h5 gray-1-text">
                      Khóa học này giúp bạn có được kiến thức để làm thế nào
                      giúp con bạn thông minh hơn
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-cell">
                <div class="religon__item border-radius bg-white-1">
                  <div class="religon__item-img">
                    <img
                      class="w100"
                      src="./imgLanding/religon_img_3.png"
                      alt=""
                    />
                  </div>
                  <div class="religon__item-body pt2 pb2 pl1 pr1">
                    <div class="h4 mb05">Ngủ ngoan bé yêu</div>
                    <div class="h5 gray-1-text">
                      Giúp bạn biết được phương pháp cải thiện chất lượng giấc
                      ngủ, giúp trẻ ngủ sâu
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </Flickity>
        </div>
        <router-link to="/post-website" class="religon__btn">
          <div
            class="mt05 p6 pt1 pb1 pl2 pr2 align-c border-radius bg-blue white cursor"
          >
            Xem thêm bài viết
          </div>
        </router-link>
      </div>
    </div>

    <!-- Diễn đàn -->
    <div class="family__container relative w100">
      <div class="family__container-bg absolute w100"></div>

      <div class="family__container-bg-1 absolute">
        <img src="./imgLanding/family_img_bg-1.png" loading="lazy" alt="" />
      </div>

      <div class="family__body absolute w100 f jcc aic">
        <div class="family__content mr5">
          <div class="religon__header w100 f fdc ais mb5">
            <div class="religon__header-title mb05">
              <div class="price-large brown">DrMom Family</div>
            </div>
            <div class="religon__header-text">
              <div class="p-large">
                Nơi giao lưu và chia sẻ <br />
                kinh nghiệm của các mẹ
              </div>
            </div>
            <div
              class="mt05 h4 pt1 pb1 pl2 pr2 align-c border-radius bg-blue white cursor"
            >
              Tham gia diễn đàn
            </div>
          </div>
        </div>
        <div v-if="!isMobile()" class="family__img ml5">
          <div class="img-cells">
            <div>
              <img
                data-aos="fade-down-right"
                data-aos-duration="500"
                data-aos-delay="300"
                src="./imgLanding/family_img_1.png"
                alt=""
                loading="lazy"
              />
            </div>
            <div>
              <img
                data-aos="fade-up-right"
                data-aos-duration="500"
                data-aos-delay="300"
                src="./imgLanding/family_img_2.png"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div class="img-cells">
            <div
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <img src="./imgLanding/family_img_3.png" loading="lazy" alt="" />
            </div>
            <div>
              <img
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="300"
                src="./imgLanding/family_img_4.png"
                alt=""
                loading="lazy"
              />
              <img
                data-aos="fade-left"
                data-aos-duration="500"
                data-aos-delay="300"
                src="./imgLanding/family_img_5.png"
                alt=""
                loading="lazy"
              />
            </div>
            <div
              data-aos="fade-down"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <img src="./imgLanding/family_img_6.png" loading="lazy" alt="" />
            </div>
          </div>
          <div class="img-cells">
            <div
              data-aos="fade-down-left"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <img src="./imgLanding/family_img_7.png" alt="" loading="lazy" />
            </div>
            <div
              data-aos="fade-up-left"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <img src="./imgLanding/family_img_8.png" alt="" loading="lazy" />
            </div>
          </div>
        </div>

        <div v-else class="family__flickity">
          <Flickity ref="flickity" :options="flickityOptionsReviews">
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-down-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_1.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_2.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_3.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_4.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_5.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_6.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_7.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="carousel-cell">
              <div>
                <img
                  data-aos="fade-up-right"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  src="./imgLanding/family_img_8.png"
                  alt=""
                  style="object-fit: cover"
                  loading="lazy"
                />
              </div>
            </div>
          </Flickity>
        </div>
      </div>
    </div>

    <!-- footer content -->
    <div class="contact__container relative f jcc aic">
      <div class="contact__body absolute f jcc aic z3">
        <div class="contact__video">
          <iframe
            class="border-radius"
            width="100%"
            height="321px"
            controls
            src="https://www.youtube.com/embed/tgbNymZ7vqY"
          >
          </iframe>
        </div>
        <div class="contact__form">
          <div class="religon__header-title mb05">
            <div class="price-large brown">Đăng ký nhận tư vấn miễn phí</div>
          </div>
          <div class="religon__header-text mb15">
            <div class="p-large">
              Và cơ hội nhận ngay khóa <br />
              học xử lí ho tại nhà miễn phí
            </div>
          </div>
          <div class="contact__form-input w100 mb1">
            <input
              v-model="emailcourse"
              class="w100 t1 pb1 align-c border-radius"
              placeholder="Nhập Email của bạn vào đây"
              type="text"
            />
          </div>
          <div
            @click="handleSendEmail"
            class="contact__submit border-radius align-c cursor"
          >
            <div class="p1 white">ĐĂNG KÝ NHẬN NGAY KHÓA HỌC</div>
          </div>
        </div>
      </div>

      <div class="contact__img-1 absolute z2">
        <img src="./imgLanding/contact_img_1.png" alt="" loading="lazy" />
      </div>
      <div class="contact__img-2 absolute z2">
        <img src="./imgLanding/contact_img_1.png" alt="" loading="lazy" />
      </div>
      <div class="contact__img-3 absolute z2">
        <img src="./imgLanding/contact_img_2.png" alt="" loading="lazy" />
      </div>
      <div class="contact__img-4 absolute z2">
        <img src="./imgLanding/contact_img_3.png" alt="" loading="lazy" />
      </div>
    </div>

    <FooterView />

    <NotiView
      v-if="notiView"
      :title="title"
      :logo="logo"
      @closePopup="closeNoti"
    />
  </div>
</template>

<script>
import HeaderLanding from "./HeaderLanding/HeaderLanding.vue";
import Flickity from "../../../node_modules/vue-flickity/src/flickity.vue";
import FooterView from "@/components/FooterSection/FooterSection.vue";
import NotiView from "@/components/NotiView/NotiView.vue";
import axios from "axios";

export default {
  components: {
    HeaderLanding,
    Flickity,
    FooterView,
    NotiView,
  },

  data() {
    return {
      notiView: false,
      title: "",
      logo: "",

      emailcourse: "",

      dataCart: [
        {
          title: "Truy vấn sức khỏe",
          text: "Kiểm tra, chăm sóc tận tâm cho bé để phát hiện sớm các loại bệnh",
        },
        {
          title: "Tư vấn tận tình",
          text: "Dr Mom luôn có mặt để giúp đỡ và tư vấn cho các mẹ đang cần trợ giúp",
        },
        {
          title: "Làm chủ tự chăm sóc bé",
          text: "Khóa học giúp bạn có kiến thức chuyên sâu về miễn dịch",
        },
      ],
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
        groupCells: true,
        autoPlay: 2000,
        arrowShape:
          "M 0,50 L 45,00 L 45,15 L 20,45 L 80,45 L 80,55 L 20,55 L 45,85 L 45,100 Z",
      },
      flickityOptionsReviews: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        groupCells: true,
        autoPlay: 3000,
      },

      flickityPost: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        groupCells: true,
        autoPlay: 3000,
      },

      dataPost: [],
    };
  },

  methods: {
    closeNoti() {
      this.notiView = false;
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    handleSendEmail() {
      const data = JSON.stringify({
        email: this.emailcourse,
      });

      axios
        .post("https://api.drmom.store/v1/api/emailcourse/", data)
        .then(() => {
          this.title = "Đăng ký nhận khoá học thành công";
          this.logo = "noti-success";
          this.notiView = true;
        });
    },

    getPost() {
      axios
        .get("https://api.drmom.store/v1/api/post/"  + '?depth=1')
        .then((res) => {
          if (res) {
            const results = res.data.results.filter(
              (item) =>
                item.system_show[0]?.id == "2" &&
                item?.approved_status === "approved"
            );
            // get 3 lastest posts
            results.forEach((item, index) => {
              if (this.dataPost.length < 6) {
                if (index < 3) {
                  this.dataPost.push(item);
                  results.splice(index, 1);
                }
              }
            });
            // get 3 trending posts
            for (let i = 0; i < 3; i++) {
              if (this.dataPost.length < 6 && results?.length) {
                const maxElement = results.reduce((accumulator, currentValue) =>
                  accumulator.total_click > currentValue.total_click
                    ? accumulator
                    : currentValue
                );
                this.dataPost.push(maxElement);
                const indexMax = results.findIndex(
                  (element) => element.id === maxElement.id
                );
                results.splice(indexMax, 1);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.getPost();
  },
};
</script>

<style scoped lang='scss'>
@import "LandingPage.scss";
</style>