<template>
  <div class="popup popup-auth auth f aic jcc w100 h100vh">
    <div
      v-if="statusForm === 'login'"
      class="login relative f fdc jcb pt2 pb1 pl1 pr1 border-radius bg-white-1"
    >
      <div
        v-if="isLogin"
        class="login-loading absolute z2 w100 h100"
      >
        <IsLoading />
      </div>
      <div>
        <svg
          @click="handleClose"
          class="absolute cursor"
          style="top: 2em; right: 1em"
          viewBox="0 0 18 16"
          width="18"
          height="16"
        >
          <use
            xlink="http://www.w3.org/2000/svg"
            href="#close"
            x="0"
            y="0"
          ></use>
        </svg>
        <div class="align-c mt2 mb2">
          <img
            src="../../assets/LogoDrMom.png"
            alt=""
          >
        </div>
        <div class="title h2 w100 mb1">Đăng nhập</div>
        <form
          action=""
          class="login__form"
        >
          <div class="f fdc mb1">
            <label
              class="h7 mb05"
              for=""
            >Tên đăng nhập</label>
            <input
              class="h6"
              type="text"
              placeholder="Nhập số điện thoại vào đây"
              v-model="username"
            >
          </div>
          <div class="f fdc mb1 relative form__password">
            <label
              class="h7 mb05"
              for=""
            >Mật khẩu</label>
            <input
              class="h6"
              :type="isShowPassWord ? 'text' : 'password' "
              placeholder="Nhập mật khẩu vào đây"
              ref="inputPassword"
              v-model="password"
            >
            <div
              class="absolute password__icon"
              @click="changeShowPassWord"
            >
              <img
                v-if="!isShowPassWord"
                src="../../assets/show-password.svg"
                alt=""
              >
              <img
                v-else
                src="../../assets/hide-password.svg"
                alt=""
              >
            </div>

          </div>
          <div class="f jcb aic mb4">
            <div class="f aic">
              <input
                type="checkbox"
                name=""
                id=""
              >
              <p class="ml05 h6">Nhớ tài khoản</p>
            </div>
            <div
              class="cursor"
              style="text-decoration-line:underline"
              @click="statusForm = 'forgotpassword'"
            >
              <p class="h6 gray">Quên mật khẩu</p>
            </div>
          </div>
          <button
            class="w100 h5 pt075 pb075 align-c border-radius bg-brown white"
            style="margin-top: 3em "
            @click="login"
          >Đăng nhập</button>
        </form>
      </div>
      <div class="w100 align-c mt15">
        <span class="gray h5">Chưa có tài khoản?</span>
        <span
          @click="statusForm = 'register'"
          class="cursor blue h5"
        >Đăng ký ngay</span>
      </div>
    </div>

    <div
      v-else-if="statusForm === 'register'"
      class="register relative f fdc jcb pt2 pb1 pl1 pr1 border-radius bg-white-1"
    >
      <div>
        <svg
          @click="handleClose"
          class="absolute cursor"
          style="top: 2em; right: 1em"
          viewBox="0 0 18 16"
          width="18"
          height="16"
        >
          <use
            xlink="http://www.w3.org/2000/svg"
            href="#close"
            x="0"
            y="0"
          ></use>
        </svg>
        <div class="align-c mt2 mb2">
          <img
            src="../../assets/LogoDrMom.png"
            alt=""
          >
        </div>
        <div class="title h2 w100 mb1">Đăng ký</div>
        <form action="">
          <div class="f fdc mb1">
            <label
              class="h7 mb05"
              for=""
            >Họ và tên</label>
            <input
              class="h6"
              type="text"
              placeholder="Nhập họ và tên"
              v-model="dataInput.full_name"
            >
          </div>
          <div class="grid-two w100 f jcb aic mb1">
            <div class="f fdc">
              <label
                class="h7 mb05"
                for=""
              >Số điện thoại</label>
              <input
                class="w100 h6"
                type="text"
                placeholder="Nhập số điện thoại"
                v-model="dataInput.phone"
              >
            </div>
            <div class="fdc">
              <div
                class="h7 mb05"
                for=""
              >Email</div>
              <input
                class="w100 h6"
                type="text"
                placeholder="Nhập email"
                v-model="dataInput.email"
              >
            </div>
          </div>
          <div class="f fdc mb1">
            <label
              class="h7 mb05"
              for=""
            >Tên đăng nhập</label>
            <input
              class="h6"
              type="text"
              placeholder="Nhập tên đăng nhập"
              v-model="username"
            >
          </div>
          <div class="f fdc mb1">
            <label
              class="h7 mb05"
              for=""
            >Mật khẩu</label>
            <input
              class="h6"
              type="password"
              placeholder="Nhập mật khẩu vào đây"
              v-model="password"
            >
          </div>
          <div
            v-if="!passwordConfirmError"
            class="f fdc mb4"
          >
            <label
              class="h7 mb05"
              for=""
            >Xác nhận lại mật khẩu</label>
            <input
              class="h6"
              type="password"
              placeholder="Nhập lại mật khẩu"
              v-model="passwordConfirm"
            >
          </div>
          <div
            v-if="passwordConfirmError"
            class="f fdc mb4"
          >
            <label
              class="h7 mb05"
              for=""
            >Xác nhận lại mật khẩu</label>
            <input
              class="h6 border-error"
              type="password"
              placeholder="Nhập lại mật khẩu"
              v-model="passwordConfirm"
            >
            <div class="h6 red mt025">
              Mật khẩu không đúng
            </div>
          </div>
          <div class="f fdc mb1">
            <label
              class="h7 mb05"
              for=""
            >Mã giới thiệu</label>
            <input
              class="h6"
              type="text"
              placeholder="Nhập mã giới thiệu"
              v-model="key_affiliate"
            >
          </div>
          <button
            @click="register"
            class="w100 h5 pt075 pb075 align-c border-radius bg-brown white"
          >Đăng ký</button>
        </form>
      </div>
      <div class="mt1 align-c pl05 pr05">
        Bằng việc chọn "Đăng ký", bạn đồng ý với các <a
          class="blue"
          href=""
        >Điều khoản và Điều kiện</a>của chúng tôi
      </div>
      <div class="w100 align-c mt15">
        <span class="gray h5">Đã có tài khoản?</span>
        <span
          @click="statusForm = 'login'"
          class="cursor blue h5"
        >Đăng nhập ngay</span>
      </div>
    </div>

    <div
      v-if="statusForm === 'forgotpassword'"
      class="forgot-password relative f fdc pt2 pb1 border-radius bg-white-1"
    >
      <svg
        @click="handleClose"
        class="absolute cursor"
        style="top: 2em; right: 1em"
        viewBox="0 0 18 16"
        width="18"
        height="16"
      >
        <use
          xlink="http://www.w3.org/2000/svg"
          href="#close"
          x="0"
          y="0"
        ></use>
      </svg>
      <div class="align-c pl1 pr1 mt2 mb15 border-bottom">
        <img
          class="mb2"
          src="../../assets/LogoDrMom.png"
          alt=""
        >
        <div
          @click="statusForm = 'login'"
          class="f aic mb05 align-l cursor"
        >
          <svg
            class="mr025"
            viewBox="0 0 7 10"
            width="7"
            height="10"
          >
            <use
              xlink="http://www.w3.org/2000/svg"
              href="#back"
              x="0"
              y="0"
            ></use>
          </svg>
          <p4 class="blue">Quay lại</p4>
        </div>
      </div>
      <div class="pl1 pr1">
        <h2 class="mb15 ">Quên mật khẩu</h2>
        <div class="step relative f jcb aic mb2">
          <div class="step-line"></div>
          <div
            class="f fdc aic"
            style="height: 100px"
          >
            <div class="step-circle step-control f jcc aic h3 mb05 border bg-white-1">1</div>
            <div class="step-title brown">Xác thực <br> thông tin</div>
          </div>
          <div
            class="f fdc aic"
            style="height: 100px"
          >
            <div class="step-circle f jcc aic h3 mb05 border bg-white-1">2</div>
            <div class="step-title">Tạo mật khẩu mới</div>
          </div>
          <div
            class="f fdc aic"
            style="height: 100px"
          >
            <div class="step-circle f jcc aic h3 mb05 border bg-white-1">3</div>
            <div class="step-title">Hoàn thành</div>
          </div>
        </div>
        <form
          class="auth-info"
          action=""
        >
          <div class="f fdc mb1">
            <label
              class="h7 mb05"
              for=""
            >Số điện thoại</label>
            <input
              class="h6"
              type="text"
              placeholder="Nhập số điện thoại của bạn"
            >
          </div>
          <div class="f fdc mb2">
            <label
              class="h7 mb05"
              for=""
            >Mã OTP</label>
            <input
              class="h6"
              type="type"
              placeholder="Nhập mã OTP đã gửi về số điện thoại của bạn"
            >
          </div>
          <button
            class="w100 h5 pt075 pb075 align-c border-radius bg-brown white"
            @click="handleToNewPassword"
          >Xác nhận mã OTP</button>
        </form>
        <form
          class="new-password hide"
          action=""
        >
          <div class="f fdc mb1">
            <label
              class="h7 mb05"
              for=""
            >Mật khẩu mới</label>
            <input
              class="h6"
              type="text"
              placeholder="Nhập mật khẩu mới của bạn"
            >
          </div>
          <div class="f fdc mb2">
            <label
              class="h7 mb05"
              for=""
            >Xác nhận mật khẩu mới của bản</label>
            <input
              class="h6"
              type="password"
              placeholder="Nhập mật khẩu vào đây"
            >
          </div>
          <button
            class="w100 h5 pt075 pb075 align-c border-radius bg-brown white"
            @click="handleToComplate"
          >Tạo mật khẩu mới</button>
        </form>
        <div class="complate hide">
          <div class="f fdc aic">
            <img
              class="mb1"
              src="../../assets/complate.png"
              alt=""
            >
            <h2 class="mb2 align-c gray">Quý khách đã tạo mật khẩu<br>mới thành công! </h2>
            <button class="w100 h5 pt075 pb075 align-c border-radius bg-brown white">Xác nhận mã OTP</button>
          </div>
        </div>
      </div>
    </div>

    <div class="hide">
      <svg
        width="9"
        height="10"
        viewBox="0 0 9 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="close"
          d="M5.78516 4.75L8.73828 1.82422C8.90234 1.66016 8.90234 1.35938 8.73828 1.19531L8.05469 0.511719C7.89062 0.347656 7.58984 0.347656 7.42578 0.511719L4.5 3.46484L1.54688 0.511719C1.38281 0.347656 1.08203 0.347656 0.917969 0.511719L0.234375 1.19531C0.0703125 1.35938 0.0703125 1.66016 0.234375 1.82422L3.1875 4.75L0.234375 7.70312C0.0703125 7.86719 0.0703125 8.16797 0.234375 8.33203L0.917969 9.01562C1.08203 9.17969 1.38281 9.17969 1.54688 9.01562L4.5 6.0625L7.42578 9.01562C7.58984 9.17969 7.89062 9.17969 8.05469 9.01562L8.73828 8.33203C8.90234 8.16797 8.90234 7.86719 8.73828 7.70312L5.78516 4.75Z"
          fill="#757575"
        />
      </svg>
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="back"
          d="M1.13672 4.53125L5.15625 0.484375C5.32031 0.320312 5.47526 0.320312 5.62109 0.484375L6.16797 1.00391C6.3138 1.16797 6.3138 1.32292 6.16797 1.46875L2.91406 4.75L6.16797 8.03125C6.3138 8.17708 6.3138 8.33203 6.16797 8.49609L5.62109 9.01562C5.47526 9.17969 5.32031 9.17969 5.15625 9.01562L1.13672 4.96875C0.990885 4.82292 0.990885 4.67708 1.13672 4.53125Z"
          fill="#2577C9"
        />
      </svg>

    </div>

    <div style="display: none">
      <img
        id="avatar-default"
        src="./avatar.jpeg"
        alt=""
      >
    </div>

    <div class="fixed" style="top: 3em; left: 2em;">
      <slot name="subfix" />
    </div>

    <NotiAuthorization v-if="loading" />

    <NotiView
      v-if="notiView"
      :title="title"
      :logo="logo"
      @closePopup='closeNoti'
    />
    <NotiView
      v-if="loginErr"
      :title="loginErrMessage"
      :logo="logo"
      @closePopup='closeNoti'
    />
  </div>
</template>

<script>
import NotiAuthorization from './NotiAuthorization/NotiAuthorization.vue'
import NotiView from '@/components/NotiView/NotiView.vue'
import IsLoading from '@/views/IsLoading/IsLoading.vue'

import axios from "axios"
import { register } from 'register-service-worker';

import bcrypt from 'bcryptjs';

export default {
    components : {
        NotiAuthorization,
        NotiView,
        IsLoading
    },

    props : {
        statusFormProp : {
            type : String,
            default() {
                return ''
            }
        }
    },

    created() {
        this.statusForm = this.statusFormProp
    },

    data() {
        return {
            notiView : false,
            title : '',
            logo : '',

            username : "",
            password : "",
            passwordConfirm : "",
            key_affiliate: null,
            passwordConfirmError: false,
            statusForm : 'login',

            dataInput : {
                full_name : '',
                phone : '',
                email : '',
            },

            loading : false,
            isLogin: false,
            loginErrMessage: '',
            loginErr: false,

            isShowPassWord: false,
        }
    },

    watch : {
        'statusForm' : function () {
            this.dataInput = {
                full_name : '',
                phone : '',
                email : '',
            } 
            this.password = '',
            this.username = ''
        }
    },

    methods: {
        handleClose() {
            this.$emit('closePopup')
        },

        closeNoti () {
            if(this.notiView){
                this.notiView = false;
            }
            if(this.loginErr){
                this.loginErr = false;
            }
            
        },

        handleToComplate(evt) {
            evt.preventDefault()
            var hide = document.querySelector('.new-password');
            hide.classList.add('hide')
            var visible = document.querySelector('.complate');
            visible.classList.remove('hide');
            var circle1 = document.querySelectorAll('.step-circle')[1];
            circle1.classList.remove('step-control')
            var title1 = document.querySelectorAll('.step-title')[1];
            title1.classList.remove('brown')
            var circle = document.querySelectorAll('.step-circle')[2];
            circle.classList.add('step-control')
            var title = document.querySelectorAll('.step-title')[2];
            title.classList.add('brown')
        },

        changeShowPassWord () {
            this.isShowPassWord = !this.isShowPassWord;
            this.$nextTick(() => {
                this.$refs.inputPassword.focus();
            })
        },

        async login(evt) {
            evt.preventDefault()
            this.isLogin = true;
            var dataUser = JSON.stringify({
                "username": this.username,
                "password": this.password,
            });
            const axiosConfig = {
                headers : {'Content-Type': 'application/json'} 
            }

            axios.post('https://api.drmom.store/login/', dataUser, axiosConfig)
            .then( async (response) => {
                var user = await response.data['token']
                var idUser = await response.data['userId']
                var role = await response.data['role']
                const salt = await bcrypt.genSaltSync(10)

                const saltRole = await bcrypt.hashSync(role, salt)

                localStorage.setItem('user', user)
                localStorage.setItem('role', saltRole)
                localStorage.setItem('idUser', idUser)
                this.username = ""
                this.password = ""
                var config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'token '+ user,
                    }  
                };
                await axios.get('https://api.drmom.store/getme', config)
                .then((res) => {
                    var data = JSON.stringify(res.data);
                    localStorage.setItem('myAccount', data);    
                })
                .finally(() => {    
                    if (localStorage.getItem('systemsetting')) {
                        if (role=='admin'){
                            this.isLogin = false; 
                            this.$router.push({name : 'body-view', params : {path: "quan-ly-user" } })
                        }
                        else {
                            location.reload()
                        }
                    } else {
                        const getSystemsetting = async () => {
                            try {
                                const systemsetting = await axios.get('https://api.drmom.store/v1/api/systemsetting/', config).then((res) => {
                                    localStorage.setItem('systemsetting', res.data.results[0].router)
                                    if (role === 'admin') {
                                        this.isLogin = false; 
                                        this.$router.push({name : 'body-view', params : {path: "quan-ly-user" } })
                                    } else if (role === 'customer' || role ==='user' || role == 'afiliate') {
                                        location.reload()
                                    }
                                })
                                return systemsetting
                            } catch (e) {
                                console.error(e);
                            }
                        }
                        getSystemsetting();
                    }
                })
            })
            .catch((err) => {
                this.isLogin = false;
                this.loginErrMessage = "Sai tên đăng nhập hoặc mật khẩu";
                this.loginErr = true;
            })
        },

        async register (event) {
            event.preventDefault()
            this.loading = true

            var dataUser = JSON.stringify({
                "username": this.username,
                "password": this.password,
            });

            const axiosConfig = {
                headers : {'Content-Type': 'application/json'} 
            }

            if (this.password === this.passwordConfirm) {
				try {
					axios.post('https://api.drmom.store/register/', dataUser, axiosConfig)
					.then((res) => {
	
						this.dataInput['user'] = res.data.id
						
						const axiosConfigCreate = {
							headers: {
								'Content-Type': 'application/json',
								// 'Authorization': 'Token add44ba0c8cad3e1a1247b74531df243b01434d2'
							}
						}
	
						var img = document.getElementById('avatar-default')
	
						fetch(img.src)
							.then(res => res.blob())
							.then(blob => {
	
							var dataCustomer = new FormData()
							var avatar = new File([blob], 'dot.png', blob)
							console.log(avatar);
							dataCustomer.append('avatar', avatar)
							dataCustomer.append('full_name', this.dataInput.full_name)
							dataCustomer.append('key_customer', Math.floor(Math.random() * 1000000000000))
							dataCustomer.append('phone', this.dataInput.phone)
							dataCustomer.append('email', this.dataInput.email)
							dataCustomer.append('role', 2)
							dataCustomer.append('user', this.dataInput['user'])
	
							axios.post('https://api.drmom.store/v1/api/customer/',dataCustomer, axiosConfigCreate)
							.then((res) => {
								this.loading = false
	
								this.title = 'Đăng ký thông tin thành công'
								this.logo = 'noti-success'
								this.notiView = true
	
								this.statusForm = 'login'

                // tính điểm affiliate
                if (this.key_affiliate) {
                  const payload = {
                    key_affiliate: this.key_affiliate,
                    customer_registered: res.data.id,
                    affiliate_link: `${window.location.origin}/quan-ly-customer/${res.data.id}`,
                    option: "r",
                  };
                  axios.post("https://api.drmom.store/affiliate/create/", payload);
                }
			
								localStorage.setItem('cartProudct', JSON.stringify([]))
							}).catch(() => {
								this.notiView = false
								this.loading = false
							})
						})
	
					})

				} catch (e) {
					this.loading = false
					console.error('Registration Error: ' + (e?.message || e))
				}

            } else {
                this.passwordConfirmError = true
                this.loading = false
            }
        }
    },
}
</script>

<style scoped lang="scss">
@import 'LoginView.scss';
</style>