@<template>
  <div v-if="!isMobile()" class="header__container f jcb aic bg-white-1 z3">
    <div class="container--forums-header w100 f jcb aic">
      <div class="f aic">
        <div class="logo__drmom">
          <img src="../imgLanding/logo.png" alt="" />
        </div>
        <div class="nav__container f aic ml1">
          <div class="cursor" v-for="(item, index) in dataNav" :key="index">
            <template v-if="item.listChildren">
              <div class="dropdown" tabindex="0">
                <input type="checkbox" id="dropdown" />
                <label class="dropdown__face" for="dropdown">
                  <div class="dropdown__text h4 gray-1-text">
                    {{ item.title }}
                  </div>
                  <div class="dropdown__arrow"></div>
                </label>

                <ul class="dropdown__items">
                  <p
                    v-for="(child, indexChild) in item.listChildren"
                    :key="indexChild"
                    @click="$router.push(`${item.path}/category-${child.id}`)"
                    class="sub-item-menu"
                  >
                    {{ child.category_name }}
                  </p>
                </ul>
              </div>
            </template>
            <router-link
              v-else
              :to="{ name: 'body-view', params: { path: item.path } }"
              class="relative"
            >
              <div
                class="h4 brown item-menu-title"
                v-if="$route.params.website === item.path"
              >
                {{ item.title }}
              </div>
              <div v-else class="h4 gray-1-text item-menu-title">
                {{ item.title }}
              </div>

              <div v-if="item.listChildren" class="item-menu absolute bg-white">
                <p
                  v-for="(child, indexChild) in item.listChildren"
                  :key="indexChild"
                  class="sub-item-menu"
                >
                  {{ child.category_name }}
                </p>
              </div>
            </router-link>
          </div>
          <router-link
            :to="{ name: 'body-view', params: { path: 'product' } }"
            v-if="account"
          >
            <div v-if="$route.params.website === 'product'" class="h4 brown">
              Sản phẩm
            </div>
            <div v-else class="h4 gray-1-text">Sản phẩm</div>
          </router-link>
        </div>
      </div>
      <div
        v-if="!account"
        class="action__container f aic pt075 pb075 pl1 pr1 border-radius bg-brown"
      >
        <div
          @click="(LoginPopup = true), (statusFormProp = 'register')"
          class="h5 pr05 border-right white cursor"
        >
          Đăng ký
        </div>
        <div
          @click="(LoginPopup = true), (statusFormProp = 'login')"
          class="h5 pl05 white cursor"
        >
          Đăng nhập
        </div>
      </div>

      <div
        v-if="account"
        @click="handleLogin"
        class="action__container relative f aic border-radius"
      >
        <div v-if="myAccount.full_name !== ''" class="p4 brown">
          {{ myAccount.full_name }}
        </div>
        <div v-if="myAccount.full_name === ''" class="p4 brown">
          {{ myAccount.user__username }}
        </div>
        <div class="ml1 mr1">
          <img class="image_user" :src="myAccount.avatar" alt="" />
        </div>
        <img src="../../../assets/Header/Down.png" alt="" />

        <PopupLogout></PopupLogout>
      </div>

      <LoginView
        v-if="LoginPopup"
        @closePopup="closePopup"
        :statusFormProp="statusFormProp"
      />
    </div>
  </div>

  <div v-else class="w100 header__container-mobile f jcb aic">
    <div class="logo__drmom">
      <img src="../imgLanding/logo.png" alt="" />
    </div>
    <div @click="isShowMenu = true">
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.75C0 0.34375 0.3125 0 0.75 0H13.25C13.6562 0 14 0.34375 14 0.75C14 1.1875 13.6562 1.5 13.25 1.5H0.75C0.3125 1.5 0 1.1875 0 0.75ZM0 5.75C0 5.34375 0.3125 5 0.75 5H13.25C13.6562 5 14 5.34375 14 5.75C14 6.1875 13.6562 6.5 13.25 6.5H0.75C0.3125 6.5 0 6.1875 0 5.75ZM13.25 11.5H0.75C0.3125 11.5 0 11.1875 0 10.75C0 10.3438 0.3125 10 0.75 10H13.25C13.6562 10 14 10.3438 14 10.75C14 11.1875 13.6562 11.5 13.25 11.5Z"
          fill="#1A1A1A"
        />
      </svg>
    </div>
    <div class="aside-menu" :class="isShowMenu && 'active-menu'">
      <div v-if="isShowMenu" class="overlay" @click="isShowMenu = false"></div>
      <div class="menu-content pv1">
        <div v-if="!account" class="group-menu">
          <div
            class="h5 white cursor group-menu__button"
            v-for="(item, index) in dataNav"
            :key="index"
          >
            <router-link
              :to="{ name: 'body-view', params: { path: item.path } }"
            >
              <template v-if="$route.params.website === item.path">
                {{ item.title }}
              </template>
              <template v-else>{{ item.title }}</template>
            </router-link>
          </div>
          <div
            @click="(LoginPopup = true), (statusFormProp = 'register')"
            class="h5 white cursor group-menu__button"
          >
            Đăng ký
          </div>
          <div
            @click="(LoginPopup = true), (statusFormProp = 'login')"
            class="h5 white cursor group-menu__button"
          >
            Đăng nhập
          </div>
        </div>
        <div v-else class="group-menu white">
          <div
            v-if="account"
            class="action__container relative f aic border-radius ph1 gap1"
          >
            <div class="">
              <img class="image_user" :src="myAccount.avatar" alt="" />
            </div>
            <div v-if="myAccount.full_name !== ''" class="p4">
              {{ myAccount.full_name }}
            </div>
            <div v-if="myAccount.full_name === ''" class="p4">
              {{ myAccount.user__username }}
            </div>
          </div>
          <router-link
            v-if="roleUser === 'admin'"
            :to="{ name: 'body-view', params: { path: 'quan-ly-user' } }"
            class="w100"
          >
            <div class="group-menu__button h4 w100">Về trang quản trị</div>
          </router-link>
          <router-link
            :to="{ name: 'body-view', params: { path: item.path } }"
            class="cursor w100"
            v-for="(item, index) in dataNav"
            :key="index"
          >
            <div
              class="h4 group-menu__button"
              v-if="$route.params.website === item.path"
            >
              {{ item.title }}
            </div>
            <div v-else class="h4 group-menu__button">{{ item.title }}</div>
          </router-link>
          <router-link
            :to="{ name: 'body-view', params: { path: 'product' } }"
            v-if="account"
          >
            <div
              v-if="$route.params.website === 'product'"
              class="h4 group-menu__button"
            >
              Sản phẩm
            </div>
            <div v-else class="h4 group-menu__button">Sản phẩm</div>
          </router-link>
          <div class="group-menu__button h4" @click="handleLogout">
            Đăng xuất
          </div>
        </div>
      </div>
    </div>

    <LoginView
      v-if="LoginPopup"
      @closePopup="closePopup"
      :statusFormProp="statusFormProp"
    />
  </div>
</template>

<script>
import bcrypt from "bcryptjs";
import LoginView from "@/views/LoginView/LoginView.vue";
import PopupLogout from "@/components/PopupLogout/LogoutView.vue";
import axios from "axios";

export default {
  components: {
    LoginView,
    PopupLogout,
  },

  data() {
    return {
      LoginPopup: false,
      statusFormProp: null,
      account: false,
      dataNav: [
        {
          title: "Trang chủ",
          path: "website",
        },
        {
          title: "Kiến thức cho mẹ",
          path: "post-website",
          listChildren: [],
        },
        {
          title: "Forum",
          path: "forums",
        },
        {
          title: "Sản phẩm",
          path: "product",
        },
      ],
      myAccount: {},
      isShowMenu: false,
      roleUser: null,
    };
  },

  methods: {
    getDataAccount() {
      var account = localStorage.getItem("user");
      this.account = account;

      var myAccount = localStorage.getItem("myAccount");
      this.myAccount = JSON.parse(myAccount);
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    handleLogin() {
      var login = document.getElementsByClassName("log-out")[0];
      console.log(login);
      login.classList.toggle("active");
    },

    closePopup() {
      this.LoginPopup = false;
    },

    handleLogout() {
      // window.localStorage.clear();
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("myAccount");
      window.localStorage.removeItem("idUser");
      window.localStorage.removeItem("role");
      if (this.$route.path !== "/")
        this.$router.push({ name: "website", params: { path: "/" } });
      else window.location.reload();
    },

    async checkRoleUser() {
      const role = localStorage.getItem("role");
      var admin = await bcrypt.compare("admin", role);
      var customer = await bcrypt.compare("customer", role);
      var user = await bcrypt.compare("user", role);
      var afiliate = await bcrypt.compare("affiliate", role);
      if (admin) {
        this.roleUser = "admin";
      } else if (customer || afiliate) {
        this.roleUser = "customer";
      } else if (user) {
        this.roleUser = "user";
      } else {
        return "another";
      }
    },

    async getDataCategory() {
      var url = "https://api.drmom.store/";
      await axios.get(url + "v1/api/categorypost/").then((res) => {
        this.dataNav[1].listChildren = res.data.results;
        this.dataNav = [...this.dataNav];
      });
    },
  },

  created() {
    this.getDataAccount();
    this.checkRoleUser();
    this.getDataCategory();
  },
};
</script>

<style scoped lang='scss'>
@import "HeaderLanding.scss";
</style>